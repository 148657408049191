const translation = {
    LANGUAGE: "en",
    SABLONO: "Sablono",

    /**
     * DICTIONARY
     */

    _DECIMAL: "{{n}}.{{m}}",

    _SECONDS: "seconds",
    _MINUTES: "minutes",
    _HOUR: "hour",
    _HOURS: "hours",
    _WORK_HOUR: "working hour",
    _WORK_HOURS: "working hours",
    _DAY: "day",
    _DAYS: "days",
    _WORKDAY: "working day",
    _WORKDAYS: "working days",
    _YEARS: "years",

    //Aliases for units
    _wd: "Working Day{{duration == 1 ? '' : 's'}}",
    _wh: "Working Hour{{duration == 1 ? '' : 's'}}",
    _h: "Hour{{duration == 1 ? '' : 's'}}",
    _d: "Day{{duration == 1 ? '' : 's'}}",

    _wd_ABBREVIATION: "wd",
    _wh_ABBREVIATION: "wh",
    _h_ABBREVIATION: "h",
    _d_ABBREVIATION: "d",

    _LAST_MODIFIED: "Last modified",
    _SCHEDULE: "Schedule",

    _ON_SCHEDULE: "On schedule",
    _AT_RISK: "At risk",
    _BEHIND_SCHEDULE: "Behind schedule",
    _BEHIND_BASELINE_SCHEDULE: "Behind schedule (Baseline)",
    _AHEAD_SCHEDULE: "Ahead schedule",
    _UNSCHEDULED: "Unscheduled",
    _NO_PROCESS: "Without workflow",

    _PLANNED: "Planned",
    _PLANNED_TO_BE_DONE: "Planned to be done",
    _ACTUAL: "Actual",

    _NEW_NOTES: "New notes",
    _NOTES: "Notes",
    _NOTE_TEXT: "Note text",
    _ISSUES_CLOSED: "Issues closed",
    _ISSUE_TEMPLATE_CODE: "Note Template",

    _CLAIM: "Quality issue",
    _CLAIMS: "Quality issues",
    _OBSTRUCTION: "Obstruction",
    _OBSTRUCTIONS: "Obstructions",

    _OPEN_CLAIM: "outstanding quality issue",
    _OPEN_ISSUE: "outstanding issue",
    _OPEN_OBSTRUCTION: "outstanding obstruction",

    _OPEN_CLAIMS: "outstanding quality issues",
    _OPEN_ISSUES: "outstanding issues",
    _OPEN_OBSTRUCTIONS: "outstanding obstructions",

    _ISSUE_STATUS: "Status",
    _ISSUE_STATUS_OPEN: "Open",
    _ISSUE_STATUS_CLOSED: "Closed",
    _ISSUE_STATUS_WFC: "In review",
    _ISSUE_STATUS_REJECTED: "Re-opened",
    _ISSUE_STATUS_CONFIRMED: "Closed",
    _ISSUE_STATUS_REMOVED: "Deleted",
    _ISSUE_STATUS_NOT_REMOVED: "Not Deleted",
    INPUT_INCLUDE_REMOVED_ISSUES_LABEL: "Deletion Status",

    _INFO_S: "information",
    _NO_NOTES: "None",
    _ISSUE_TYPE_INFO: "Information",

    _CLOSED_CLAIMS: "closed quality issues",
    _CLOSE_ISSUE: "close issue",
    _CLOSED_ISSUES: "closed issues",
    _CLOSED_OBSTRUCTIONS: "closed obstructions",

    _OPEN_CLAIMS_N:
        "{{items}} open quality issue{{ (items === 1) ? '' : 's' }}",
    _OPEN_ISSUES_N: "{{issues}} open issue{{ (issues === 1) ? '' : 's' }}",
    _OPEN_OBSTRUCTIONS_N:
        "{{items}} open obstruction{{ (items === 1) ? '' : 's' }}",
    _INFO_N: "{{items}} {{ (items === 1) ? 'note' : 'notes'}}",

    _ACTIVITIES_FINISHED_N: "{{done}} out of {{quantity}} are finished",
    _ACTIVITIES_FINISHED: "activities finished",
    _ACTIVITIES: "activities",
    _ACTIVITIES_N:
        "{{activities | number}} Activit{{ (activities === 1) ? 'y' : 'ies' }}",
    _CUSTOM_ACTIVITIES: "Custom activities",

    _CONTAINS: "Contains:",
    _CREATED: "Created:",
    _DRAFT: "Ongoing",

    _DATE: "Date",
    _TODAY: "Today",
    _END_OF_LAST_MONTH: "End Of Last Month",
    _CUSTOM_DATE: "Custom",
    _UNTIL: "Up to",
    _ANYTIME: "Anytime",
    _DAYS_BETWEEN_OVER_30_DAYS: "Older than 30 days",
    _DAYS_BETWEEN_TO_30_DAYS: "Last 30 Days",
    _DAYS_BETWEEN_TO_7_DAYS: "Last 7 Days",
    _DAYS_BETWEEN_AFTER_30_DAYS: "Next 30 Days",
    _DAYS_BETWEEN_AFTER_7_DAYS: "Next 7 Days",
    _TEN_DAYS_AGO: "In the last 10 days",
    _LAST_MONTH: "Last month",
    _LAST_WEEK: "Last week",
    _NEXT_WEEK: "Next week",
    _THIS_WEEK: "This week",
    _WEEK_OF_YEAR: "Week Commencing",

    _TO_DATE_NOT_SET: "none",
    _FROM_DATE_NOT_SET: "none",
    _UNSET: "not set",
    _TOPOLOGICAL_INDEX: "Activity-position in sequence",

    _CUSTOM: "User defined...",
    _STATUS: "Deliverable Status",
    _UPDATING_STATUS: "Updating status",
    _STATUS_UPDATED: "Status updated",
    _FROM: "From",
    _TO: "To",
    _ALL: "All",
    _NO_DESCRIPTION: "No description",

    _INFO: "Info notes",
    _ALL_TYPES: "All",
    _IMAGES: "Images",

    _EVERYBODY: "Everybody",

    _ROLE_DEFAULT: "",
    _ROLE_ADMIN: "Project Admin",
    _ROLE_MANAGER: "Project Manager",
    _ROLE_INSPECTOR: "Site Inspector",
    _ROLE_CONTROLLER: "Project Controller",
    _ROLE_REVIEWER: "Reviewer (read only)",
    _ROLE_REPLACEMENT: "Replacement",

    _ROLE_COMMERCIAL_MANAGE: "Commercial Manager",
    _ROLE_COMMERCIAL_READ: "Commercial Reviewer",

    _ROLE_INITIALS_ADMIN: "PA",
    _ROLE_INITIALS_MANAGER: "PM",
    _ROLE_INITIALS_INSPECTOR: "SI",
    _ROLE_INITIALS_CONTROLLER: "PC",
    _ROLE_INITIALS_REVIEWER: "PR",

    _COMPONENT: "Component",
    _DELIVERY: "Delivery",
    _DOCUMENT: "Document",
    _ROOM: "Room",
    _TRADE: "Trade",
    _GROUP: "Group",
    _OTHERS: "Other",
    _ACTIVITY: "Activity",
    _DELIVERABLE: "Deliverable",

    _PROGRESS_0: "not started",
    _PROGRESS_100: "finished",
    _PROGRESS_50: "work started",
    _PROGRESS_CHANGES: "Activity updates",
    _PROGRESS_CANNOT_START: "Report a Note",

    _NOT_STARTED: "Not started",
    _STARTED: "Work started",
    _DONE: "Finished",
    _WAITING_FOR_CONFIRMATION: "Waiting for confirmation",
    _CONFIRMED: "Confirmed",
    _REJECTED: "Rejected",
    _APPROVED: "Approved",
    _COMPLETED: "Finalised: Confirmed + Finished after trade",
    _REVIEW_CHECKLIST: "Review QA Checklist",

    _SELECT_ALL: "Select all",
    _DESELECT_ALL: "Deselect all",
    _SHOW_MORE: "More",

    _PREDECESSOR: "Predecessor",
    _SUCCESSOR: "Successor",

    _PREDECESSOR_N:
        "Depends on {{items}} predecessor{{(items ===0 || items > 1) ? 's' : ''}}",
    _SUCCESSOR_N:
        "Influences {{items}} successor{{(items ===0 || items > 1) ? 's' : ''}}",

    _COPYRIGHT: "© {{year}} Sablono GmbH",

    _NEXT: "Next",
    _REFRESH: "Refresh",
    _UPDATE: "Update",
    _IMPORT: "Import",
    _DOWNLOAD: "Download",
    _DOWNLOAD_FILE: "Download file",
    _DOWNLOAD_MACRO: "Download macro",
    _CANCEL: "Cancel",
    _CONTINUE: "Continue",
    _GO_TO: "Go to ",
    _CLOSE: "Close",
    _FINISH: "Finish",
    _YES: "Yes",
    _NO: "No",
    _SAVED: "Saved",

    _TEAM_COLOR: "Team color",
    _COLOR: "Color",
    _STREET: "Street Address",
    _POST_CODE: "ZIP/Postal Code",
    _CITY: "City",
    _COUNTRY: "Country",
    _CONTACT_INFO: "Assigned to:",
    _COMPANY: "Company details:",

    _EDIT: "Edit",

    LATE_BY_DAYS: "{{ days }} Late",

    _NOTE_RESPONSIBLE_TEAM: "Responsible team",
    _NOTE_RESPONSIBLE_TEAM_SHORT: "Responsible",
    _NOTE_CONFIRMATION_TEAM_SHORT: "Reviewing",

    _NOTE_CONFIRMATION_TEAM: "Reviewing Team",

    _PROJECT_TEAM: "Full access team",
    PROJECT_TEAM_INVITED_MEMBERS: "Pending invites",
    _NO_DEFAULT_CONFIRMATION_TEAM: "No pre-selected default Reviewing Team",
    _REPORTERS_TEAM: "Team that the user reporting the Notes belongs to",
    _UNRESTRICTED_TEAM: "Unassigned",
    _NO_CONFIRMATION_TEAM_AVAILABLE: "Not requested",

    _AREA_MANAGER: "Responsible Manager",

    _CREATION_DATE: "Reported on",

    _PROJECT_START_DATE: "Start date",
    _PROJECT_DUE_DATE: "Completion date",

    _FILTER_TOOLTIP: "Filter",
    _SEND_MAIL: "Send mail",

    _AUTOCOMPLETE_SEARCH_BY_EMAIL_OR_USERNAME:
        "Please type the full email address and press enter",
    _AUTOCOMPLETE_SEARCH_USER_NOT_FOUND:
        "Please use the exact email address of the person that you want to add to your project. Hint: They need to create and activate their account first.",

    SECTION_TEAMS_SUPPORT_CENTER_TITLE: "Team management",
    SECTION_TEAMS_ADD_NEW_TITLE: "Add a new team",
    SECTION_TEAMS_ADD_NEW_TEXT:
        "A team can represent e.g. a company or a trade. Activities created in Sablono can be assigned to specific teams to manage responsibilities and visibility.",
    SECTION_TEAMS_EDIT_TITLE: "Edit team",
    SECTION_TEAMS_EDIT_TEXT:
        "A team can represent e.g. a company or a trade. Activities created in Sablono can be assigned to specific teams to manage responsibilities and visibility.",
    SECTION_TEAMS_EDIT_CONTACT_TITLE: "Contact details",
    SECTION_TEAMS_EDIT_CONTACT_TEXT:
        "Please enter the credentials of the person responsible for this team.",
    SECTION_TEAMS_EDIT_LOCATION_TEXT:
        "Please enter the name and address of the company responsible for this team.",
    SECTION_TEAMS_EDIT_DETAILS_TITLE: "Team information",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TITLE:
        "Give up administrative rights?",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TITLE: "Give up manager rights?",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TEXT:
        "Are you sure you want to change your role to a different one? This can not be undone.",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TEXT:
        "Are you sure you want to change your role to a different one? This can not be undone, because you will lose all priviledges to administrate the team members of the project.",
    SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TITLE: "Are you sure?",
    SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TEXT:
        "Do you really want to remove yourself from this project? Afterwards you will not be able to access it anymore.",
    SECTION_TEAMS_PROJECT_TEAM_INFO_TOOLTIP:
        "The Full access team is the only team which can edit every activity in the project even if the activity has been assigned to another team. Therefore it is e.g. suited for project management.",
    ACTION_DELETE_TEAM: "Delete team",
    ACTION_DELETE_USER_FROM_TEAM: "Remove user",
    ACTION_CONFIRM_DELETE_USER_FROM_TEAM: "Confirm removal",
    ACTION_UPLOAD_TEAM_LOGO: "Upload logo",

    INFO_NO_MATCHES_FOUND: "No matches found",
    INFO_CONTACT_CUSTOMER_SUPPORT: "Do you need help or more information?",
    ACTION_WATCH_VIDEO: "Watch our video",

    _STRUCTURE: "Structure",
    _PROJECT_STRUCTURE: "Project Structure",
    _NAME: "Name",
    _CODE: "Code",
    _DESC: "Description",

    ENTER_FULLSCREEN: "Start Site Dashboard",
    EXIT_FULLSCREEN: "Close Site Dashboard",
    LAST_UPDATED: "Data last updated",
    FULLSCREEN_REFRESH: "Reload data",

    PLANNED_DATES_TOOLTIP:
        "These dates have been planned and set by you or one of your teammates.",
    CALCULATED_DATES_TOOLTIP:
        "These dates have been automatically calculated by Sablono based on your set up workflows.",
    FORECASTED_DELIVERY_TOOLTIP:
        'Sablono forecasted the finish of this deliverable based on your current progress. "At risk" is shown if an activity of this deliverable runs late which is not part of the critical path.',

    NAME: "Name",
    CODE: "Code",
    _PROGRESS: "Progress",
    _INSPECTION: "update",
    START_DATE: "Start date",
    USER_DEFINED_START: "User-defined start",
    USER_DEFINED_END: "User-defined end",
    EARLIEST_START: "Earliest start (Baseline)",
    EARLIEST_END: "Earliest end (Baseline)",
    DUE_BETWEEN: "Due between",
    END_DATE: "End date",
    LATEST_END: "Latest end (Baseline)",
    LATEST_START: "Latest start (Baseline)",
    DUE_DATE: "Completion date",
    DURATION: "Duration",
    DURATION_UNIT: "Unit of duration",
    LAST_PLANNER_START: "Start (Look Ahead)",
    LAST_PLANNER_END: "End (Look Ahead)",

    REVISION_NUMBER_TOOLTIP: "Template Version",

    TOTAL_FLOAT: "Total Float (Baseline)",
    TOTAL_FLOAT_OF_X:
        "{{float >= 0 ? float + ' day' + (float === 1 ? 's' : '') : '-'}}",
    TOTAL_FLOAT_TOOLTIP:
        "This buffer will be available to finish the deliverable between Earliest Start and Latest Finish.",
    TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_DELIVERABLE:
        "This buffer will be available to finish the Deliverable between Earliest Start and Latest End.",
    TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_ACTIVITY:
        "This buffer will be available to finish the Activity between Earliest Start and Latest End.",
    TOTAL_FLOAT_TOOLTIP_IS_ZERO_DELIVERABLE:
        "This Deliverable is part of a Critical Path which is why there is no available buffer.",
    TOTAL_FLOAT_TOOLTIP_IS_ZERO_ACTIVITY:
        "This Activity is part of a Critical Path which is why there is no available buffer.",
    TOTAL_FLOAT_TOOLTIP_IS_NEGATIVE:
        "Based on the durations of your Activities and the dependencies between them, you will not be able to match you user-defined completion dates.",
    TOTAL_FLOAT_TOOLTIP_IS_NULL:
        "The Total Float was not calculated because either Start or completion date were not defined for this path.",

    PRIORITY: "Priority",
    TIME_OF_DAY: "Time",
    PERCENTAGE_FINISHED: "finished",
    _FINISHED: "Finished",
    _REMOVE: "remove",
    _PROCESS: "Workflow",
    _PROCESS_TEMPLATE: "Workflow Template",
    _STAGE: "Activity",
    _UNSTRUCTURED: "Unstructured",
    _NO_TEMPLATE_ASSIGNED: "No workflow assigned",
    _NOT_SET: "None",
    _SELECT_STATUS: "...and its status.",
    _ANY: "All",
    _LAST_INSPECTION: "Last update",
    WORK_SCHEDULED: "Work scheduled",
    _LEGEND: "Legend",

    _STATE: "Status",
    _ACTIVITY_NUMBER: "Number of Activities",
    _DELIVERABLE_NUMBER: "Number of Deliverables",

    INSPECTED_BY: "Last report from {{timestamp}} by {{inspector}}",
    LAST_MODIFIED_BY: "Last edited on {{timestamp}} by {{user}}",
    REMOVED_BY: "Deleted on {{timestamp}} by {{user}}",
    EDITED: "Edited",
    REMOVED: "Deleted",

    _FILTER_NAME_TEXT_PLACEHOLDER: "Filter by deliverable name...",
    _FILTER_CODE_TEXT_PLACEHOLDER: "Filter by deliverable code...",
    _FILTER_TYPE_TEXT_PLACEHOLDER: "Filter by deliverable type...",
    _FILTER_DESC_TEXT_PLACEHOLDER: "Filter by deliverable description...",
    _FILTER_STAGE_TEXT_PLACEHOLDER: "Search and select an Activity or Group...",
    _FILTER_LABEL_STAGE: "Stage of deliverable",
    _FILTER_LABEL_PROCESS: "Workflow",
    _FILTER_WORKFLOW_TEXT_PLACEHOLDER: "Search and select a Workflow...",
    _FILTER_STRUCTURE_TEXT_PLACEHOLDER:
        "Search and select a part of the project structure...",
    _FILTER_SEARCH_DELIVERABLE_PLACEHOLDER:
        "Search for deliverables by name, code or description...",
    _FILTER_SEARCH_TEAM_MEMBERS_PLACEHOLDER: "Search for team members...",
    _FILTER_SEARCH_NOTES_PLACEHOLDER: "Search for notes by text...",
    _SEARCH_BY_STAGE_PLACEHOLDER: "Search for an activity",

    _DELIVERABLE_SEARCH: "Search",

    _ACTION_SHOW_ADVANCED_FILTERS: "Advanced options",
    _ACTION_SHOW_REDUCED_FILTERS: "Basic options",

    /**
     * LOCALIZED LINKS
     */
    LINK_DOWNLOAD_MACRO: "/downloads/modAstaSablonoImporter.bas",
    LINK_VERSION_PAGE:
        "https://support.sablono.com/en/articles/4783534-sablono-web-platform-updates",
    LINK_VISUALIZATION_VIDEO: "https://www.youtube.com/watch?v=mkLL8Gt1aRc",
    LINK_SUPPORT_CENTER: "https://support.sablono.com",
    LINK_IDEAS_PORTAL: "https://sablono.ideas.aha.io/?sort=recent",
    LINK_IMPRESSUM: "https://www.sablono.com/legal/imprint",

    /**
     * ACTIONS
     */

    ACTION_LOGIN: "Login",
    ACTION_REGISTER_USER: "Sign up",
    ACTION_FINALISE_REGISTER_USER: "Finish",
    ACTION_CHANGE_USER: "Change User",
    ACTION_CHANGE_PASSWORD: "Change Password",
    ACTION_ARIA_LABEL_CHANGE_LANGUAGE: "Change Language",

    ACTION_SETUP_PASSWORD: "Define new password",
    LOGIN__PASSWORD_RESET_REQUIRED_MESSAGE:
        "You need to define a new password for your user. Please use the button below to do so.",

    ACTION_CREATE_CLONE_TOOLTIP: "Clone Project",
    ACTION_CREATE_EMPTY_TOOLTIP: "Create your own project",
    ACTION_PROJECT_FROM_IMPORT_TOOLTIP: "Import project",

    ACTION_CALL_THE_SUPPORT: "Open support chat",
    ACTION_SHOW_ERROR_DETAILS: "Show details",

    ACTION_OPEN_IN_TEMPLATE_EDITOR: "Open in template editor",
    ACTION_ASSIGN_ACTIVITIES: "Assign activities",
    ACTION_SHOW_WHOLE_PROCESS: "Show whole workflow",
    ACTION_SHOW_ALL_ACTIVITIES: "Show all activities",

    // NAV
    ACTION_NAV_TO_IMPRINT: "Imprint",
    ACTION_NAV_TO_AGB: "T&Cs",
    ACTION_NAV_TO_FAQ: "Online Support",
    ACTION_NAV_TO_VERSION: "Updates & Releases",
    ACTION_NAV_TO_IDEAS: "Ideas Portal",
    ACTION_NAV_TO_CONTACT: "Contact",
    ACTION_NAV_TO_APP: "Inspect Web-App",
    ACTION_NAV_TO_LEARNING_CENTER: "Go to Learning Center",
    ACTION_NAV_TO_LOGIN: "Go to login",
    ACTION_RESEND_VERIFICATION: "Send new verification code via email",
    ACTION_NAV_TO_PROJECTS: "Go to projects",
    ACTION_NAV_TO_CONFIRM_RESET_PASSWORD: "Go to Reset Password",
    ACTION_NAV_TO_SITE_DIARY: "Daily Logs",

    ACTION_NAV_TO_DASHBOARD: "Performance Dashboard",
    ACTION_NAV_TO_LEANBOARD: "Lean Planning",
    ACTION_NAV_TO_PROJECT_SELECTION: "Show my Projects",
    ACTION_NAV_TO_ALL_PROJECTS: "My Projects",
    ACTION_NAV_TO_PROJECT_DETAILS: "Project Settings",
    ACTION_NAV_TO_WBS: "Project Structure",
    ACTION_NAV_TO_TEAMS: "Teams",
    ACTION_NAV_TO_PROJECT_DELIVERABLES: "Deliverables",
    ACTION_NAV_TO_TEMPLATE_ELEMENT: "Workflow Templates",
    ACTION_NAV_TO_TEMPLATE_CHECKLIST: "QA Checklists",
    ACTION_NAV_TO_TEMPLATE_NOTES: "Note Templates",
    ACTION_NAV_TO_TEMPLATE_NOTES_IMPORT: "Import: Note Templates",
    ACTION_NAV_TO_IMPORT_SELECTION: "Import Deliverables",
    ACTION_NAV_TO_INSPECTIONS: "Updates Timeline",
    ACTION_NAV_TO_ANALYSIS: "Deliverables",
    ACTION_NAV_TO_PROCESS_VIEWER: "Activities",
    ACTION_NAV_TO_TRACKER: "Activity Tracker",
    ACTION_NAV_TO_NOTES: "Notes",
    ACTION_NAV_TO_HELP_FEEDBACK: "Help & Feedback",
    ACTION_NAV_TO_USER_PROFILE: "My Profile",
    ACTION_NAV_TO_VISUALIZATION: "Visual Trackers",
    ACTION_NAV_TO_ASSIGN_WORKFLOW: "Assign Workflow Template",

    ACTION_NAV_TO_TEAM_EDIT_TOOLTIP: "Add Team Members",

    ACTION_NAV_TO_IMPORT_CLONE: "Clone Project",
    ACTION_NAV_TO_IMPORT_EXCEL: "Deliverables - Microsoft© Excel",
    ACTION_NAV_TO_IMPORT_MSPROJECT: "Microsoft© Project",
    ACTION_NAV_TO_IMPORT_ASTA: "Asta Power Project",
    ACTION_NAV_TO_IMPORT_PRIMAVERA: "Oracle Primavera",
    ACTION_NAV_TO_IMPORT_XML: "XML file",
    ACTION_NAV_TO_IMPORT_EMPTY: "New Project",
    ACTION_NAV_TO_IMPORT_ANOTHER_SOURCE: "Import more deliverables",
    ACTION_NAV_TO_TOOLS: "Import/Export Tools",
    ACTION_NAV_TO_EXPORT: "Export Project Progress",

    ACTION_NAV_TO_EXPORT_PRIMAVERA: "Export P6 Progress",
    ACTION_NAV_TO_REQUEST_ACCESS: "Request Access",

    ACTION_IMPORT_INTEGRATE:
        "Integrate Sablono into your familiar tools | Sablono Help Center",
    ACTION_IMPORT_INTEGRATE_URL:
        "https://support.sablono.com/en/articles/2967622-step-4-integrate-sablono-into-your-familiar-tools",

    ACTION_ASK_IMPORT_SOURCE: "You didn't find your tool?",
    ACTION_ASK_IMPORT_SOURCE_SUBTITLE: "Contact us now",

    ACTION_ISSUE_EDITOR: "Create new note",

    /**
     * SECTIONS
     */
    SECTION_PROJECT_SELECTION_TITLE: "My Projects",
    SECTION_PLATFORM_INFO_TITLE: "The Platform",

    SECTION_DASHBOARD_TITLE: "Performance Dashboard",
    SECTION_PROJECT_DETAIL_TITLE: "Project Settings",
    SECTION_PROJECT_DELIVERABLES_TITLE: "Deliverables",
    SECTION_TEMPLATE_ELEMENTS_TITLE: "Workflow Templates",
    //"SECTION_TEMPLATE_NOTES_TITLE": "Note Templates",
    SECTION_TEMPLATE_NOTES_IMPORT_TITLE: "Import: Note Templates",
    SECTION_INSPECTIONS_TITLE: "Updates Timeline",
    SECTION_ANALYSIS_TITLE: "Project Progress",
    SECTION_NOTES_TITLE: "Notes",
    SECTION_IMPORT_UPLOAD_TITLE: "Import will be completed",
    SECTION_EXCEL_IMPORT_TITLE: "Microsoft© Excel Import",
    SECTION_XML_IMPORT_TITLE: "XML Import",
    SECTION_NOTE_TEMPLATES_TITLE: "Note Templates",
    SECTION_TEMPLATES_TITLE: "Workflow Templates",
    SECTION_WBS_TITLE: "Project Structure",
    SECTION_IMPORT_SELECTION_TITLE: "Import: Project Data",
    SECTION_EXPORT_TITLE: "Export progress",

    SECTION_EXCEL_IMPORT_DATES_TITLE: "Microsoft© Excel Import",
    TUTORIAL_IMPORT_DATES:
        "https://support.sablono.com/en/articles/5879573-how-to-import-activity-dates-from-microsoft-excel",
    SECTION_EXCEL_IMPORT_DATES_SUCCESS_TITLE:
        "Dates & Durations successfully imported",
    SECTION_EXCEL_IMPORT_DATES_SUCCESS_TEXT:
        "{{numberOfUpdates}} activities have received new dates and/or durations.",
    SECTION_EXCEL_IMPORT_DATES_TEXT:
        "Please make sure that your Excel has the following format before importing: <br> Deliverable Code - Activity Name - Start - End - Date Format - Duration - Unit - Project ID <br> Please note, that you will not be able to import Start and End dates for the same activity at the same time.",

    SECTION_TOOLS_TITLE: "Import/Export Tools",
    SECTION_TOOLS_IMPORT:
        "Update your Sablono project to the newest version of your schedule",
    SECTION_TOOLS_EXPORT:
        "Export the progress of your project to use it in your scheduling tool",

    SECTION_TOOLS_TILE_IMPORT:
        "<h3>Import</h3> of <b>new</b> deliverables, progress or activity dates",
    SECTION_TOOLS_TILE_UPDATE:
        "<h3>Update existing Import</h3> to edit <b>existing</b> deliverables",
    SECTION_TOOLS_TILE_EXPORT:
        "<h3>Export</h3> the progress of your project to use it in other tools",
    SECTION_TOOLS_IMPORT_ACTIVITIES:
        "Data for existing Tracker - Microsoft© Excel",
    SECTION_TOOLS_IMPORT_PACKAGE: "New Activity Tracker - Microsoft© Excel",

    SECTION_TOOLS_GO_DASHBOARD: "Go to Dashboard",

    SECTION_REQUEST_FOR_INFORMATION_TITLE: "Report progress",
    SECTION_REQUEST_FOR_INFORMATION_OPT_OUT_TITLE: "Report progress",

    SECTION_TOOLS_DIALOG_CREATED: "Imported at {{at}} by {{by}}",
    SECTION_TOOLS_DIALOG_UPDATED: "Last updated at {{at}} by {{by}}",

    SECTION_TOOLS_DIALOG_MERGE_TITLE: "Update your Sablono project",
    SECTION_TOOLS_DIALOG_MERGE_TEXT:
        "Please select the source file that you want to use to update your project.",
    SECTION_TOOLS_DIALOG_EXPORT_TITLE: "Export your project progress",
    SECTION_TOOLS_DIALOG_EXPORT_TEXT:
        "Please select the part of your project for which you want to export progress.",
    SECTION_TOOLS_DIALOG_EXPORT_PROJECT_TILE:
        "@:INFO_DELIVERABLES_AND_ACTIVITIES_REPORT_TITLE",
    SECTION_TOOLS_DIALOG_EXPORT_PROJECT_TEXT:
        "Export the progress of the complete project to a CSV file.",
    /**
     *
     * EXPORT PAGE TRANSLATIONS
     */
    SECTION_EXPORT_SELECT_FILE_SUBTITLE:
        "The progress of your project will be written into the file you upload. <br/> Afterwards you can download and import it back to the tool you use.",
    SECTION_EXPORT_PROCESSING_TITLE: "We are updating your file...",
    SECTION_EXPORT_PROJECT_PROGRESS_TITLE:
        "We are exporting your project progress...",
    SECTION_EXPORT_SUCCESS_TITLE: "Your file is ready to download now.",
    SECTION_EXPORT_SUCCESS_TITLE_PRIMAVERA:
        "Your updated file is ready to download now.",
    SECTION_EXPORT_SUCCESS_SUBTITLE:
        "The state of {{changed}} {{(changed==1)?'task':'tasks'}} has been updated.",
    SECTION_EXPORT_CSV_SUCCESS_SUBTITLE:
        "{{platformTotal}} {{(platformTotal==1)?'line has':'lines have'}} been exported.",
    SECTION_EXPORT_ASTA_RESOURCES:
        "To import the progress of your project into Asta Power Project please use the macro provided below.",
    SECTION_EXPORT_ASTA_RESOURCES_DETAIL:
        "* If you used the macro before you won't need to download it again.",
    SECTION_EXPORT_WRONG_FILE_TITLE:
        "The file you selected does not seem to match your Sablono project...",
    SECTION_EXPORT_WRONG_FILE_SUBTITLE:
        "The tasks in your file do not relate to the ones on Sablono. To continue please select another file.",
    SECTION_EXPORT_UP_TO_DATE_FILE_TITLE: "Your file is already up to date!",
    SECTION_EXPORT_UP_TO_DATE_FILE_SUBTITLE:
        "The progress of your project has not changed since you last updated your file.",

    SECTION_EXPORT_PRIMAVERA_SELECT_FILE_TITLE:
        "Please upload your Primavera file",
    SECTION_EXPORT_PRIMAVERA_SELECT_FILE_SUBTITLE:
        "To export the progress of your project, we will need your Primavera project in the Primavera-XML file format. <br />After you uploaded the file, the progress you documented using Sablono will be added to it automatically.<br />Afterwards you can download the file again and import it back to Primavera.",
    DIALOG_EXPORT_PROGRESS_TITLE: "Oops...",
    DIALOG_EXPORT_PROGRESS_CONTENT:
        "Sadly, we were not able to detect if you are working with a Primavera system using 'Comma' or 'Full Stop' as separator. In order to be able to import the file provided by us, please tell us how your system is configured.",
    DIALOG_EXPORT_PROGRESS_ACTION_COMMA: "Comma",
    DIALOG_EXPORT_PROGRESS_ACTION_FULL_STOP: "Full Stop",
    /**
     *
     * MERGE/UPDATE TRANSLATIONS
     */

    SECTION_MERGE_TITLE: "Update project",
    SECTION_MERGE_TOOLBAR_TITLE: "Update your Sablono project",

    SECTION_MERGE_STEP_1_STEPPER: "Select a file",
    SECTION_MERGE_STEP_2_STEPPER: "Choose update options",
    SECTION_MERGE_STEP_3_STEPPER: "Updating your project",
    SECTION_MERGE_STEP_5_STEPPER: "Update finished",

    SECTION_MERGE_STEP_1_TITLE:
        "To update your Sablono project, please upload the file containing the newest version of your schedule.",
    SECTION_MERGE_STEP_1_SUBTITLE:
        "Please select a file matching <strong>{{title}}</strong> (<em>{{fileName}}</em>).",

    SECTION_MERGE_STEP_2_TITLE: "Delete Deliverables from Sablono",
    SECTION_MERGE_STEP_2_SUBTITLE:
        "Would you like to delete Deliverables from Sablono which were imported with this file previously but are not included any longer? Please keep in mind that this would also delete all activities and notes connected to them.",
    SECTION_MERGE_STEP_2_KEEP: "No, keep them",
    SECTION_MERGE_STEP_2_DELETE: "Yes, delete them",

    SECTION_MERGE_STEP_2A_TITLE: "Re-activate previously deleted Deliverables",
    SECTION_MERGE_STEP_2A_SUBTITLE:
        "Would you like to re-activate Deliverables which are part of this file but were deleted from the project using the Sablono platform after the previous import?",
    SECTION_MERGE_STEP_2A_KEEP: "No, keep them deleted",
    SECTION_MERGE_STEP_2A_DELETE: "Yes, re-activate them",

    SECTION_MERGE_STEP_3_TITLE: "We are updating your Sablono project...",
    SECTION_MERGE_STEP_4_TITLE: "We are processing your input...",

    SECTION_MERGE_ERROR_TITLE: "Whoops, something went wrong...",
    SECTION_MERGE_ERROR_SUBTITLE:
        "To make sure that your Sablono project is updated successfully, please try again.<br/>If you run into more problems, feel free to contact us using our chat support.",

    SECTION_MERGE_SUCCESSFULL_PROJECT_UPLOAD_HEADER:
        "Project successfully updated",
    SECTION_MERGE_SUCCESSFULL_PROJECT_UPLOAD_TEXT:
        "Your Sablono project including your Deliverables and the Project Structure was updated to the latest version of your schedule.",
    SECTION_MERGE_SUCCESSFULL_XLS_PROJECT_UPLOAD_TEXT:
        "Your Sablono project including your Deliverables and the Project Structure was updated to the latest version of your Excel sheet.",
    SECTION_MERGE_INFO_EXCEL_MAPPING:
        "Please use the <b>{{mappingKey}}</b> column of your file as Code to ensure a successful update.",
    SECTION_MERGE_INFO_EXCEL_MAPPING_HINT:
        "The Code is used to identify identical Deliverables and update them.",

    /**
     * INPUT
     */

    INPUT_USERNAME_TITLE: "Email",
    INPUT_PASSWORD_TITLE: "Password",
    INPUT_VERIFICATION_CODE_TITLE: "Verification Code",
    INPUT_NEW_PASSWORD_TITLE: "New Password",
    INPUT_PASSWORD_REPEAT_TITLE: "Repeat Password",

    INPUT_FULL_NAME_TITLE: "Name",
    INPUT_FIRST_NAME_TITLE: "First name",
    INPUT_LAST_NAME_TITLE: "Last name",
    INPUT_EMAIL_TITLE: "Work email",
    INPUT_COMPANY_NAME_TITLE: "Company name",
    INPUT_REGISTER_PHONE_TITLE: "Work phone",
    INPUT_REGISTER_QUESTION_TITLE: "How did you hear about us?",
    INPUT_REGISTER_QUESTION_OTHER_TITLE: "How in detail?",
    INPUT_REGISTER_QUESTION_OPTION_OTHER: "something else",
    INPUT_REGISTER_QUESTION_OPTION_WEBSITE: "Sablono Homepage",
    INPUT_REGISTER_QUESTION_OPTION_TELESALE: "Phone Campaign",
    INPUT_REGISTER_QUESTION_OPTION_RECOMMENDATION: "Recommendation",
    INPUT_REGISTER_QUESTION_OPTION_GOOGLE: "Internet-Search",
    INPUT_REGISTER_QUESTION_OPTION_ADVERTISMENT: "Advertisment",

    INPUT_REGISTER_QUESTIONNAIRE_LABEL: "Write your answer here",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1:
        "How did you hear about Sablono?",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2:
        "What do you want to use Sablono for?",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3:
        "Why do you want to use Sablono?",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_1:
        "Personal invite or referral",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_2: "Social media",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_3: "Blog article",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_4: "Search engine",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_5: "Event",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_6: "Other",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_1:
        "Tracking progress",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_2:
        "Quality documentation",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_3: "Reporting",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_4:
        "Commercial Valuation Management",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_5: "Planning",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_6:
        "Daily/Weekly Progress Meetings",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_1:
        "Replace existing Excel trackers",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_2:
        "Simplified trade management",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_3:
        "Quality documentation in a central location",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_4:
        "Simplified reporting from site",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_5:
        "Daily progress meetings",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_6:
        "Easier understanding of project status",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_7:
        "Look ahead planning sessions",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_8:
        "Commercial valuations",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1:
        "How did you hear about Sablono Track?",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2:
        "What will you be tracking?",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3:
        "Why do you want to use Sablono track?",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_1:
        "Personal invite or referral",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_2: "Social media",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_3: "Blog article",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_4: "Search engine",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_5: "Event",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_6: "Other",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_1: "Piling",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_2: "Fitout",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_3: "Facades",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_4: "Prefab Package",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_5:
        "Structural work",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_6:
        "Complete project",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_7: "Firestopping",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_8: "Other",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_1:
        "Replace existing Excel trackers",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_2:
        "Simplified trade management",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_3:
        "Simplified reporting from site",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_4:
        "Daily progress meetings",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_5:
        "Easier understanding of project status",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_6:
        "Commercial Valuation Management",

    INPUT_PROJECT_NAME_TITLE: "Project name",
    INPUT_PROJECT_CODE_TITLE: "Project code",
    INPUT_PROJECT_DESCRIPTION_TITLE: "Project description",
    INPUT_PROJECT_START_DATE_TITLE: "Project start",
    INPUT_PROJECT_LANGUAGE_TITLE: "Project language",
    INPUT_PROJECT_TIMEZONE_TITLE: "Project timezone",
    INPUT_PROJECT_START_TITLE: "Project Start",
    INPUT_PROJECT_COMPLETION_TITLE: "Project End",
    INPUT_PROJECT_TYPE: "Project type",
    INPUT_PROJECT_TYPE_LABEL: "Write your answer here",
    INPUT_PROJECT_TYPE_OPTION_1: "Residential Construction",
    INPUT_PROJECT_TYPE_OPTION_2: "Commercial Construction",
    INPUT_PROJECT_TYPE_OPTION_3: "Manufacturing & Production",
    INPUT_PROJECT_TYPE_OPTION_4: "Renewable Energy",
    INPUT_PROJECT_TYPE_OPTION_5: "Heritage, Conservation, Refurbishment",
    INPUT_PROJECT_TYPE_OPTION_6: "Public (Health, Education, Defence)",
    INPUT_PROJECT_TYPE_OPTION_7:
        "Industrial Construction (Distribution, Logistics, Data Centre)",
    INPUT_PROJECT_TYPE_OPTION_8: "Infrastructure, Rail, Roads",
    INPUT_PROJECT_TYPE_OPTION_9: "Private Housing",
    INPUT_PROJECT_TYPE_OPTION_10: "Other",
    INPUT_PROJECT_IMAGE_SIZE_SUGGESTION: "Recommended: 800x600px",
    INPUT_PROJECT_CURRENCY_TITLE: "Project currency",

    ACTION_CREATE_PROJECT: "Create Project",
    ACTION_LOGOUT: "Logout",

    SECTION_SIDENAV_SETUP_TITLE: "Setup",
    SECTION_SIDENAV_DASHBOARDS_AND_TRACKERS_TITLE: "Dashboards & Trackers",
    SECTION_SIDENAV_LISTS_TITLE: "Lists",
    SECTION_SIDENAV_SCHEDULES_AND_TIMELINES_TITLE: "Schedules & Timelines",
    SECTION_SIDENAV_RESOURCES_TITLE: "Resources",
    SECTION_SIDENAV_NO_PROJECT: "No project selected.",

    SECTION_CREATE_CLONE: "Clone existing Project",
    SECTION_CREATE_EMPTY: "Create new Project",
    SECTION_CREATE_FROM_IMPORT: "Import a project from other tools",

    SECTION_LOGIN_TITLE: "Login",
    SECTION_REGISTRATION_TITLE: "Sign up",
    SECTION_REGISTRATION_LANGUAGE: "Language",
    SECTION_CREATE_PROJECT_TITLE: "Create Project",

    INFO_NO_INSPECTIONS: "no updates yet",
    INFO_RECENT_INSPECTION: "latest update",
    INFO_TEAM_MEMBER: "Team Member",
    INFO_TEAM_MEMBERS: "Workflow & QA Users",
    INFO_MORE_TEAM_MEMBER: "and 1 other",
    INFO_MORE_TEAM_MEMBERS: "and {{numTeam}} others",
    INFO_PROJECT_PROGRESS: "finished",
    INFO_PROJECT_EXPIRY: "Demo projects expire after 14 days of inactivity.",
    INFO_COMMERCIAL_USERS: "Commercial Users",

    // Simple Labels
    BACK: "Back",
    CLOSE: "Close",
    YES: "Yes",
    NO: "No",
    NOT_APPLICABLE: "N/A",
    PROJECT_LANGUAGE: "Project Language",
    PROJECT_PREVIEW: "Project preview",
    FILE_VIEW: "File view",
    PREVIEW_PROJECT: "Preview project",
    VIEW_FILE: "View file",
    SELECT_FILE: "Select file",

    REFRESH: "Refresh",
    UPLOAD: "Upload",
    SETTINGS: "Settings",
    VISUALIZATION_SHOW_SETTINGS_TOOLTIP: "Settings",
    VISUALIZATION_SWITCH_SHOW_BORDER_INDICATOR: "Mark issues",

    _UPLOADED: "Uploaded",
    _GENERATED: "Generated",

    // Basic Error Messages
    ERROR_UNKNOWN_TITLE: "Unknown Error",
    ERROR_UNKNOWN_MESSAGE:
        "An unexpected error occurred. Please contact the support hotline or support@sablono.com",
    ERRORS_NO_DATA_TITLE: "No data available",
    ERRORS_NO_DATA_MESSAGE:
        "There is no data available for the desired function.",
    ERRORS_AUTHENTICATION_TITLE: "Authentication Error",
    ERRORS_AUTHENTICATION_MESSAGE: "Your session has expired.",

    // HANA Native Errors
    ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_TITLE: "This value already exists",
    ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_MESSAGE:
        "This value cant be used because it is already in use by the system",
    ERROR_HANA_INSUFFICIENT_PRIVILEGE_TITLE:
        "Action canceled: Insufficient Privileges",
    ERROR_HANA_INSUFFICIENT_PRIVILEGE_MESSAGE:
        "The Action cant be performed because there are missing system privileges.",
    ERROR_HANA_VALUE_TO_LARGE_TITLE: "Value exceeds limit",
    ERROR_HANA_VALUE_TO_LARGE_MESSAGE:
        "The Action cant be performed because a value exceeds the database limit.",

    ERROR_DATE_END_BEFORE_START: "End date has to be later than start date.",
    // Custom HANA errors
    MISSING_INPUT: "Action canceled: Required parameters are missing.",
    ERROR_DATE_END_BEFORE_START_MESSAGE:
        "Date change impossible! End date has to be earlier than start date.",
    ERROR_PROJECT_OPERATION_DENIED: "Action canceled: Insufficient Privileges",
    ERROR_COMPONENT_OPERATION_DENIED:
        "Action canceled: Insufficient Privileges",
    ERROR_ACTIVITY_OPERATION_DENIED: "Action canceled: Insufficient Privileges",
    ERROR_INVALID_ALLOCATION_UNIT: "Action canceled: Calendar type unknown",
    ERROR_INVALID_COLOR: "Action canceled: Color invalid",
    ERROR_MISSING_REQUIRED_PARAM:
        "Action canceled: Required parameters are missing",
    ERROR_CHANGING_PROGRESS_SIGNED_CHECKLIST_REQUIRED:
        "Please report progress on this Activity through the mobile app because a QA checklist is attached to it.",
    ERROR_CONFIRMING_SIGNED_CHECKLIST_REQUIRED:
        "Please report progress on this Activity through the mobile app because a QA checklist is attached to it.",
    ERROR_CHANGING_PROGRESS_ALREADY_CONFIRMED:
        "This Activity has already been confirmed.",
    ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED:
        "All Responsible Managers have to be from the same team.",
    ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED_TITLE:
        "Unfortunately not possible...",
    ERROR_CHAIN_LEAVE_AREA_MANAGER_UNSUPPORTED:
        "This Responsible Manager is currently replacing another which is why they can not be replaced themself.",
    ERROR_AREA_MANAGER_SUBSTITUTE_IS_ALREADY_ASSIGNED:
        "This user you selected is already replacing this Responsible Manager.",
    ERROR_AREA_MANAGER_SUBSTITUTE_IS_ALREADY_ACTIVE:
        "The replacement is already activated.",
    ERROR_INSUFFICIENT_PRIVILEGES_TO_BULK_STATE_CHANGE_TITLE: "Not responsible",
    ERROR_INSUFFICIENT_PRIVILEGES_TO_BULK_STATE_CHANGE_MESSAGE:
        "It seems like you selected Deliverables for which another user is the Responsible Manager. Therefore, the bulk progress update could not be carried out. Please select only Deliverables that you are responsible for and try again.",
    ERROR_CHANGING_PROGRESS_REJECTED_TITLE: "Invalid progress change",
    ERROR_CHANGING_PROGRESS_REJECTED_MESSAGE:
        "At least one of the selected activities is set to rejected. An activity that is rejected can only be set to done.",
    ERROR_CHECKLIST_EXPORT_THRESHOLD:
        "Process aborted! This service currently supports a maximum of 2000 QA checklists for a single request. Please repeat the process with fewer deliverables or activities.",
    ERROR_UNIQUE_CODE_VIOLATION:
        "The Excel list that you are trying to import into this project contains Deliverable Codes that already exist. If you are trying to change existing Deliverables please use our Update functionality. If you want to import new Deliverables please define Codes in Excel that are unique for this project.",
    ERROR_UNIQUE_CODE_VIOLATION_TITLE: "Codes have to be unique",

    //Request Errors
    ERROR_REQUEST_TITLE:
        "Server Request Error. Please send a screenshot to our support team and kindly share details.",
    ERROR_REQUEST_MESSAGE:
        "An error occurred while communicating with the server. Please try again and if this continues to happen, contact our support team and kindly share details.",
    ERROR_REQUEST_TIMEOUT_TITLE:
        "Request Timeout. Please try again and if this continues to happen, contact our support team and kindly share details.",
    ERROR_REQUEST_TIMEOUT_MESSAGE:
        "The request took too long. Please try again and if this continues to happen, contact our support team and kindly share details.",

    ERROR_REQUIRED_EMAIL_USERNAME: "Email is required",
    ERROR_REQUIRED_PASSWORD: "Password is required",
    ERROR_REQUIRED_FIRST_NAME: "First Name is required",
    ERROR_REQUIRED_LAST_NAME: "Last Name is required",
    ERROR_REQUIRED_COMPANY: "Company is required",
    ERROR_REQUIRED_EMAIL: "Email is required",

    //Authentication Errors
    ERROR_REQUIRED_VERIFICATION_CODE: "Verification Code is required",
    ERROR_AUTHENTICATION_UNKNOWN_TITLE: "Login failed",
    ERROR_AUTHENTICATION_UNKNOWN_MESSAGE:
        "Login failed unexpectedly. Please try again and if this continues to happen contact our support team at support@sablono.com.",
    ERROR_AUTHENTICATION_NO_SESSION_TITLE: "session expired",
    ERROR_AUTHENTICATION_NO_SESSION_MESSAGE: "Your session has expired.",
    ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD_TITLE:
        "Invalid email or password",
    ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD_MESSAGE:
        "Your email or password is not valid.",
    ERROR_AUTHENTICATION_USER_DEACTIVATED_TITLE: "users disabled",
    ERROR_AUTHENTICATION_USER_DEACTIVATED_MESSAGE:
        "Your user is disabled. Please contact the support.",
    ERROR_AUTHENTICATION_USER_OUTSIDE_VALIDITY_PERIOD_TITLE: "users expired",
    ERROR_AUTHENTICATION_USER_OUTSIDE_VALIDITY_PERIOD_MESSAGE:
        "Your account has expired. Please contact the support.",
    ERROR_AUTHENTICATION_USER_LOCKED_TITLE: "users locked",
    ERROR_AUTHENTICATION_USER_LOCKED_MESSAGE:
        "Your account has been automatically locked because of too many login attempts. Please wait for 15 minutes and try again. If the problem persists, please contact our support team at support@sablono.com.",
    ERROR_AUTHENTICATION_USER_PWD_CHANGE_TITLE: "Password change required",
    ERROR_AUTHENTICATION_USER_PWD_CHANGE_MESSAGE:
        "Please change your password before continuing.",

    ERROR_PASSWORD_NOT_EQUAL_TITLE: "Password is not the same",
    //form validation
    ERROR_INPUT_REQUIRED_TITLE: "Required field",
    ERROR_INPUT_EMAIL_REQUIRED_TITLE:
        "Please enter an email for contacting you.",
    ERROR_MINLENGTH_TITLE: "min. {{minlength}} characters",
    ERROR_MAXLENGTH_TITLE: "max. {{maxlength}} characters",
    ERROR_MIN_ONE_LOWERCASE_LETTER_TITLE: "at least one lowercase letter",
    ERROR_MIN_ONE_UPPERCASE_LETTER_TITLE: "at least one capital letter",
    ERROR_MIN_ONE_SPECIAL_CHAR_TITLE: "at least one special character",
    ERROR_MIN_ONE_NUMBER_TITLE: "at least one digit (0-9)",
    ERROR_NUMBER_ONLY: "Enter a valid number",
    ERROR_NO_DECIMAL: "Please only use whole numbers",

    //Roles error
    ERROR_INSUFFICIENT_PRIVILEDGES_FOR_REMOVE_USER_FROM_GROUP_MESSAGE:
        "You are not authorized to remove an user",
    ERROR_GROUP_DOES_NOT_EXIST_MESSAGE:
        "The group does not exist on the platform",
    ERROR_INSUFFICIENT_PRIVILEDGES_FOR_ADD_USER_TO_GROUP_MESSAGE:
        "You are not authorized to add an user",
    ERROR_USER_ALREADY_IN_PROJECT: "The user you add is already in the project",
    ERROR_USER_ALREADY_IN_PROJECT_MESSAGE:
        "{{users}} {{(num === 1) ? 'is' : 'are'}} already in the project",
    ERROR_LAST_ADMIN_IN_PROJECT: "A project must have at least one admin",
    ERROR_FORBIDDEN_USER_IN_MULTIPLE_SYSTEM_GROUPS_MESSAGE:
        "It is not allowed to add an user in multiple default/system groups.",

    // ui errors
    ERROR_UNEXPECTED_TITLE:
        "Ups.. that should not happen. Better go back to dashboard.",

    ERROR_UNSUPPORTED_FILE_EXTENSION:
        "Invalid file type. Supported are{{fileTypes}}.",
    ERROR_FAILED_FILE_UPLOAD: "Upload failed. Please retry.",
    ERROR_MULTIPLE_INVITATIONS_PER_PROJECT: "User is already invited",
    /**
     * translations for dialogs
     */
    DIALOG_ALERT_OK: "OK",

    DIALOG_CONFIRM_OK: "OK",
    DIALOG_CONFIRM_CANCEL: "Cancel",
    DIALOG_CONFIRM_CREATE: "Save",
    DIALOG_CONFIRM_CREATE_AND_NEW: "Save & create another",

    DIALOG_SELECT_OK: "Select",
    DIALOG_SELECT_CANCEL: "Cancel",
    DIALOG_SELECT_NEXT: "Next",
    DIALOG_SELECT_BACK: "Back",

    //Unable to load chat script
    DIALOG_HELP_DESK_SCRIPT_BLOCKED_TITLE: "Help Desk Service Warning!",
    DIALOG_HELP_DESK_SCRIPT_BLOCKED_CONTENT:
        "Some extension is blocking help desk service, please disable any ad-blockers to ensure smooth experience.",

    //Unable to fetch progress for the project
    DIALOG_FETCH_PROGRESS_ERROR_TITLE: "Fetch progress failure",
    DIALOG_FETCH_PROGRESS_ERROR_CONTENT:
        "An error occurred while fetching the progress, please try again.",

    //Unable to apply filters due to resulting query being too large too process
    DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_TITLE: "Whoops...",
    DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_CONTENT:
        "Sadly, we can not apply the currently selected Project Structure Filter because of common limitations of your web-browser. Please select a deeper structure part (e.g. Level instead of Building) or another filter criteria.",

    // wrong extension
    DIALOG_WRONG_EXTENSION_TITLE: "File has the wrong format",
    DIALOG_WRONG_EXTENSION_CONTENT:
        "Please select a file with the right format.",

    // error reading file
    DIALOG_READING_FILE_ERROR_TITLE: "File reading Error",
    DIALOG_READING_FILE_ERROR_CONTENT:
        "An error occurred while reading the file, please try again",

    // error file has false or not existing fields
    DIALOG_ANALYSING_FILE_ERROR_TITLE: "File reading Error",
    DIALOG_ANALYSING_FILE_ERROR_CONTENT:
        "An error occurred while reading the file, some fields are missing or corrupted. Please check your file.",

    // import error
    DIALOG_IMPORT_FILE_ERROR_TITLE: "File import Error",
    DIALOG_IMPORT_FILE_ERROR_CONTENT:
        "An error occurred while importing the file, please try again",

    ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF_TITLE:
        "@:SECTION_MERGE_ERROR_TITLE",
    ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF:
        "The PDF could not be exported due to the large number of pages. Please select a smaller timerange or smaller number of Deliverables.",
    DIALOG_USER_CANCELED: "Action canceled",

    DIALOG_IMPORT_MERGE_CONFLICT_TITLE: "Warning",
    DIALOG_IMPORT_MERGE_CONFLICT_CONTENT:
        "It seems like you are about to import a schedule which is very similar to one that was already imported to your project. If you choose to continue, every task will be added to your Sablono project again. You can also update your project to avoid duplicated elements.",
    DIALOG_IMPORT_MERGE_ACTION: "Update project",
    DIALOG_IMPORT_CONTINUE_ACTION: "Continue importing",

    DIALOG_ASSIGN_TEMPLATE_TITLE: "Assign workflow template to deliverable",
    DIALOG_ASSIGN_TEMPLATE_TEXT:
        "Select a workflow template which you want to assign to your currently selected deliverable.",
    DIALOG_ASSIGN_TEMPLATE_NO_DATA_TEXT:
        "It seems like you did not create a Workflow Template, yet. Use the button below to open the editor.",
    DIALOG_ASSIGN_TEMPLATE_NO_TEMPLATES_NAV_TO: "Go to template creation",
    ACTION_ASSIGN_TEMPLATE: "Assign template",
    DIALOG_ASSIGN_TEMPLATE_SEARCH_PLACEHOLDER:
        "Search templates by Name, Code...",
    DIALOG_ASSIGN_TEMPLATE_EMPTY_SEARCH_TEXT:
        "There are no templates matching your search term.",

    DIALOG_CLONE_TEMPLATE_TITLE: "@:ACTION_CLONE_TEMPLATE",
    DIALOG_CLONE_TEMPLATE_TEXT:
        "Please, select the Workflow Template which you want to copy into this project from the list below.",
    DIALOG_CLONE_TEMPLATE_BUTTON_TEXT: "Copy template",
    DIALOG_CLONE_TEMPLATE_SUCCESS_TOAST_TEXT:
        "Workflow Template successfully copied",
    DIALOG_CLONE_TEMPLATE_SEARCH_PLACEHOLDER:
        "Search templates by Name, Code and Project...",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_AND_QA_TITLE:
        "Now, specify what should happen with QA Checklists and Teams linked to the selected Workflow Template.",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_SKIP:
        "Do not copy and attach any QA Checklists or Teams",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_REUSE:
        "Use existing and create new QA Checklists and Teams to attach to the copied Workflow Template",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_CLONE:
        "Copy all QA Checklists and Teams and attach them to the copied Workflow Template (may lead to duplicates)",

    // creating project
    DIALOG_CREATING_PROJECT: "Creating project",
    DIALOG_BUSY_TAKE_TIME: "This might take a while",

    // creating new activities
    DIALOG_CREATE_ACTIVITY_TOOLBAR_TITLE: "Create new activity",
    DIALOG_ACTION_CREATE_ACTIVITY: "Create activity",
    _RECENT_ACTIVITIES: "Recently created activities",
    _ADD_ACTIVITY_TOOLTIP: "Add activity to this deliverable.",

    // md-table
    ROWS_PER_PAGE: "Rows per page",

    UPLOAD_ERROR: "Upload failed",

    // Create new deliverables dialog
    ACTION_CREATE_DELIVERABLES: "Add deliverables",
    DIALOG_CREATE_NEW_DELIVERABLES_TITLE: "Create new deliverables",
    INPUT_NAME_NEW_DELIVERABLE_PLACEHOLDER:
        "E.g. Apartment 3.2.1, Room 202, Façade Panel 3777-08",
    INPUT_CODE_NEW_DELIVERABLE_PLACEHOLDER:
        "E.g. Apt. 3.2.1, R-202, FP-3777-08 - maximum 64 characters",
    DIALOG_CREATE_NEW_DELIVERABLE_SAVED: "Deliverable saved",
    DIALOG_CREATE_NEW_DELIVERABLES_ERROR_EXISTING_CODE:
        "Error: There's a deliverable with that code already in the project. Please choose a different one.",

    UPLOAD_SUCCESSFUL: "Upload successful",
    UPLOAD_SUCCESSFUL_CREATED_INFO:
        "{{elements}} deliverable{{(elements > 1) ? 's' : ''}} created and added to your project.",
    UPLOAD_SUCCESSFUL_UPDATED_INFO:
        "{{elements}} deliverable{{(elements > 1) ? 's' : ''}} updated with new values from the file.",
    UPLOAD_SUCCESSFUL_DELETED_INFO:
        "{{elements}} deliverable{{(elements > 1) ? 's' : ''}} removed from the project.",
    UPLOAD_SUCCESSFUL_CREATED_STRUCTURE_INFO:
        "{{elements}} structure element{{(elements > 1) ? 's' : ''}} created and added to your project.",
    UPLOAD_SUCCESSFUL_UPDATED_STRUCTURE_INFO:
        "{{elements}} structure element{{(elements > 1) ? 's' : ''}} updated with new values from the file.",
    UPLOAD_SUCCESSFUL_DELETED_STRUCTURE_INFO:
        "{{elements}} structure element{{(elements > 1) ? 's' : ''}} removed from the project.",
    UPLOAD_SUCCESSFUL_UPDATED_INFO_NO_FILE:
        "{{elements}} deliverable{{(elements > 1) ? 's' : ''}} updated with new values.",
    UPLOAD_SUCCESS_WARN_LIMITS_TITLE:
        "The project you just imported contains text exceeding our system limits. In order to import, we had to shorten it accordingly.",
    UPLOAD_SUCCESS_WARN_LIMITS_MESSAGE:
        "Our limits are: 64 characters for deliverable codes, 120 characters for deliverable names and 1000 characters for deliverable descriptions.",

    TO_PROJECT_DATA: "View Deliverables",
    GO_TO_DELIVERABLES: "Go To Deliverables",

    DIALOG_UPLOADING_FILE_ERROR_TITLE: "Data Uploading Error",
    DIALOG_UPLOADING_FILE_ERROR_CONTENT:
        "An error occured while uploading the data, please try again.",

    DIALOG_UPLOADING_REMAINING_TIME_LEFT:
        "Estimated time left: {{time}} seconds.",
    DIALOG_PREVENT_CLOSING:
        "There is a process running that should not be interrupted!",

    // import
    INFO_IMPORT_PROJECT_DATA_INTRODUCTION_MESSAGE:
        "When setting up a new project, please first import Deliverables from Microsoft© Excel or Asta Powerproject.\n Later, you can also import Activity Dates or Progress via Microsoft© Excel.",
    IMPORT_UPLOAD_COMPONENTS_IN_PROGRESS:
        "We are sending {{ elements }} elements to Sablono",
    SECTION_IMPORT_SUCCESSFULL_PROJECT_IMPORT_HEADER:
        "Project successfully imported",

    // excel import
    LEVEL: "WBS Level ",
    ASSIGN_PROJECT_ELEMENTS: "Deliverables",
    CREATE_STRUCTURE: "Project Structure",
    ADD_STURCUTRE_LEVEL: "Add WBS Level",
    NOT_ASSIGNED: "Not Assigned",
    INFO_IMPORT_NO_DEFINED_STRUCTURE: "No Structure has been defined",
    ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_TITLE: "Structures inconsistent",
    ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_MESSAGE:
        "The structures in rows {{ invalidRowNumberRanges.join(', ') }} are inconsistent. Please revise your data in the import file and try again.",
    INFO_HOW_TO_IMPORT_A_EXCEL_PROJECT:
        "How to import a Microsoft Excel project",

    NO_MATCH_FOUND: "No match found...",

    // import preview
    STRUCTURE: "Project Structure",
    STRUCTURE_BRACES: "Project Structure (Summary Tasks)",
    PROJECT_ELEMENTS: "Deliverables",
    PROJECT_ELEMENTS_BRACES: "Deliverables (Tasks)",
    REFRESH_PREVIEW: "Refresh Project Preview",

    // Select file
    ACTION_REFRESH_SELECTED_FILE_TOOLTIP: "Refresh selected file",
    PLEASE_SELECT_FILE: "Please select a File",

    EDGE_MODE_ACTIVE:
        "Edge Mode Active - Click on a Node to create an edge between them.",
    EDGE_CREATE_SUCCESSFUL: "Successfully created dependency",
    EDGE_DELETE_SUCCESSFUL: "Successfully deleted dependency",

    TOGGLE_EDIT_MODE_TOOLTIP: "Edit",

    ACTION_ACTIVATE_EDIT_MODE: "Edit deliverable",
    ACTION_DEACTIVATE_EDIT_MODE: "Finish editing",

    // xml import
    XML_IMPORT_NO_STRUCTURE:
        "We could not detect a project structure in the file you are trying to upload. You can easily set it up after the import is finished using the work breakdown structure editor.",
    // general PDF
    PDF_GEN_PROGRESS: "File generated.",

    //qr codes
    ACTION_QR_CODE_GENERATE: "Print QR-Codes",
    ACTION_QR_CODE_GENERATE_TOOLTIP:
        "Create printable QR codes for your structure as a PDF.",
    QR_DIALOG_TITLE: "Select a format",
    QR_2x6_TITLE: "2 x 6 QR-Codes",
    QR_1x2_TITLE: "1 x 2 QR-Codes",
    QR_STICKER_POWERED_BY: "Powered by ",
    QR_STICKER_WARN_TEXT:
        "Sticker may only be removed on instruction of the owner.",
    QR_DIALOG_LAST_LEVEL_TEXT: "Print for Structure Elements of the last level",
    QR_DIALOG_SELECT_STRUCTURE_TITLE:
        "Print only for selected Structure Elements",
    QR_DIALOG_STRUCTURE_SELECTION_TEXT:
        "Create QR-Codes for a selection of Structure Elements",
    QR_DIALOG_STRUCTURE_ALL_TEXT: "Print for all Structure Elements",
    ERROR_REPORT_SIZE_LIMIT_TITLE: "Your requested report is too big",
    QR_DIALOG_ERROR_REPORT_SIZE_MESSAGE:
        "Please create your QR codes for less than {{max}} deliverables.",
    QR_DIALOG_WBS_ERROR_REPORT_SIZE_MESSAGE:
        "Please create your QR codes for less than {{max}} Structure Elements.",
    DELIVERABLE_REPORT_ERROR_SIZE_MESSAGE:
        "Please create this report for less than {{max}} deliverables.",
    ACTIVITIES_REPORT_ERROR_SIZE_MESSAGE:
        "Please create this report for less than {{max}} activities.",

    // code generation
    ACTION_GENERATE_CODES: "Generate Codes",
    ACTION_GENERATE_CODES_TOOLTIP:
        "Generate codes for all your structure nodes.",
    CODE_GENERATION_CONFIRM_DIALOG_TITLE: "Structure Code Generation",
    CODE_GENERATION_CONFIRM_DIALOG_CONTENT:
        "New codes will be generated for all elements of the Work Breakdown Structure. Previous codes will be overwritten. Would you like to proceed?",
    CODE_GENERATION_SUCCESS_MESSAGE: "Codes generated.",

    // merge structure in wbs
    ACTION_MERGE_STRUCTURE: "Combine structure",
    ACTION_MERGE_STRUCTURE_TOOLTIP: "Automatically merge duplicated structure.",
    DIALOG_MERGE_STRUCTURE_TITLE: "Combine your project structure",
    DIALOG_MERGE_STRUCTURE_TEXT:
        "Please select the project structure (by source) into which you want to integrate duplicated structure parts.<br /><br /><b>Hint:</b> In order to combine structure parts, their name and structure level need to be exactly the same.",
    DIALOG_MERGE_STRUCTURE_LOADING_TITLE: "Just a few seconds...",
    DIALOG_MERGE_STRUCTURE_LOADING_TEXT:
        "We are currently combining your project structure...",
    DIALOG_MERGE_STRUCTURE_SUCCESS_TITLE: "Project structure combined",

    // WBS
    WBS_CREATE_CHILD_TOOLTIP: "Add Sub-Category",
    WBS_CREATE_SIBLING_TOOLTIP: "Add Related Category",
    WBS_DELETE_NODE_TOOLTIP: "Delete Category",
    CONFIRM_DELETE_STRUCTURE_NODE_TITLE: "Are you sure?",
    CONFIRM_DELETE_STRUCTURE_NODE_TEXT:
        "Do you really want to delete this part of your project structure?",

    //SECTIONS

    // login and registration pages
    SUCCESSFUL_USER_SIGNUP:
        "We got your information and will contact you soon.",
    SUCCESSFUL_USER_SIGNUP_GET_MORE_INFO:
        "In between, get to know sablono better yet:",

    // project selection
    PROJECT_SELECTION_PENDING_INVITES_TITLE: "Pending invites",
    PROJECT_SELECTION_PENDING_INVITES_CONTROL_ACCEPT: "Accept",
    PROJECT_SELECTION_PENDING_INVITES_CONTROL_DECLINE: "Decline",
    PROJECT_SELECTION_ACCEPT_INVITATION: "Invitation accepted",
    PROJECT_SELECTION_DECLINE_INVITATION: "Invitation declined",
    PROJECT_SELECTION_WELCOME_CARD_TITLE: "Welcome to Sablono!",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_PROJECT_MANAGER_TEXT:
        "Not a member of a project yet? Please contact your Project Manager to request access.",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_PROJECT_MANAGER_BUTTON_TEXT:
        "Send email now",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_SUPPORT_TEXT:
        "If you run into questions feel free to contact us on weekdays from 9 a.m. to 6 p.m.",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_SUPPORT_BUTTON_TEXT:
        "Contact support",
    PROJECT_SELECTION_WELCOME_CARD_EMAIL_SUBJECT:
        "Please add me to the project on Sablono",
    PROJECT_SELECTION_WELCOME_CARD_EMAIL_BODY:
        "Hi,%0D%0A%0D%0A I have activated my account on Sablono. Please add me to the project now so I can start using the platform.%0D%0A%0D%0A Many thanks!%0D%0A%0D%0A Go to Sablono: https://app.sablono.com/",
    PROJECT_SELECTION_FILTERS_STATUS: "Status",

    // project edit
    PROJECT_LANGUAGE_HELP_TOOLTIP_MESSAGE:
        "The project language adjusts content suggestions that you get on the platform.",
    PROJECT_TIMEZONE_HELP_TOOLTIP_MESSAGE:
        "The project timezone will be used to schedule the activities you set up on the platform.",

    PROJECT_CURRENCY_HELP_TOOLTIP_MESSAGE:
        "The project currency will be shown next to all commercial values on e.g. the Commercial Pro Dashboard or your Activity Tracker",
    //Dashboard
    SECTION_INSPECTIONS_PROGRESS: "Progress Trend (last 10 weeks)",

    // deliverable cards
    DASHBOARD_DELIVERABLES_UNSCHEDULED:
        "Deliverable{{(items ===0 || items > 1) ? 's' : ''}} without start and completion date",
    DASHBOARD_DELIVERABLES_BEHIND:
        "Deliverable{{(items ===0 || items > 1) ? 's' : ''}} behind schedule",
    DASHBOARD_DELIVERABLES_WITH_CLAIMS:
        "Deliverable{{(items ===0 || items > 1) ? 's' : ''}} with open quality issues",
    DASHBOARD_DELIVERABLES_WITH_OBSTRU:
        "Deliverable{{(items ===0 || items > 1) ? 's' : ''}} with open obstructions",
    DASHBOARD_DELIVERABLES_WITH_INFO:
        "Deliverable{{(items ===0 || items > 1) ? 's' : ''}} with info notes",

    DASHBOARD_ALL_DELIVERABLES: "All Deliverables",

    DASHBOARD_STATS_CHART_SELECTION_TITLE:
        "Bar chart: Planned vs. actual completion of work",
    DASHBOARD_STATS_CHART_SELECT_TYPE: "Select Workflow Template",
    DASHBOARD_S_CURVE_CHART_SELECTION_TITLE:
        "S-Curve: Planned vs. actual completion of work",
    DASHBOARD_S_CURVE_CHART_CHART_SELECT_TYPE: "Select activities",
    DASHBOARD_S_CURVE_CHART_CHART_DESELECT_TYPE: "Remove Activity Selection",
    DASHBOARD_S_CURVE_CHART_SELECTION_LABEL: "Selection:",
    DASHBOARD_S_CURVE_CHART_SELECTION_EVERYTHING:
        "All activities that meet the selected filters",
    DASHBOARD_S_CURVE_CHART_SELECTION_NO_MATCH:
        "There are no results matching your current filter criteria",
    DASHBOARD_PRODUCTIVITY_CHART_SELECTION_TITLE:
        "Productivity chart: Planned vs. actual by week",
    DASHBOARD_CHARTS_LEGEND_PLANNED: "Planned",
    DASHBOARD_CHARTS_LEGEND_FINISHED: "Work Finished",
    DASHBOARD_CHARTS_LEGEND_CONFIRMED: "Confirmed",
    DASHBOARD_CHARTS_LEGEND_UNPLANNED: "Unscheduled",
    DASHBOARD_CHARTS_LEGEND_COMPLETED:
        "Finalised: Confirmed + Finished after trade",

    FILTER_BY_STRUCTURE: "Filter by Structure",
    FILTER_BY_ALL_STRUCTURES: "Full project",
    DASHBOARD_S_CURVE_CHART_EMPTY:
        "There are no results matching your current filter criteria. If you selected specific Activities you can remove that selection below.",
    DASHBOARD_BAR_CHART_EMPTY:
        "There are no results matching your current filter criteria.",

    DASHBOARD_FILTER_BY_TEAM: "Filter by Team",
    DASHBOARD_FILTER_BY_ALL_TEAMS: "All Teams",

    DASHBOARD_FILTER_BY_PROCESS_TEMPLATE: "Filter by Workflow",
    DASHBOARD_FILTER_BY_ALL_PROCESS_TEMPLATES: "All Workflows",

    DASHBOARD_SELECT_ACTIVITIES_DIALOG_TITLE: "Filter by Activities",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_SEARCHBAR_PLACEHOLDER:
        "Search for Activities by Workflow Template, Name and Team...",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_DESCRIPTION:
        "Please select the combination of activities which you want to see in the S-Curve:",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_SELECT_BUTTON: "Show combination",

    // Dashboard no data
    SECTION_DASHBOARD_NO_DATA_TITLE: "Please finish your project setup",
    SECTION_DASHBOARD_NO_DATA_CARD_1_TITLE: "Create your first Deliverables",
    SECTION_DASHBOARD_NO_DATA_CARD_1_TEXT:
        "You can import Deliverables from Microsoft© Excel and Asta Powerproject. Or use the button above to manually create them.",
    SECTION_DASHBOARD_NO_DATA_CARD_1_ACTION: "Go to import",
    SECTION_DASHBOARD_NO_DATA_CARD_2_TITLE: "Finish your setup on Sablono",
    SECTION_DASHBOARD_NO_DATA_CARD_2_TEXT:
        "After creating Deliverables, please go ahead and create a Workflow Template. To be able to start using this project you need to assign the Workflow Template to your Deliverables and create your Activities.",
    SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION: "Go to deliverables",
    SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION_2: "Go to workflow templates",

    SECTION_DASHBOARD_NO_DATA_TEMPLATES_TITLE: "Create a workflow template",
    SECTION_DASHBOARD_NO_DATA_TEMPLATES_TEXT:
        "Workflow templates have to be assigned to deliverables to be able to tick off activities.",
    SECTION_DASHBOARD_NO_DATA_TEMPLATES_ACTION: "Go to Template Editor",

    INFO_STRUCTURE_PROGRESS:
        "{{allElements}} Deliverables, {{finishedElements}}% finished",
    ERROR_EMPTY_TIMELINE_TITLE: "There are no updates yet",

    //Timeline
    INFO_SHOW_ALL_INSPECTIONS: "Show all {{totalAmountOfInspections}} updates",
    INSPECTION_DETAIL_TITLE: "Reported by - {{author}}",
    INSPECTION_DETAIL_SUBTITLE: "Uploaded {{time}}",
    INSPECTION_DETAIL_HEADER_PROGRESS: "{{number}} deliverables updated",
    INSPECTION_DETAIL_HEADER_NEW_NOTES: "{{number}} notes created",
    INSPECTION_DETAIL_HEADER_RESOLVED_NOTES: "{{number}} issues resolved",
    INSPECTION_DETAIL_PAGE_NO_FILTERED_DATA:
        "There are no updates matching your filter criteria.",
    INSPECTION_DETAIL_FILTER_AUTHOR: "Reporter",
    INSPECTION_DETAIL_FILTER_TIMESTAMP: "Date",
    INSPECTION_DETAIL_NO_DATA_TITLE: "No updates available, yet",
    INSPECTION_DETAIL_NO_DATA_TEXT:
        "No updates for your project have been uploaded, yet. After project setup is complete, you can either use our mobile applications or e.g. the Deliverables page to upload progress.",
    INSPECTION_DETAIL_NO_DATA_ACTION: "Go to Deliverables",

    //PROFILE PAGE
    SECTION_USER_PROFILE_TITLE: "User Profile",
    ERROR_PASSWORD_CHANGE_LIFETIME_MESSAGE:
        "Password change currently not allowed: minimal password lifetime is one day. Wait {{time}}",
    SECTION_YOUR_INFORMATION_TITLE: "Personal information",
    INFO_GENDER_FEMALE_TITLE: "Ms.",
    INFO_GENDER_MALE_TITLE: "Mr.",
    ERROR_EMAIL_FORMAT_MESSAGE: "Must be in the correct format.",
    INPUT_SALUTATION_TITLE: "Salutation",
    INPUT_TITLE_TITLE: "Title",
    INPUT_FIRSTNAME_TITLE: "First name",
    INPUT_LASTNAME_TITLE: "Last name",
    INPUT_COMPANY_TITLE: "Company",
    ACTION_SAVE: "Save",
    ACTION_PUBLISH: "Publish",
    SECTION_CHANGE_PASSWORD_TITLE: "Change password",
    CHANGE_PASSWORD_ENTER_OLD_TITLE: "Confirm",
    CHANGE_PASSWORD_ENTER_OLD_MESSAGE:
        "Please enter the old password to confirm.",
    ACTION_CHANGE: "Change",
    SECTION_EMAIL_ADRESSES_TITLE: "Email Addresses",
    INFO_PRIMARY_TITLE: "Primary",
    INFO_PRIMARY_NOT_VERIFIED: "Not verified",
    INFO_PRIMARY_VERIFIED: "Verified",
    INPUT_NEW_EMAIL_TITLE: "Add new email",
    INFO_SAVE_SUCCESS_TITLE: "Saved Successfully",
    INFO_PASSWORD_CHANGE_SUCCESS_TITLE: "Password changed Successfully",
    ACTION_CHANGE_PICTURE: "Select an image",

    ACTION_FILTER: "Filter",
    ACTION_CLEAR_FILTERS: "Remove filters",

    //Add Role Dialog
    _ROLE_DESCRIPTION_ADMIN:
        "A Project Administrator can access all features within this project.",
    _ROLE_DESCRIPTION_MANAGER:
        "A Project Manager can access all features within this project except for Project Settings and Editing Notes.",
    _ROLE_DESCRIPTION_INSPECTOR:
        "A Site Inspector can access Sablono's mobile app, the Lean Planning and Daily Logs page. Users in this role are allowed to upload progress.",
    _ROLE_DESCRIPTION_CONTROLLER:
        "A Project Controller can access Sablono's mobile app and most pages except for Setup. Users in this role are allowed upload progress.",
    _ROLE_DESCRIPTION_REVIEWER:
        "A Project Reviewer has read-only permissions for this project.",
    ACTION_ADD: "Add",

    SECTION_TEAMS_ADD_USER_DIALOG_TITLE:
        "Invite a new member to <b>{{name}}</b>",
    SECTION_TEAMS_ADD_USER_DIALOG_TEXT:
        "Members added to this team will only be able to report on activities assigned to it or to 'everyone in the project'. You can set this up during the creation of workflow templates.",
    //  -We could have a link here to the editor - Thought ?
    SECTION_TEAMS_ADD_USER_DIALOG_OK: "Invite",
    SECTION_TEAMS_ADD_USER_INPUT_INVALID: "Please enter a valid e-mail address",

    SECTION_TEAMS_EDIT_USER_DIALOG_TITLE: "Edit <b>{{name}}</b>",
    SECTION_TEAMS_EDIT_USER_DIALOG_OK: "Edit member",
    SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_ROLE:
        "Or change the role of the user in this project.",
    SECTION_TEAMS_ADD_USERS_DIALOG_CHANGE_ROLE:
        "Please select a role defining the access rights within this project:",
    SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_TEAM:
        "Here, you may move this user to another team.",
    SECTION_TEAMS_ADD_NEW_USER_BADGE: "Add a new member",
    SECTION_TEAMS_ADD_NEW_COMMERCIAL_USER_BADGE: "Add a commercial user",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_TITLE:
        "Adapt invitation for <b>{{name}}</b>",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_CHANGE_TEAM:
        "Here, you may move this user to another team.",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_CHANGE_ROLE:
        "Or change the role of the user in this project.",
    ACTION_INVITATION_RESEND: "Resend invitation",
    ACTION_INVITATION_UPDATE_AND_RESEND: "Edit and resend invitation",
    INPUT_SEARCH_USER_MESSAGE: "Search for a user...",
    INPUT_SELECT_ROLE_MESSAGE: "Select a role...",
    //Project Team
    SECTION_INSPECT_APP_SETTINGS_TITLE: "Mobile App Settings",
    SECTION_INSPECT_APP_SETTINGS_COMBINED_STATUS_CHANGES_ENABLED:
        "Mobile users are able to change the status of whole deliverables and all its activities at once. Hint: Should only be activated, if really needed.",
    SECTION_INSPECT_APP_SETTINGS_GEOLOCATION: "Capture geo location for photos",
    SECTION_INSPECT_APP_GEOLOCATION_PERMISSIONS:
        "Photos taken or uploaded to Sablono will include the geo location of the position they were taken in, if the information is available. This will help to comply with Part L regulations.",

    SECTION_INSPECT_APP_SETTINGS_STATUS_CHANGES_TITLE:
        "Combined status changes",
    SECTION_INSPECT_APP_SETTINGS_HIDE_READONLY_ENTITIES_TITLE:
        "[Only Inspect app] Limit visible activities",
    SECTION_INSPECT_APP_SETTINGS_HIDE_READONLY_ENTITIES_ENABLED:
        "The Inspect app will only show activities which may be edited by the current user.",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_TITLE:
        "[Only Inspect app] Information layout",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_NAME:
        "The Names of your Deliverables are more prominently presented in the Inspect app.",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_CODE:
        "The Codes of your Deliverables are more prominently presented in the Inspect app.",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_WORK_TITLE:
        "[Execution team] Sign QA Checklist by password",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_WORK_DESCRIPTION:
        "As an additional security measure, users of the Execution team will need to enter their password when uploading QA Checklists using the mobile app. The app will always capture user name and upload time even if this setting is off.",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_INSPECTION_TITLE:
        "[Inspection & Confirmation team] Sign QA Checklist by password",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_INSPECTION_DESCRIPTION:
        "As an additional security measure, users of the Confirmation team will need to enter their password when uploading QA Checklists using the mobile app. The app will always capture user name and upload time even if this setting is off.",

    SECTION_QUALITY_PRIVACY_TITLE: "Quality visibility",
    SECTION_QUALITY_PRIVACY_ACTIVATE_LIMITED_NOTE_VISIBILITY_TITLE:
        "Limited Note visibility",
    SECTION_QUALITY_PRIVACY_ACTIVATE_LIMITED_NOTE_VISIBILITY_DESCRIPTION:
        "Users will only see Notes that their team reported, for which their team is responsible or which are attached to activities that their team is responsible for.",

    SECTION_EMAIL_NOTIFICATIONS_TITLE: "Email notifications",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_DAILY_UPDATE_TITLE:
        "Daily Activity Update",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_DAILY_UPDATE_DESCRIPTION:
        "Users will receive a daily email each afternoon showing for their team which Activities are Available, Confirmed, Rejected and where successors already started.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_REALTIME_UPDATE_TITLE:
        "Real-time Activity Update",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_REALTIME_UPDATE_DESCRIPTION:
        "Users will receive a real-time email showing for their team which Activities are Available, Confirmed and Rejected.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_REMINDER_TITLE:
        "Weekly Activity Update Reminder",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_REMINDER_DESCRIPTION:
        "Users will receive a weekly email on Monday mornings showing for their team all Activities where successors already started.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_LOOKAHEAD_TITLE:
        "Weekly Look Ahead Schedule",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_LOOKAHEAD_DESCRIPTION:
        "Users will receive a weekly email on Monday mornings showing for their team scheduled Activities within the upcoming 14 days as well as late Activities.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_OBSTRUCTION_CONCERNING_MY_TEAM_TITLE:
        "Real-time Obstruction Issue Update",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_OBSTRUCTION_CONCERNING_MY_TEAM_DESCRIPTION:
        "Users will receive a real-time email showing recently created Obstruction Issues for which their team is responsible or supposed to confirm.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_QUALITY_CONCERNING_MY_TEAM_TITLE:
        "Real-time Quality Issue Update",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_QUALITY_CONCERNING_MY_TEAM_DESCRIPTION:
        "Users will receive a real-time email showing recently created Quality Issues for which their team is responsible or supposed to confirm.",
    SECTION_NOTE_CONFIRMATION: "Issue Confirmation Settings",
    OBSTRUCTION_CONFIRMATION_TITLE: "Obstruction Issues",
    OBSTRUCTION_CONFIRMATION_DESCRIPTION:
        "If selected, users reporting an Obstruction Issues must assign a Reviewing Team to it. Otherwise it is optional.",
    OBSTRUCTION_DEFAULT_TEAM: "Default Reviewing Team for Obstruction Issues",
    QUALITY_ISSUE_CONFIRMATION_TITLE: "Quality Issues",
    QUALITY_ISSUE_CONFIRMATION_DESCRIPTION:
        "If selected, users reporting a Quality Issue must assign a Reviewing Team to it. Otherwise it is optional.",
    QUALITY_ISSUE_DEFAULT_TEAM: "Default Reviewing Team for Quality Issues",

    INFO_USER_ADD_SUCCESS_TITLE: "{{users}} added successfully",
    INFO_USER_MOVED_SUCCESS_TITLE: "User moved successfully",
    INFO_USER_REMOVE_SUCCESS_TITLE: "User removed from project",
    INFO_NO_MEMBER_1_TITLE: "There are no",
    INFO_NO_MEMBER_2_TITLE: "in your project yet.",
    CONFIRM_DELETE_YOURSELF_MESSAGE:
        "Are you sure you want to delete yourself ? This can not be undone.",
    ACTION_UNDO: "Undo",
    INFO_USER_NOT_FOUND: "User {{user}} not found",
    INFO_LOADING_ADD_MESSAGE: "Adding User",
    INFO_LOADING_ADD_MESSAGE_PLURAL: "Adding Users",
    INFO_LOADING_REMOVE_MESSAGE: "Deleting User",
    INFO_LOADING_REMOVE_MESSAGE_PLURAL: "Deleting Users",
    INFO_LOADING_MOVE_MESSAGE: "Moving User",
    INFO_LOADING_MOVE_MESSAGE_PLURAL: "Moving Users",
    INFO_LOADING_RESTORE_MESSAGE: "Restoring User",
    INFO_LOADING_RESTORE_MESSAGE_PLURAL: "Restoring Users",

    _SUN: "Sun",
    _MON: "Mon",
    _TUE: "Tue",
    _WED: "Wed",
    _THU: "Thu",
    _FRI: "Fri",
    _SAT: "Sat",

    SECTION_PROJECT_SETTINGS_PAGE_TITLE: "Project settings",
    SECTION_PROJECT_CALENDAR_TITLE: "Project calendar",

    SECTION_PROJECT_CURRENCY_TITLE: "Project currency",
    SECTION_SCHEDULING_MODE_TITLE: "Scheduling mode",
    SECTION_SCHEDULING_MODE_SELECTION_DATES_HEADING: "Individual scheduling",
    SECTION_SCHEDULING_MODE_SELECTION_DATES_OPTION:
        "This mode will calculate your baseline schedule while ignoring dependencies between different deliverables.",
    SECTION_SCHEDULING_MODE_SELECTION_EDGES_HEADING: "Connected scheduling",
    SECTION_SCHEDULING_MODE_SELECTION_EDGES_OPTION:
        "This mode will calculate your baseline schedule including dependencies between different deliverables. Please note that results may differ from user-defined start and end dates for your deliverables.",
    SECTION_SCHEDULING_MODE_SELECTION_INFO_TOOLTIP:
        "Don't worry! Switching between Individual and Connected scheduling will never delete any dates. If you are not satisfied with the results of the other mode, you can switch back anytime.",

    ERROR_NO_WORKDAYS_SELECTED_MESSAGE:
        "You have to select at least one working day",
    ERROR_NO_WORKHOURS_SELECTED_MESSAGE: "Please select your working hours",
    ERROR_NO_TIMEZONE_SELECTED_MESSAGE: "Please select a timezone",
    ERROR_INVALID_TIMEZONE_SELECTED_MESSAGE: "Please select a valid timezone",
    DELETE_PROJECT: "Delete project",
    SECTION_PROJECT_INFOS_TITLE: "Project information",
    SECTION_SELECT_WORKING_DAYS_TITLE: "Working days",
    SECTION_SELECT_WORKING_HOURS_TITLE: "Select your working hours",
    INFO_CALENDAR_EDIT_SUCCESS_TITLE: "Calendar updated successfully",
    INFO_CALENDAR_EDIT_LOADING_TITLE: "Calendar is being updated...",
    CONFIRM_DELETE_PROJECT_MESSAGE:
        "Do you really want to delete this project?",
    INFO_PROJECT_EDIT_SUCCESS_TITLE: "Successfully changed",
    INFO_PROJECT_EDIT_LOADING_TITLE: "Project is being updated...",
    INPUT_START_DESCRIPTION: "Start",
    INPUT_END_DESCRIPTION: "End",
    SECTION_SELECT_FIRST_PERIOD_TITLE: "First period",
    SECTION_SELECT_SECOND_PERIOD_TITLE: "Second period",
    SECTION_EXCEPTION_DAYS_TITLE: "Non-Working days",
    SECTION_EXCEPTION_DAYS_INFO:
        "Please enter all days on which your project team will not work, e.g. bank holidays.",
    INPUT_DAY_LABEL_DESCRIPTION: "Occasion",
    ERROR_DUPLICATED_DATES: "You have selected the same day twice.",
    ACTION_ADD_MORE_EXCEPTION_DAY: "Add day",

    //Edit Project
    INFO_PROJECT_REMOVE_SUCCESS_TITLE: "Project deleted successfully",
    ACTION_DELETE_PROJECT_TITLE: "Delete project",
    ACTION_DELETE_PROJECT_CONFIRM_TITLE: "Delete finally?",
    SECTION_EDIT_TITLE: "Edit",

    // edit Component
    INFO_COMPONENT_DATE_CHANGE_SUCCESS_TITLE: "{{name}} successfully changed",

    //////
    // Start of template list page
    //

    // subbar actions
    ACTION_CLONE_TEMPLATE: "Copy existing template",
    ACTION_CREATE_TEMPLATE: "Create new template",

    // group by menu
    ACTION_UNGROUPED: "Ungrouped",
    ACTION_CREATOR: "Creator",
    ACTION_CATEGORY: "Category",
    ACTION_CODE: "Code",
    ACTION_REVISION: "Template Version",
    ACTION_ACTIVITIES: "Activity Count",
    //
    // End of template list page

    // TEMPLATE
    TEMPLATE_GROUP_INPUT_HOURS_OF_WORK_TITLE: "Hours of work",
    TEMPLATE_GROUP_TOOLTIP_DURATION_TITLE: "Duration: ",
    TEMPLATE_GROUP_DURATION_TOOLTIP_TEXT_BEFORE:
        "The duration represents the critical path of your defined workflow. Here,",
    TEMPLATE_GROUP_DURATION_TOOLTIP_LINK_TEXT: "working hours and days",
    TEMPLATE_GROUP_DURATION_TOOLTIP_TEXT_AFTER:
        "as well as hours and days are included.",

    TEMPLATE_GROUP_HOURS_OF_WORK_TOOLTIP_TITLE: "Hours of work: ",
    TEMPLATE_GROUP_HOURS_OF_WORK_TOOLTIP_TEXT:
        "This value is calculated summing up all activity durations contained in this group.",

    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_TEXT:
        "Working days and hours are scheduled based on your Project Calendar. Days and hours use a 24 hours calendar.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_DISABLED_TEXT:
        "Define your planned durations as part of your standard work sequences on Sablono and make the most out of its Baseline and Look Ahead Scheduling capabilities! Contact Sablono today to get access to this feature.",
    CONFIRM_TEAM_DISABLED_TOOLTIP_TEXT:
        "Assign Confirmation Teams to your Activities to be able to sign-off or even reject work and QA Checklists directly from site, fully documented as part of your project audit trail! Contact Sablono today to get access to this feature.",
    TEMPLATE_ACTIVITY_EMPTY_CHECKLIST_TOOLTIP_TEXT:
        "QA Checklists that include no items yet will appear disabled in the list. Please add items to their QA Checklist template to be able to attach them here.",
    TEMPLATE_ACTIVITY_CHECKLIST_DISABLED_TOOLTIP_TEXT:
        "Assign QA Checklists to your Activities and make sure that quality information is captured automatically while reporting progress from site! Contact Sablono today to get access to this feature.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_LINK_TEXT:
        "Working days: {{days}}<br>Working hours: {{times}}",
    SECTION_CREATE_TEMPLATE_TITLE: "Create a new template",
    INPUT_TEMPLATE_NAME_TITLE: "Name",
    INPUT_TEMPLATE_CODE_TITLE: "Code",
    INPUT_UNIT_TITLE: "Unit",
    INPUT_ASSIGNED_TEAM_TITLE: "Execution team",
    INPUT_REVIEW_TEAMS_TITLE: "Inspection Team",
    INPUT_CONFIRM_TEAM_TITLE: "Confirmation team",
    _NOTES_CONFIRMATION_TEAM: "Reviewing Team",
    INPUT_OPTION_NO_CONFIRMATION_REQUIRED: "No separate confirmation requested",
    INPUT_ASSIGNED_CHECKLIST_TITLE: "QA checklist attached",
    INPUT_ASSIGNED_CHECKLIST_NONE: "No QA checklist attached",
    INPUT_SELECT_ASSIGNED_TEAM_PLACEHOLDER: "Choose a team",
    INPUT_SELECT_MIXED_TEAM_ASSIGNMENT_OPTION: "Multiple teams assigned",
    INPUT_SELECT_ADD_NEW_TEAM_OPTION: "Create a new team",
    INPUT_TEMPLATE_DESCRIPTION_TITLE: "Description",
    INPUT_TEMPLATE_CATEGORY_TITLE: "Category",
    INPUT_TEMPLATE_ACTIVITY_NAME_TITLE: "Activity name",
    INPUT_TEMPLATE_STATE_NAME_TITLE: "State name",
    INFO_TEMPLATE_ADD_SUCCESS_TITLE: "The template was successfully created.",
    INPUT_SELECT_PREDECESSOR_TITLE: "Select additional predecessor",
    INPUT_SELECT_SUCCESSOR_TITLE: "Select additional successor",
    INFO_TEMPLATE_EDIT_SUCCESS_TITLE: "The template was successfully edited",
    INFO_TEMPLATE_REMOVE_MESSAGE:
        "{{success}} template{{(success == 1) ? '' : 's'}} deleted. {{errors}} error{{(errors == 1) ? '' : 's'}}",
    INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATES_MESSAGE: "Search for templates..",
    INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATE_CONTENT_MESSAGE:
        "Search in the template..",
    ACTION_GROUP_BY: "Group by",
    ACTION_GROUP_BY_TYPE: "Group by type",
    ACTION_GROUP_BY_CREATOR: "Group by creator",
    ACTION_GROUP_BY_CREATION_DATE: "Group by date",
    ACTION_KEY: "Code",
    ACTION_NAME: "Name",
    ACTION_DESCRIPTION: "Description",
    ACTION_TEXT: "Text",
    ACTION_TYPE: "Type",
    ACTION_SAVE_TEMPLATE: "Save workflow template",
    ACTION_DATE: "Date",
    INFO_NO_TEMPLATES_YET_TITLE:
        "No workflow templates created for this project yet",
    INFO_NO_TEMPLATES_YET_TEXT:
        "Workflow templates can be used to capture highly detailed information on construction sequences. They contain activities which can be structured in groups and be linked by dependencies. <br /><img src='images/attach-template_en_slow.gif'/><br /> After creating a template simply assign it to the deliverables of your project and start ticking off activities.",
    INFO_NO_TEMPLATES_YET_BUTTON_LEFT: "Create new",
    INFO_NO_TEMPLATES_SEARCH_1_MESSAGE: "Your search for",
    INFO_NO_TEMPLATES_SEARCH_2_MESSAGE: "did not match any existing templates.",
    INFO_NO_ACTIVITIES_IN_GROUP:
        "This group does not contain any activities for progress tracking.",
    INFO_NO_WORKFLOW_ASSIGNED_YET_TITLE: "Please assign a Workflow Template",
    INFO_NO_WORKFLOW_ASSIGNED_YET_MESSAGE:
        "To be able to track status of activities, you need to assign a Workflow Template to this Deliverable next.",
    INFO_EMPTY_WORKFLOW_ASSIGNED_TITLE:
        "Workflow template without trackable activities",
    INFO_EMPTY_WORKFLOW_ASSIGNED_MESSAGE:
        "Your assigned workflow template does not contain any trackable activities yet. Add activities by editing your template.",
    SECTION_NO_GROUP_ELEMENT_TITLE: "No ",
    ACTION_ADD_TEMPLATE_DESCRIPTION: "Add template",
    SECTION_ALL_THE_TEMPLATES_1_TITLE: "All",
    SECTION_ALL_THE_TEMPLATES_2_TITLE: "templates",
    CONFIRM_DELETE_TEMPLATES_MESSAGE:
        "Are you sure you want to delete {{value}} template{{(value == 1) ? '' : 's'}} ?",
    ALERT_DELETE_TEMPLATES_NOT_ALLOWED_TITLE: "Not allowed",
    ALERT_DELETE_TEMPLATES_NOT_ALLOWED_MESSAGE:
        "Workflow templates can not be deleted if they are assigned to Deliverables. The workflow template(s) you are trying to delete are still used by {{value}} deliverable{{(value == 1) ? '' : 's'}}. \nPlease assign a new workflow template to those Deliverables to be able to continue.",
    SECTION_GROUPS: "Groups",
    SECTION_ACTIVITIES: "Activities",
    INFO_ACTIVITY_TITLE: "Activity",
    INFO_TEMPLATE_NO_CHILDREN:
        "This template does not have any groups or activities yet",
    ACTION_TEMPLATE_ADD_CONTENT: "Open in Editor",
    ACTION_TEMPLATE_DELETE_NOTE: "Delete Note",
    CONFIRM_DELETE_NOTE_MESSAGE: "Are you sure you want to delete this note?",
    ACTION_ADD_GROUP: "Add a new group to {{name}}",
    ACTION_ADD_ACTIVITY: "Add a new activity to {{name}}",
    ACTION_DELETE_GROUP: "Delete Group",
    ACTION_DELETE_ACTIVITY: "Delete Activity",
    ACTION_TRANSFORM_ACTIVITY: "Transform Activity to Group",
    ACTION_DISCARD_CHANGES: "Discard Changes",
    SECTION_TEMPLATE_EDITOR: "Template Editor",
    CONFIRM_TEAM_OVERWRITE_CONFIRM_TITLE: "Overwrite assigned teams?",
    CONFIRM_TEAM_OVERWRITE_CONFIRM_TEXT:
        "If you assign a execution team to this group you will overwrite assignments already done for contained activities. Are you sure that you want to proceed?",
    CONFIRM_DELETE_GROUP_MESSAGE:
        "Are you sure you want to delete the group {{name}}? All contents of the group will be deleted as well.",
    CONFIRM_DELETE_ACTIVITY_MESSAGE:
        "Are you sure you want to delete the activity {{name}}? Please keep in mind that after reassigning the Workflow Template, progress, notes and pictures connected to this activity on all your deliverables will no longer be available. This action cannot be reversed.",
    INFO_TEMPLATE_NODE_REMOVE_SUCCESS_TITLE: "{{ name }} successfully deleted.",
    CONFIRM_DISCARD_CHANGES_MESSAGE:
        "Are you sure you want to discard all your changes and download the template from the server again?",
    INFO_TRANSFORM_ACTIVITY:
        "Groups and Activities can not be assigned to activities. To do so, you have to transform the activity into a group.",
    DIALOG_TRANSFORM_ACTIVITY_OPTION:
        "Please select one of the following approaches",
    SELECTION_TRANSFORM_ACTIVITY_KEEP:
        "Create a new group and add this activity to that group.",
    SELECTION_TRANSFORM_ACTIVITY_DISCARD:
        "Create a new group and delete this activity.",
    INFO_CREATE_NEW_GROUP: "Create a new Group...",
    INFO_TEMPLATE_SAVED_SUCCESSFULL: "Template saved successfully",
    INFO_CANNOT_DRAG_INTO_ACTIVTY_MESSAGE:
        "You cannot move an activity or group into another activity",
    INFO_CANNOT_DRAG_INTO_CHILD_MESSAGE:
        "You cannot move this group into one of its subgroups",
    SECTION_PREDECESSOR_TITLE: "Depends on",
    SECTION_SUCCESSOR_TITLE: "Followed by",
    SECTION_CURRENT_TITLE: "Current Activity",
    ACTION_EXPAND_ALL: "Expand all",
    ACTION_COLLAPSE_ALL: "Collapse all",
    INFO_CIRCLE_PREDECESSOR_MESSAGE:
        "You already defined this activity as a successor in the workflow. Adding it as a predecessor would lead to a workflow cycle.",
    INFO_CIRCLE_SUCCESSOR_MESSAGE:
        "You already defined this activity as a predecessor in the workflow. Adding it as a successor would lead to a workflow cycle.",
    INFO_SAVING_MESSAGE: "Saving Changes...",
    INFO_DISCARDING_MESSAGE: "Discarding...",
    LABEL_EDIT_ACTIVITY: "Edit activity",
    INFO_LAST_CHANGES_TIME: "Your changes were saved on this device",
    INFO_LAST_CHANGES_TIME_PUBLISH: "Publish now",
    SELECT_EXIT_EDITOR_TITLE: "Leave template editor",
    SELECT_EXIT_EDITOR_MESSAGE:
        "Your changes have not been published yet. How do you want to proceed?",
    SELECT_EXIT_EDITOR_DISMISS: "Stay on this page",
    SECTION_CHANGE_PARENT_DIALOG_TITLE: "Select another group",
    ACTION_MOVE_TO_ANOTHER_PARENT: "Move to another group",
    ACTION_PUBLISH_AND_UPDATE_DELIVERABLES: "Save and assign",
    INFO_UPDATING_DELIVERABLES_MESSAGE: "Updating deliverables...",
    INFO_UPDATING_DELIVERABLES_SUCCESSFUL: "Deliverables successfully updated",
    INFO_UPDATING_DELIVERABLES_SUCCESSFUL_N:
        "{{deliverables}} deliverable{{(deliverables ===0 || deliverables > 1) ? 's' : ''}} successfully updated",
    INFO_PUBLISHING_MESSAGE: "Publishing Changes...",
    INFO_TEMPLATE_PUBLISHED_SUCCESSFUL: "Template published successfully",
    TEMPLATE_EDITOR_NO_DATA_TITLE:
        "No {{(type=='GROUP')?'group':'activity'}} added yet",
    TEMPLATE_EDITOR_NO_DATA_GROUP_TEXT:
        "A group consists of multiple activities and is used to structure your template. Add an existing group or create a new one using the field above.",
    TEMPLATE_EDITOR_NO_DATA_ACTIVITY_TEXT:
        "Activities consist of an activity and a target state (e.g. deliver - delivered). Add an existing activity or create a new one using the field above.",
    TEMPLATE_EDITOR_NO_PREDECESSOR_TITLE: "No predecessor selected yet",
    TEMPLATE_EDITOR_NO_PREDECESSOR_TEXT:
        "Predecessors have to be finished before the current activity can be started. Select an activity of this workflow template using the field above.",
    TEMPLATE_EDITOR_NO_SUCCESSOR_TITLE: "No successor selected yet",
    TEMPLATE_EDITOR_NO_SUCCESSOR_TEXT:
        "Successors can only be started after the current activity was finished. Select an activity of this workflow template using the field above.",
    INFO_NO_ADDITIONAL_EDGE_POSSIBLE_IN_TEMPLATE_PREDECESSOR:
        "Every activity created in this template would result in a workflow cycle if used again. Please create more activities before defining another predecessor.",
    INFO_NO_ADDITIONAL_EDGE_POSSIBLE_IN_TEMPLATE_SUCCESSOR:
        "Every activity created in this template would result in a workflow cycle if used again. Please create more activities before defining another successor.",
    EDIT_TEMPLATE_ACTIVITY_DESCRIPTION_PLACEHOLDER:
        "Enter additional notes, descriptions or translations which you or your teammates want to see.",
    INFO_PROGRESS_CHANGE_DISABLED_TOOLTIP:
        "You are not allowed to change the status of this activity because you are not responsible for it.",

    ACTION_EXPORT_GRAPHML_FILE: "Export as .graphml file",

    // TEMPLATE EDITOR
    _LABOUR: "Labour",
    _LABOUR_UNIT: "Workers",
    LABOUR_IN_UNIT: "{{ amount }} Workers",

    // NOTE TEMPLATE PAGE
    INFO_NOTE_TEMPLATE_NO_DATA_CARD_TITLE: "Create your first Note Template",
    INFO_NOTE_TEMPLATE_NO_DATA_CARD_TEXT:
        "Note Templates help saving time on site by allowing you to create standard texts which can then be used to create Notes more efficiently.",
    INFO_NO_DATA_DUE_TO_FILTER_CONDITIONS:
        "Your current filter and search criteria don't match any note template.",
    ACTION_NOTE_TEMPLATE_NO_DATA_BUTTON: "Create Your first Template",
    NOTE_TEMPLATE_TITLE: "Note type",
    CONFIG_CARD_TITLE: "Filters",
    INFO_PLACEHOLDER_SEARCH_BAR_NOTE_TEMPLATES_MESSAGE:
        "Search for note templates..",
    INFO_NOTE_TEMPLATE_REMOVE_MESSAGE:
        "{{success}} Note template{{(success == 1) ? '' : 's'}} deleted. {{errors > 0 ? errors + ' Error' + (errors == 1 ? '' : 's') : ''}}",
    CONFIRM_DELETE_NOTE_TEMPLATE_MESSAGE:
        "Are you sure you want to delete {{(value == 1) ? 'this' : 'these ' + value}} template{{(value == 1) ? '' : 's'}}?",
    INFO_NOTE_TEMPLATE_LOADING_REMOVE_MESSAGE: "Deleting note templates",
    SECTION_CREATE_NOTE_TEMPLATE_TITLE: "Create new note template",
    INPUT_CODE_TITLE: "Code",
    INPUT_NOTE_TEMPLATE_DESCRIPTION_TITLE: "Note Template Text",
    INPUT_SELECT_TYPE: "Select note type",

    //PROJECT CLONING
    INFO_ANY_PROJECT_MESSAGE: "You don't have any project, yet",
    INFO_SEARCH_NO_RESULT_PREFIX: "Your search of",
    INFO_SEARCH_NO_RESULT_PROJECT: "did not match any projects",
    SECTION_PROJECT_TO_BE_CLONED_TITLE: "Please select a project to be copied",
    SECTION_ENTER_PROJECT_DETAILS_TITLE: "Please enter project details",
    INFO_COPYING_PROJECT_MESSAGE: "Copying project...",
    INFO_PROJECT_CLONED_SUCCESSFULLY_MESSAGE: "Project cloned successfully",
    INPUT_SEARCH_FOR_A_PROJECT: "Search for a project",
    INFO_LOADING_MESSAGE: "Loading...",

    //PROJECT SELECTION
    INFO_PLACEHOLDER_SEARCH_BAR_PROJECT_SELECTION_MESSAGE:
        "Search for projects..",

    // sb-subbar
    INFO_ELEMENTS_SELECTED: "selected",

    DIALOG_CHANGE_DATES_TITLE: "Edit deliverable date",
    DIALOG_DATE_CHANGE_VALUE_NOT_DATE: "The entered value is not a date!",
    DIALOG_CHANGE_DATE_WARNING_MESSAGE:
        "The selected deliverables include different start and completion dates. Here shown are the earliest start and the latest completion date. Keep in mind that you will move all selected deliverables if you choose to save.",
    DIALOG_CHANGE_DATE_ALL_SELECTED_WARNING_MESSAGE:
        "Warning! All deliverables are currently selected. If you choose to change the dates now, this will affect all deliverables and overwrite existing start and completion dates.",

    // MAINTENANCE CARD
    INFO_MAINTENANCE_CARD_TITLE_PLANNED: "Planned Maintenance",
    INFO_MAINTENANCE_CARD_SUB_TITLE_PLANNED:
        "The system is currently not available due to scheduled updates and maintenance work.",
    INFO_MAINTENANCE_CARD_TEXT_STARTER_PLANNED:
        "Planned maintenance windows are Friday 10pm (CET) to Saturday 2am (CET), Saturday 10pm (CET) to Sunday 2am (CET) and Sunday 10pm (CET) to Monday 2am (CET).",
    INFO_MAINTENANCE_CARD_TEXT_PLANNED:
        "Please contact our support through <a href='mailto:support@sablono.com'>support@sablono.com</a> in case you have questions or need assistance.",

    INFO_MAINTENANCE_CARD_TEXT_UNKNOWN:
        "If the problem persists please contact us on <a href='mailto:support@sablono.com'>support@sablono.com</a>",
    INFO_MAINTENANCE_CARD_TITLE_UNKNOWN: "Network Timeout",
    INFO_MAINTENANCE_CARD_SUB_TITLE_UNKNOWN:
        "There seems to be no or slow connection to the Sablono server at the moment...",
    INFO_NOTE_TYPE_EDIT_TEMPORARILY_UNAVAILABLE:
        "Changing the type of Notes is currently not working any longer. We are working hard to bring this functionality back asap.",
    INFO_MAINTENANCE_CARD_TEXT_STARTER_UNKNOWN:
        "Please check if your internet connection is stable and try to refresh the page.",
    INFO_MAINTENANCE_CARD_BUTTON_UNKNOWN: "Support Center",

    // AUTO SIGN UP
    SIGNUP_TITLE_FREEMIUM: "Create your free Sablono Track account",
    SIGNUP_TITLE_FULL: "Create your Sablono account",
    SIGNUP_SUCCESS_TITLE:
        "Please verify your email using your inbox within 24h…",
    SIGNUP_SUCCESS_HUBSPOT_TITLE:
        "Do you want to make the most out of your Sablono experience? Meet one of our product experts!",
    SIGNUP_SUCCESS_HUBSPOT_TEXT:
        "We are more than happy to help you achieve your goals with our platform. Our dedicated team is here to help.",
    SIGNUP_SUCCESS_TEXT_WAIT_BUTTON: "click here.",
    SIGNUP_SUCCESS_TEXT_MAIL_SENT:
        "The email was sent a second time. If you don't receive an email in the next few minutes please contact our support through <a href='mailto:support@sablono.com'>support@sablono.com</a> or <a href='tel:0049030609807440'>+49 030 609807440</a>. We will gladly help you!",

    SIGNUP_GENERIC_ERROR_TITLE: "There seems to be a problem",
    SIGNUP_GENERIC_ERROR_TEXT:
        "We currently have an unexpected problem with the registration process. Please contact our support through <a href='mailto:support@sablono.com'>support@sablono.com</a> or <a href='tel:0049030609807440'>+49 030 609807440</a>. We will gladly help you!",

    SIGNUP_FIRST_ERROR_TITLE: "Ups...",
    SIGNUP_FIRST_ERROR_TEXT:
        "We are sorry but something went wrong while transferring your data to our system. Please try again.",
    SIGNUP_FIRST_ERROR_BUTTON: "Back to registration",

    SIGNUP_ERROR_EMAIL_ALREADY_USED_TITLE: "Already taken",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_TEXT:
        "Your email address was already used to create a user.",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_BUTTON: "Use a different email",

    SIGNUP_INFO_VERIFY_TITLE: "Choose a password",
    SIGNUP_INFO_VERIFY_TEXT:
        "Please choose a secure password before starting to work with Sablono.",
    SIGNUP_SUCCESS_VERIFY_TITLE: "Registration completed successfully",
    SIGNUP_SUCCESS_VERIFY_TEXT:
        "Congratulations! Make sure to check our Sablono step-by-step guide which can be found on <a target='_blank' href='http://support.sablono.com/english-tutorials'>support.sablono.com</a> whenever you have questions regarding the platform.",
    SIGNUP_SUCCESS_VERIFY_BUTTON: "Go to the support center",
    ACTION_SIGNUP_VERIFY_TEXT: "Activate",

    SIGNUP_ERROR_VERIFY_FAILED_TITLE: "Oops...",
    SIGNUP_ERROR_VERIFY_FAILED_TEXT:
        "Somehow the activation failed. Please try to use the provided link again.",

    SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TITLE: "Oops...",
    SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TEXT:
        "The link you are using seems to be invalid. This might have several reasons.",

    SIGNUP_EULA:
        "I have read and hereby accept the <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>Sablono End-User-License-Agreement</a>.*",
    SIGNUP_DATA_PRIVACY:
        "I agree that Sablono may also transfer the data I have entered to so-called third countries (Art. 49 para. 1 lit. a) GDPR). I am aware that in some third countries (e.g. the USA) access by control and / or monitoring authorities cannot be excluded. Possibly, neither the assertion of data subject rights nor recourse to the courts is open to me. The transmission is carried out for the purpose of contract fulfilment as well as for promotional contacting by Sablono. My consent is freely revocable.*<br><br>Please refer to the <a href='https://www.sablono.com/privacy' target='_blank'>Data and Privacy Policy</a> and note in particular our information on transmission to third countries.",
    SIGNUP_MARKETING_CONSENT:
        "I would like to receive information about Sablono products by e-mail and telephone in the future. My consent is freely revocable.*",

    //Visualisation page
    SECTION_VISUALIZATION_PAGE_TITLE: "Visual Trackers",
    DIALOG_NO_MATCH_SVG_TITLE: "No matching progress detected",
    DIALOG_NO_MATCH_SVG_CONTENT:
        "We were not able to detect any matching progress between your file and Sablono project. Please check if the Deliverable codes in your file match the codes on the platform and if Workflow Templates have been assigned to them.",
    INFO_PLACEHOLDER_SEARCH_BAR_VISUALIZATION: "Search for Visualisation..",

    DIALOG_BAD_PRINT_WARNING_TITLE: "Browser not supported",
    DIALOG_BAD_PRINT_WARNING_CONTENT:
        "Your browser does not support printing the visualisation to PDF. Please download and use Google Chrome to do so.",

    INFO_UPLOAD_VISUALIZATION_TEXT:
        "Upload an image of your project containing information about your deliverables (SVG file)",
    INFO_UPLOAD_NEW_VISUALIZATION_MESSAGE:
        "Upload a drawing containing the Codes used on Sablono for all deliverables displayed (SVG file only).",
    INFO_GENERATE_NEW_VISUALIZATION_MESSAGE:
        "Create a new schematic visualisation of your project.",
    ACTION_UPLOAD_NEW_VISUALIZATION: "Upload SVG drawing",
    ACTION_GENERATE_NEW_VISUALIZATION: "Create visualisation",

    SECTION_VISUALIZATION_SHOW_PROGRESS: "Show planned vs. actual",
    SECTION_VISUALIZATION_SHOW_STAGE: "Show status",
    SECTION_VISUALIZATION_SHOW_CURRENT_ACTIVITY: "Show teams",
    SECTION_VISUALIZATION_SHOW_DONE_ACTIVITY: "Browse status",
    SECTION_VISUALIZATION_PRINT_TOOLTIP:
        "Print visualisation or save as PDF using a PDF printer",
    SECTION_VISUALIZATION_PROGRESS: "Baseline comparison",
    SECTION_VISUALIZATION_STAGE: "Status",
    SECTION_VISUALIZATION_CURRENT_ACTIVITY: "Teams",
    "0_INFO_LEGEND_NO_STAGE": "No activity finished",
    "0_ACTIVITIES_PLANNED_LEGEND": "No activities planned",

    ERROR_SVG_DOES_NOT_EXIST:
        "The requested visualisation was not found on our server. Feel free to choose one from the list.",
    ERROR_FILE_IS_NOT_SVG:
        "The file you uploaded does not seem to be in the SVG format. Please choose another file.",
    CONFIRM_SVG_DELETION_TITLE: "Confirm deletion of visualisation",
    CONFIRM_SVG_DELETION_MESSAGE:
        "Are you sure that you want to delete this visualisation?",
    INFO_SVG_DELETION_SUCCESSFUL: "Visualisation deleted successfully",

    // Visualisation configuration section
    INFO_VISUALIZATION_CONFIG_NO_TEMPLATE_EMPTY_STATE_MESSAGE:
        "Seems like you did not create a Workflow Template in this project, yet.<br> Visualisations can only be created once a Workflow Template was set up.",

    SELECT_VISUALIZATION_CONFIG_DELIVERABLE_TYPE_MESSAGE:
        "Which kind of deliverables should be shown in the visualisation?",
    SELECT_VISUALIZATION_CONFIG_STRUCTURE_NODES_MESSAGE:
        "Which parts of your project structure should be displayed in the visualisation?",
    SELECT_VISUALIZATION_CONFIG_SVG_TITLE_MESSAGE:
        "Please define the titles displayed in your visualisation.",
    SELECT_VISUALIZATION_CONFIG_LABEL_ROW_PROPERTY_MESSAGE:
        "Which information should be used to label the levels displayed in the visualisation?",
    SELECT_VISUALIZATION_CONFIG_LABEL_CELL_PROPERTY_MESSAGE:
        "Which information should be used to label the deliverables displayed in the visualisation?",
    SELECT_VISUALIZATION_CONFIG_LABEL_PROPERTY_SUB_MESSAGE:
        "Which characters of that information should be displayed?",
    SELECT_VISUALIZATION_CONFIG_SVG_NAME_MESSAGE:
        "Please name the visualisation to be able to differentiate it from others.",

    INPUT_VISUALIZATION_CONFIG_DELIVERABLE_TYPE_DESCRIPTION:
        "Select a Workflow Template...",
    INPUT_VISUALIZATION_CONFIG_STRUCTURE_NODES_DESCRIPTION:
        "Select all relevant project structures levels...",
    INPUT_VISUALIZATION_CONFIG_HEADER_RIGHT_DESCRIPTION: "Title:",
    INPUT_VISUALIZATION_CONFIG_HEADER_LEFT_DESCRIPTION: "Project Structure:",
    INPUT_VISUALIZATION_CONFIG_LABEL_PROPERTY_DESCRIPTION:
        "Displayed information:",
    INPUT_VISUALIZATION_CONFIG_FIRST_CHAR_DESCRIPTION:
        "First character position",
    INPUT_VISUALIZATION_CONFIG_SECOND_CHAR_DESCRIPTION:
        "Last character position",
    INFO_VISUALIZATION_CONFIG_LABEL_PROPERTY_LIST: "Examples:",
    INPUT_VISUALIZATION_CONFIG_SVG_NAME_DESCRIPTION: "Define a name...",

    SECTION_VISU_CONFIG_DELIVERABLE_TYPE_STEPPER: "Select Workflow Template",
    SECTION_VISU_CONFIG_STRUCTURE_NODES_STEPPER: "Select project structure",
    SECTION_VISU_CONFIG_SVG_TITLE_STEPPER: "Define titles",
    SECTION_VISU_CONFIG_LABEL_PROPERTY_STEPPER: "Define labels",
    SECTION_VISU_CONFIG_SVG_NAME_STEPPER: "Name visualisation",

    // Visualisation generated SVG default headings
    SECTION_VISUALIZATION_GENERATED_SVG_HEADER_LEFT_TITLE: "WBS Levels",
    SECTION_VISUALIZATION_GENERATED_SVG_HEADER_RIGHT_TITLE: "Title",

    // Visualisation legend keys
    VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_APPLICABLE: "Activity not applicable",
    VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_STARTED: "Not started",
    VISUALIZATION_LEGEND_ENTRY_STAGE_AVAILABLE:
        "Available - All predecessors finished",
    VISUALIZATION_LEGEND_ENTRY_STAGE_STARTED: "Started",
    VISUALIZATION_LEGEND_ENTRY_STAGE_COMPLETED: "Finished & Confirmed",

    // Visualisation bulk update
    START_BULK_UPDATE: "Start progress update",
    UPDATE_PROGRESS: "Update progress for selection",
    BULK_UPDATE_MODE_TITLE: "Select deliverables to update an activity.",
    DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_TITLE:
        "Selected multiple Workflow Templates...",
    DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_MESSAGE:
        "Batch updating does only work for a selection of Deliverables of the Proces Template. Please only select Deliverables using the same Template.",

    // Process Viewer
    SECTION_PROCESS_VIEWER_TITLE: "Activities",
    SECTION_PROCESS_VIEWER_HIDE_ACTIVITIES_SWITCH_TEXT:
        "Hide finished activities",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_TITLE: "No deliverables existing",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_MESSAGE:
        "This project does not contain any deliverables, yet.",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_ACTION_1:
        "@:SECTION_DASHBOARD_NO_DATA_CARD_1_ACTION",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_ACTION_2:
        "@:SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION",
    SECTION_PROCESS_VIEWER_PAGE_NO_FILTERED_DATA:
        "@:SECTION_DELIVERABLES_PAGE_NO_FILTERED_DATA",
    SECTION_PROCESS_VIEWER_SELECT_ACTIVITIES_MESSAGE:
        "Select activities to create dependencies between them.",
    SECTION_PROCESS_VIEWER_SELECTED_ACTIVITIES_MESSAGE:
        "{{activitiesNum}} activities selected.",
    SECTION_PROCESS_VIEWER_PREDECESSOR_MESSAGE:
        "{{activitiesNum}}  activities selected. Please define their predecessor now…",
    SECTION_PROCESS_VIEWER_SUCCESSOR_MESSAGE:
        "{{activitiesNum}}  activities selected. Please define their successor now…",
    SECTION_PROCESS_PREVIOUS_PAGE: "Show previous activities",
    SECTION_PROCESS_NEXT_PAGE: "Show next activities",
    DIALOG_CREATE_DEPENDENCY_LOADING_TITLE: "Just a second...",
    DIALOG_CREATE_DEPENDENCY_LOADING_TEXT:
        "We are currently creating your dependencies. This may take a couple of seconds.",

    INFO_PROCESS_VIEWER_NOTE_CREATED: "Note created successfully",

    // Deliverables list page
    SECTION_DELIVERABLES_PAGE_TITLE: "Deliverables",
    SECTION_DELIVERABLES_PAGE_SUBTITLE: "Go back to Dashboard",
    SECTION_DELIVERABLES_PAGE_FILTERS: "Active filters: ",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_CODE_NAME: "Name & Code",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_STRUCT_DESC:
        "Description & Structure",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_DATES: "Start & End Date",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_STATUS: "Workflow & Status",
    SECTION_DELIVERABLES_PAGE_NO_FILTERED_DATA:
        "There are no deliverables matching your filter criteria.",
    INFO_DELIVERABLES_COUNT:
        "<b>{{filteredItems}} / {{totalItems}}</b> Deliverable{{(filteredItems ===0 || filteredItems > 1) ? 's' : ''}} in the list",
    INFO_LIST_UPDATED_MESSAGE: "List updated",
    INFO_LIST_SELECTION_REMOVED_MESSAGE: "Selection removed",
    SEARCHABLE_SELECT_SELECTION_REMOVED_MESSAGE: "Selection removed",
    SELECTED_ACTIVITIES_SECTION_PRINT_HEADER: "Selected activities",

    ACTION_ASSIGN_TEMPLATE_ERROR_MAX_REQUEST:
        "The number of selected deliverables exceeded the maximum allowed request.",
    ACTION_ASSIGN_TEMPLATE_LOADING: "Assigning template...",
    ACTION_ASSIGN_TEMPLATE_SUCCESS_N:
        "Workflow template assigned to {{changed}} of {{total}} deliverable{{(total ===0 || total > 1) ? 's' : ''}}.",

    ACTION_OPEN_BULK_PROGRESS_CHANGE_DIALOG: "Update progress",
    DIALOG_BULK_PROGRESS_UPDATE_TITLE: "Update progress",
    DIALOG_BULK_PROGRESS_UPDATE_HEADER:
        "Please, select all activities which you want to update:",
    INFO_BULK_PROGRESS_CHANGE_MESSAGE:
        "Saving will overwrite the existing status of all selected activities for all previously selected deliverables.",
    INFO_BULK_PROGRESS_CHANGE_SUCCESS:
        "{{items}} activities were set to '{{state}}'.",
    DIALOG_BULK_PROGRESS_UPDATE_ACTION_HEADER:
        "Now, select the status you want to report for those activities:",
    DIALOG_DELIVERABLE_TYPE_SELECTION_TITLE: "Activate Workflow filter",
    DIALOG_DELIVERABLE_TYPE_SELECTION_MESSAGE:
        "To be able to Configure Activities and Report Updates, please first use the filter tool to select which Workflow Template you want to edit. You will find it in the filters overlay which you can open on the right hand side.",

    ACTION_OPEN_BULK_UPDATE_OPTIONS_DIALOG: "Report Updates",
    DIALOG_BULK_UPDATE_OPTIONS_TITLE: "Update selected deliverables",
    DIALOG_BULK_UPDATE_OPTION_UPDATE_PROGRESS_TITLE:
        "Update progress for activities",
    DIALOG_BULK_UPDATE_OPTION_CREATE_NOTE_TITLE: "Attach Note to activities",

    DIALOG_BULK_CREATE_NOTE_TITLE: "Select activity",
    DIALOG_BULK_CREATE_NOTE_HEADER:
        "Please, select the activity you want to create notes for:",

    DIALOG_BULK_PDF_EXPORT_TITLE: "Export QA Checklist PDFs",
    DIALOG_BULK_PDF_EXPORT_TEXT:
        "Choose which activities you want to download the PDFs for. We will generate PDFs for these and send you an email.",
    DIALOG_BULK_PDF_EXPORT_BY_DATE_TEXT:
        "To export all QA Checklists signed on a specific day please select the date below. Afterwards we will generate PDFs for you and send you an email.",
    DIALOG_BULK_PDF_EXPORT_EMPTY_TITLE: "No QA Checklists to export",
    DIALOG_BULK_PDF_EXPORT_EMPTY_TEXT:
        "There are no filled QA Checklists saved on Sablono for your current selection. To be able to export PDFs please select Deliverables and Activities which include filled QA Checklists.",
    DIALOG_BULK_PDF_EXPORT_BY_DATE_EMPTY_TEXT:
        "There are no QA Checklists which were uploaded and signed on the selected date. To be able to export PDFs please select another day.",
    DIALOG_BULK_PDF_EXPORT_SUCCESS_TITLE: "Your PDFs are being generated...",
    DIALOG_BULK_PDF_EXPORT_SUCCESS_TEXT:
        "Once we created your {{numberOfReports}} PDFs we will email them to {{email}}. Please keep in mind that this might take some time depending on how many PDFs you requested (e.g. 1000 PDFs may take up to 60min).",

    ACTION_CONFIGURE_DELIVERABLES: "Configure deliverables",
    DIALOG_DELIVERABLE_SET_CONFIG_TITLE: "@:ACTION_CONFIGURE_DELIVERABLES",
    DIALOG__DELIVERABLE_SET__ASSIGN_TEMPLATE__OPTION_TITLE:
        "Assign workflow template",
    DIALOG__DELIVERABLE_SET__ASSIGN_STRUCTURE__OPTION_TITLE:
        "Move to Project Structure",
    DIALOG__DELIVERABLE_SET__CHANGE_DATES__OPTION_TITLE:
        "Edit deliverable dates",
    DIALOG__DELIVERABLE_SET__ASSIGN_DELIVERABLE_TYPE__OPTION_TITLE:
        "Assign Type",
    DIALOG__DELIVERABLE_SET__ASSIGN_RESPONSIBLE_MANAGER__OPTION_TITLE:
        "Assign Responsible Manager",
    DIALOG__DELIVERABLE_SET__DELETE__OPTION_TITLE: "Delete Deliverables",

    ACTION_CONFIGURE_ACTIVITIES: "Configure activities",
    DIALOG_ACTIVITY_SET_CONG_TITLE: "@:ACTION_CONFIGURE_ACTIVITIES",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_END_TITLE: "Specify End date",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_START_TITLE: "Specify Start date",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_TITLE:
        "Remove user-defined activity dates",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_SUBMIT:
        "Remove user-defined dates",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_TITLE:
        "Hide activities from workflow",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_TITLE:
        "Show hidden activities",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES:
        "Please select the activities that should be visible again for the previously selected deliverables:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY:
        "Please select the activities for which you want to define a custom Start date:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_END_DATE:
        "Please select the activities for which you want to define a custom End date:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_UNSET:
        "Please select the activities for which you want to remove all custom Start and End dates:",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_SELECT_ACTIVITIES:
        "Please select the activities which should be hidden from the workflow of your previously selected deliverables:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_DATE: "Select a date for ",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_HINT:
        "Please note, earliest possible start or completion dates will only be used as a constraint while calculating your project schedule if they do not contradict your defined workflows and dependencies.",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_SUBMIT: "Hide activities",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUBMIT:
        "Show hidden activities",
    DIALOG_ACTIVITY_UNSET_WHAT_TEAM:
        "For which team would you like to use the standard again?",

    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_TITLE:
        "Change duration of activities",
    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SELECT:
        "Please select those activities of the previously selected deliverables for which you want to change the duration:",
    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SUBMIT: "Change duration",
    ACTION_SET_DURATION_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} {{(activities === 0 || activities > 1) ? 'durations': 'duration'}} successfully changed.",

    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_TITLE:
        "Remove user-defined duration of activities",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SELECT:
        "Please select those activities of the previously selected deliverables for which you want remove the user-defined and reactivate the standard duration defined in the workflow template:",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SUBMIT: "Use standard duration",
    ACTION_UNSET_DURATION_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} {{(activities === 0 || activities > 1) ? 'durations': 'duration'}} successfully changed.",

    DIALOG_ASSIGN_AREA_MANAGER_TITLE: "Assign Responsible Manager",
    DIALOG_ASSIGN_AREA_MANAGER_TEXT:
        "Please type in the email of your teammate who you want to set as responsible manager. Please note that you first have to add them to this project.",
    DIALOG_ASSIGN_AREA_MANAGER_ACTION: "Assign",
    ACTION_ASSIGN_AREA_MANAGER_SUCCESS_N:
        "{{displayName}} was set as responsible manager for {{deliverables}} deliverable{{(deliverables === 1) ? '' : 's'}}.",

    ACTION_SEND_REQUEST_FOR_PROGRESS: "Send request",
    ACTION_OPEN_REQUEST_FOR_PROGRESS_DIALOG: "Request for update",
    DIALOG_REQUEST_FOR_UPDATE_TITLE: "Request for update",
    DIALOG_REQUEST_FOR_UPDATE_CONTENT_HEADER:
        "Please type in the email of your teammate from whom you want to receive information. Please note that you first have to add them to this project.",
    DIALOG_PICK_PROJECT_MEMBER_USERS_PLACEHOLDER:
        "Teammates last name, email address or company name...",
    DIALOG_PICK_PROJECT_MEMBER_USER_NOT_FOUND:
        "Seems like you are trying to select a user not added to this project. Please add them first.",
    DIALOG_REQUEST_FOR_UPDATE_WARNING:
        "It seems like you have selected a very huge number of deliverables. We would recommend to send smaller requests for information since your teammate might be overwhelmed by the amount of information required.",
    TOAST_REQUEST_FOR_PROGRESS_SUCCESS:
        "Request for update for {{deliverables}} deliverable{{(deliverables ===0 || deliverables > 1) ? 's' : ''}} sent to {{username}}",

    ACTION_OPEN_ASSIGN_TYPE_DIALOG: "Assign Deliverable Type",
    ACTION_ASSIGN_TYPE_SUCCESS_N: "Updated {{deliverabes}} deliverables",
    DIALOG_ASSIGN_TYPE_TITLE: "Assign Deliverable Type",
    DIALOG_ASSIGN_TYPE_TEXT:
        "Please create or select a Type that you want to assign to the previously selected Deliverables:",
    DIALOG_ASSIGN_TYPE_ACTION: "Assign",
    DIALOG_PICK_TYPE_TO_ASSIGN_PLACEHOLDER: "Create or select a Type...",
    DIALOG_PICK_TYPE_TO_ASSIGN_NOT_FOUND:
        "This Type does not exist in this project, yet...",

    //Details Overlay
    _TIMEZONE: "Timezone",
    _DELAY: "Forecasted delivery",
    _DELAYED_BY_X_DAYS:
        "{{days}} day{{(days ===0 || days > 1) ? 's' : ''}} late",
    _SOURCE: "Source",
    _CREATED_WITH_EXCEL: "Imported from Excel",
    _CREATED_WITH_PRIMAVERA: "Imported from Primavera",
    _CREATED_WITH_MSP: "Imported from Microsoft Project",
    _CREATED_WITH_ASTA: "Imported from Asta Powerproject",
    _CREATED_WITH_CLONE: "Cloned from another project",
    _CREATED_WITH_USER: "Created with Sablono",
    _SHOW_ALL: "Show all activities",
    _SHOW_ALL_CHECKLISTS: "Show all checklists",

    INFO_BEHIND_SCHEDULE_EXPLANATION_MESSAGE:
        "At least one activity behind schedule",

    OPEN_ACTIVITY_UPDATE_TIMELINE: "Open Updates Timeline for this Activity",

    SECTION_DEPENDENCIES_TITLE: "Dependencies",
    INFO_NO_CHECKLIST_ITEMS:
        "It seems like this QA Checklist was submitted without any items that were checked.",
    INFO_DELIVERABLES_DEPENDENCIES_SCHEDULE_MODE_TEXT:
        'In "individual scheduling"-mode dependencies will not influence your project schedule. You can change to "connected scheduling" on the Project Settings page.',
    INFO_DELIVERABLE_PREDECESSOR_ADD_TEXT:
        "Predecessors have to be finished before this deliverable can be started.",
    INFO_DELIVERABLE_SUCCESSOR_ADD_TEXT:
        "Successors can only be started after this deliverable was finished.",
    INFO_DETAILS_OVERLAY_FILTER_TEXT: "Filter by Team",
    INFO_EMPTY_DETAILS_OVERLAY_STATE_TITLE: "No activities assigned to team",
    INFO_EMPTY_DETAILS_OVERLAY_STATE_MESSAGE:
        "It seems like the team was not assigned responsible for any activities of this sequence.",
    TOAST_DELETE_CUSTOM_DATES: "User-defined date removed",
    SELECT_PREDECESSOR_DEPENDENCY_TITLE: "Select predecessors from the list",
    SELECT_SUCCESSOR_DEPENDENCY_TITLE: "Select successors from the list",
    ACTION_ADD_SUCCESSOR: "Add Successor",
    ACTION_ADD_PREDECESSOR: "Add Predecessor",
    ACTION_CLOSE_DEPENDENCY_MANAGER: "Finish editing",
    SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_PRED:
        "Search for deliverables by name, code or description and define predecessors...",
    SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_SUCC:
        "Search for deliverables by name, code or description and define successors...",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_DIALOG_TITLE: "Delete dependency",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_MULTIPLE_DIALOG_TEXT:
        "Do you really want to delete this dependency? Since it was automatically transformed from a dependency of two deliverables, all dependencies associated to it ({{value}}) will be deleted as well.",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_SINGLE_DIALOG_TEXT:
        "Do you really want to delete this dependency?",

    INFO_ACTIVITY_DEPENDENCY_NOT_FULFILLED:
        "<b>{{activitySource}}</b>{{ deliverableSource ? ' of <b>' + deliverableSource + '</b>' : ''}} needs to be finished before <b>{{activityTarget}}</b>{{ deliverableTarget ? ' of <b>' + deliverableTarget + '</b>' : ''}} can be started.",
    INFO_ACTIVITY_DEPENDENCY_COMPLETED:
        "<b>{{activitySource}}</b>{{ deliverableSource ? ' of <b>' + deliverableSource + '</b>' : ''}} was finished, therefore <b>{{activityTarget}}</b>{{ deliverableTarget ? ' of <b>' + deliverableTarget + '</b>' : ''}} can be started.",

    ERROR_EDGE_WOULD_INTRODUCE_CYCLE:
        "The requested dependency would result in a cycle.", // DB error code

    // Structure  and dates dialog

    DIALOG_ASSIGN_STRUCTURE_TITLE:
        "Move deliverables to project structure part",
    DIALOG_ASSIGN_STRUCTURE_SELECTED_STRUCTURE_TEXT:
        "The selected deliverables will be moved to the following structure part:",
    ACTION_ASSIGN_STRUCTURE: "Move deliverables to project structure part",
    ACTION_CHANGE_DATES_SUCCESS_N:
        "The dates of {{deliverables}} deliverable{{(deliverables ===0 || deliverables > 1) ? 's' : ''}} have been changed.",
    DIALOG_CHANGE_DATES_RANGE:
        "Keep duration of {{days}} day{{(days ===0 || days > 1) ? 's' : ''}}.",
    DIALOG_CHANGE_DATES_LOADING_TEXT: "Changing dates...",
    DIALOG_CHANGE_DATES_DERIVE_FROM_SCHEDULE_HINT_TEXT: "Delete date",
    DIALOG_ASSIGN_STRUCTURE_LOADING_TEXT: "Moving deliverables...",
    ACTION_ASSIGN_STRUCTURE_SUCCESS_N:
        "{{deliverables}} deliverable{{(deliverables ===0 || deliverables > 1) ? 's have' : ' has'}} been moved.",
    DIALOG_CHANGE_DATES_OPTION_BOTH_TITLE:
        "Change both Start and Completion dates",
    DIALOG_CHANGE_DATES_OPTION_START_TITLE: "Change Start dates",
    DIALOG_CHANGE_DATES_OPTION_END_TITLE: "Change Completion dates",
    INFO_DATE_CHANGE_CONFLICT_STRATEGY_MESSAGE:
        "Date conflicts (completion before start date) may occur. These conflicts will be solved by un-setting the date if you decide to continue.",
    ACTION_SET_ACTIVITY_DUE_DATES_SUCCESS_N:
        "The completion dates of {{activities}}/{{totalActivitiesForBulkOperation}} {{(activities === 0 || activities > 1) ? 'activities have' : 'activity has'}} been changed.",
    ACTION_SET_ACTIVITY_START_DATES_SUCCESS_N:
        "The start dates of {{activities}}/{{totalActivitiesForBulkOperation}} {{(activities === 0 || activities > 1) ? 'activities have' : 'activity has'}} been changed.",
    ACTION_DELETE_BULK_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} activit{{(activities === 0 || activities > 1) ? 'ies' : 'y'}} successfully hidden.",
    ACTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} activit{{(activities === 0 || activities > 1) ? 'ies' : 'y'}} visible again",
    ACTION_UNSET_ACTIVITY_DATES_SUCCESS_N:
        "The user-defined dates of {{activities}}/{{totalActivitiesForBulkOperation}} {{(activities === 0 || activities > 1) ? 'activities have' : 'activity has'}} been removed.",

    // Delete deliverables
    DIALOG_DELETE_DELIVERABLES_TITLE: "Delete deliverables",
    DIALOG_DELETE_DELIVERABLES_TEXT_N:
        "Do you really want to delete {{items}} deliverable{{(items === 0 || items > 1) ? 's' : ''}}?",
    DIALOG_DELETE_DELIVERABLES_OK: "Yes, delete them",
    TOAST_DELETE_DELIVERABLES_SUCCESS:
        "{{items}} deliverable{{(items === 0 || items > 1) ? 's' : ''}} successfully deleted.",
    DIALOG_DELETE_DELIVERABLES_LOADING_TEXT: "Deleting deliverables...",

    // notes
    INFO_ALL_ISSUES_RESOLVED: "All issues have been resolved.",
    INFO_NO_NOTES_REPORTED_TITLE: "No notes reported.",

    INFO_ISSUES_RESOLVED:
        "{{closed || 0}}/{{total}} issue{{closed === 1 ? '' : 's'}} resolved",

    ACTION_PRINT_DELIVERABLES_REPORT: "Print Deliverables Report",
    ACTION_PRINT_ACTIVITIES_REPORT: "Print Activities Report ",
    ACTION_PRINT_STRUCTURE_REPORT: "Print Project Structure Report",
    ACTION_PRINT_REPORT: "Print Report",
    ACTION_PRINT_REPORTS: "Print Reports",
    ACTION_EXPORT_DATA: "Export Data",
    ACTION_SHOW_PROCESS_VIEWER: "Show Activities",
    ACTION_SHOW_DELIVERABLES_LIST: "Show Deliverables",
    ACTION_ASSIGN_AS_PREDECESSOR: "Define predecessor of selection",
    ACTION_ASSIGN_AS_SUCCESSOR: "Define successor of selection",

    INFO_DELIVERABLES_REPORT_TITLE: "Progress Report Deliverables",
    INFO_DELIVERABLES_REPORT_TEXT:
        "This report contains progress information for those deliverables that are currently displayed in the list.",
    INFO_ACTIVITIES_REPORT_TITLE: "Progress Report Activities",
    INFO_ACTIVITIES_REPORT_TEXT:
        "This detailed report contains progress information for the currently displayed deliverables as well as activities assigned to them.",
    INFO_STRUCTURE_REPORT_TEXT:
        "This report contains progress information for the whole project structured by the defined Project Structure.",
    INFO_DELIVERABLES_AND_ACTIVITIES_REPORT_TITLE: "Export project data (CSV)",
    INFO_QR_CODES_TITLE: "QR Codes",
    INFO_QR_CODES_TEXT:
        "QR Codes can be used to identify Deliverables on the construction site and find the related Actitivities faster in the mobile app.",
    INFO_QA_CHECKLIST_PDFS_TITLE: "QA Checklist PDFs",
    INFO_QA_CHECKLIST_PDFS_TEXT:
        "In the following step you will be able to select the Activities for which you want to export your QA Checklists as PDFs.",
    INFO_QA_CHECKLIST_PDFS_BY_DATE_TITLE: "QA Checklist PDFs",
    INFO_QA_CHECKLIST_PDFS_BY_DATE_TEXT:
        "This export contains all PDFs of QA Checklists which have been signed on a selected day. In the next step you will be able to select for which date you want to run the export.",

    SECTION_CHOOSE_REPORT_TYPE: "Select a report",

    INFO_NO_FILTERED_DATA: "There is no data matching your filter criteria.",

    // LeanBoard
    SECTION_LEANBOARD_TITLE: "Lean Planning",
    SECTION_LEANBOARD_EMPTY_MESSAGE:
        "There are no activities scheduled during the currently shown days.",
    ACTION_LEANBOARD_GO_TO_PREV_WEEK: "Open previous weeks",
    ACTION_LEANBOARD_GO_TO_NEXT_WEEK: "Open next weeks",
    ACTION_LEANBOARD_OPEN_OVERLAY: "View details",
    ACTION_LEANBOARD_FORECAST_DELIVERABLE_DATES: "Forecast all",
    ACTION_LEANBOARD_SHOW_AGGREGATED_DELIVERABLES: "Show included Deliverables",
    ACTION_LEANBOARD_ACCEPT_ALL: "Accept all",
    ACTION_LEANBOARD_OPEN_LATE_ACTIVITIES_PANEL:
        "{{lateActivities}} late activities",
    DIALOG_LATE_ACTIVITIES_TITLE: "Late activities for {{deliverableName}}",
    DIALOG_LATE_ACTIVITIES_MESSAGE:
        "Click on activities to define new Look Ahead dates.",
    ACTION_LEANBOARD_MOVE_TO_THIS_WEEK: "Move to current week",
    INFO_LEANBOARD_UPDATING_SCHEDULE: "Loading...",
    ONE_WEEK: "1 week",
    TWO_WEEKS: "2 weeks",
    THREE_WEEKS: "3 weeks",
    FOUR_WEEKS: "4 weeks",
    EIGHT_WEEKS: "8 weeks",
    TWELVE_WEEKS: "12 weeks",
    COLUMN_TIMEFRAME_DAYS: "Days",
    COLUMN_TIMEFRAME_WEEKS: "Weeks",

    DIALOG_LEAVE_LEANBOARD_WITH_OPEN_SESSION_HEADER:
        "Planning Session running...",
    DIALOG_LEAVE_LEANBOARD_WITH_OPEN_SESSION_MESSAGE:
        "You are currently running a Look Ahead Planning Session. Do you want to leave the page without saving or discarding your changes? Please note, that other users won’t be able to modify the project schedule as long as your Session is still in progress.",
    ACTION_STAY_IN_LEANBOARD_SESSION: "Stay",
    ACTION_DISCARD_LEANBOARD_SESSION_AND_LEAVE: "Leave & discard",
    ACTION_RELEASE_LEANBOARD_SESSION_AND_LEAVE: "Leave & publish",
    ACTION_KEEP_LEANBOARD_SESSION_OPEN_AND_LEAVE:
        "Leave & keep session running",

    INFO_LEANBOARD_DELIVERABLE_SELECTED:
        "An activity of this deliverable has been selected.",

    // Request for update
    SECTION_REQUEST_TEXT:
        "Update deliverables or search for others if you can't find the ones you worked on.",
    SECTION_REQUEST_SUCCESS_TITLE: "Update successful",
    SECTION_REQUEST_SUCCESS_TEXT:
        "Your update has been saved. Thank you for collaborating!",
    SECTION_REQUEST_UPLOAD_BUTTON: "Save progress",
    SECTION_REQUEST_INFORMATION_OPT_OUT_BUTTON: "Search for other deliverables",
    SECTION_REQUEST_ERROR_PERMISSION_DENIED_TITLE: "Access denied",
    SECTION_REQUEST_ERROR_PERMISSION_DENIED_TEXT:
        "It seems like the link you clicked was supposed to be used by another teammate. Please make sure that the email was meant for you and double check with its sender.",
    SECTION_REQUEST_ERROR_CLOSED_TITLE: "Link expired",
    SECTION_REQUEST_ERROR_CLOSED_TEXT:
        "The link you clicked has already been used to send a progress report to the platform. Please make sure that you used the right email.",
    SECTION_REQUEST_ERROR_NOT_EXIST_TITLE: "No open request",
    SECTION_REQUEST_ERROR_NOT_EXIST_TEXT:
        "Oops... It seems like there is no open request for update related to the link you used. Please make sure that you clicked a valid link.",
    ACTION_SELECT_PROJECT: "Select a project",

    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_PLACEHOLDER: "Search ...",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_TITLE:
        "Search for deliverables",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_MESSAGE:
        "Search for deliverables by name, code or description.",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_START_SEARCH_ACTION:
        "Start search",
    SECTION_DELIVERABLES_PROGRESS_LIST_EMPTY_SEARCH_RESULTS_TITLE:
        "No deliverables found",
    SECTION_DELIVERABLES_PROGRESS_LIST_EMPTY_SEARCH_RESULTS_MESSAGE:
        "No matches found for <b>{{searchTerm}}</b>",
    SECTION_DELIVERABLES_PROGRESS_LIST_PREDECESSOR_DONE_BADGE:
        "Predecessor finished",
    SECTION_DELIVERABLES_PROGRESS_LIST_SCHEDULED_BADGE: "Work scheduled",

    // Reset password
    SECTION_RESET_PASSWORD_LINK: "Forgot your password?",
    SECTION_RESET_PASSWORD_TEXT:
        "Please type your email address. We will send you an email including further guidance.",
    SECTION_RESET_PASSWORD_BUTTON: "Request a new password",
    SECTION_RESET_PASSWORD_SUCCESS_TITLE: "Just a few steps left...",
    SECTION_RESET_PASSWORD_SUCCESS_TEXT:
        'Please check your inbox for further instructions. Make sure to also have a look at your spam folder if you can not find our email. If you can not find it please contact our customer support: <a href="mailto:support@sablono.com">support@sablono.com</a>',
    SECTION_RESET_PASSWORD_ERROR_TITLE: "Oops...",
    SECTION_RESET_PASSWORD_ERROR_TEXT:
        'Seems like something went wrong. Please try again shortly. If this happens again, feel free to contact our customer support: <a href="mailto:support@sablono.com">support@sablono.com</a>',
    SECTION_RESET_PASSWORD_ERROR_LIMIT_ATTEMPT_EXCEEDED_TITLE:
        "Attempt limit exceeded",
    SECTION_RESET_PASSWORD_ERROR_LIMIT_ATTEMPT_EXCEEDED_TEXT:
        "Attempt limit exceeded, please try after some time.",
    SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TITLE: "User does not exist",
    SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TEXT:
        "It seems like this user is not registered for Sablono. Please make sure that you did not misspell.",
    SECTION_RESET_PASSWORD_MAIL_NOT_DELIVERED_TITLE: "Oops...",
    SECTION_RESET_PASSWORD_MAIL_NOT_DELIVERED_TEXT:
        'It seems like we could not deliver the email containing the temporary password to the address you used. Please contact our costumer support: <a href="mailto:support@sablono.com">support@sablono.com</a> We will reach out to you as soon as possible.',

    // Issues page
    SECTION_ISSUES_PAGE_TITLE: "Notes",
    INFO_ISSUES_COUNT:
        "<b>{{filteredItems}} / {{totalItems}}</b> Note{{(filteredItems ===0 || filteredItems > 1) ? 's' : ''}} in the list",
    INPUT_ISSUE_TEXT_SEARCH_TITLE: "Note text",
    INPUT_ISSUE_TEXT_SEARCH_PLACEHOLDER: "Filter by note text...",
    INPUT_ISSUE_KEY_SEARCH_LABEL: "Note code",
    INPUT_ISSUE_KEY_PLACEHOLDER: "Filter by note code...",
    INPUT_ISSUE_TYPE_LABEL: "Note type",
    INPUT_ISSUE_STATUS_LABEL: "Status",
    INPUT_ISSUE_TEMPLATE_KEY_LABEL: "Note-Template code",
    INPUT_ISSUE_TEMPLATE_KEY_PLACEHOLDER: "Filter by note-template code...",
    INPUT_ISSUE_AUTHOR_SEARCH_LABEL: "Reporter",
    INPUT_ISSUE_AUTHOR_PLACEHOLDER:
        "Filter by name of user reporting the note...",
    INPUT_ISSUE_DELIVERABLE_SEARCH_LABEL: "Deliverable Search",
    INPUT_ISSUE_DELIVERABLE_PROCESS_SEARCH_LABEL: "Deliverable Workflow",
    INPUT_ISSUE_DELIVERABLE_SEARCH_PLACEHOLDER:
        "Search for notes by deliverable name, code or description...",
    INPUT_DELIVERABLE_NAME_LABEL: "Deliverable name",
    INPUT_DELIVERABLE_NAME_PLACEHOLDER: "Filter by deliverable name...",
    INPUT_DELIVERABLE_CODE_LABEL: "Deliverable code",
    INPUT_DELIVERABLE_CODE_PLACEHOLDER: "Filter by deliverable code...",
    INPUT_DELIVERABLE_DESC_LABEL: "Deliverable description",
    INPUT_DELIVERABLE_DESC_PLACEHOLDER: "Filter by deliverable description...",
    INPUT_ACTIVITY_SEARCH_LABEL: "Activity Search",
    INPUT_ACTIVITY_SEARCH_PLACEHOLDER:
        "Search for notes by activity name or description...",
    INPUT_ACTIVITY_NAME_LABEL: "Activity name",
    INPUT_ACTIVITY_NAME_PLACEHOLDER: "Filter by activity name...",
    INPUT_ACTIVITY_DESC_LABEL: "Activity description",
    INPUT_ACTIVITY_DESC_PLACEHOLDER: "Filter by activity description...",

    INPUT_ISSUE_ISSUE_RESPONSIBLE_TEAM_PLACEHOLDER:
        "Select a Responsible Team...",

    INPUT_ISSUE_ISSUE_CONFIRMATION_TEAM_PLACEHOLDER:
        "Select a Reviewing Team...",

    INPUT_ISSUE_GEOLOCATION_INFO:
        "Your project admin asked you to upload photos incl. geo-location. Please make sure that you do.",

    //create new issue
    DIALOG_ISSUE_EDITOR_TITLE: "Create a new note",
    DIALOG_EDIT_ISSUE_TITLE: "Edit note",
    DIALOG_EDIT_ISSUE_TOOLTIP: "@:ACTION_TEMPLATE_ADD_CONTENT",
    DIALOG_EDIT_ISSUE_SUCCESS: "@:INFO_PROJECT_EDIT_SUCCESS_TITLE",
    DIALOG_ISSUE_EDITOR_TEAM_SELECTOR_LABEL: "Responsible Team",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_ERROR_REQUIRED:
        "Please select a Reviewing Team before you can continue",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_LABEL: "Reviewing Team",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_NONE: "No separate review requested",
    INPUT_ISSUE_DESCRIPTION_TITLE: "Enter a text describing the new note",
    ACTION_ADD_ATTACHMENT_TO_NOTE: "Add attachment",

    //close issue
    SECTION_ISSUE_CONFIRM_CLOSE_ISSUE_TITLE: "Report issue status",
    SECTION_ISSUE_CONFIRM_CLOSE_ISSUE_TEXT:
        "Do you really want to close this issue? This cannot be undone.",
    ACTION_CLOSE_ISSUE: "Closed",
    ACTION_OPEN_ISSUE: "Open",
    ACTION_FINISH_ISSUE: "In review",
    ACTION_CONFIRM_ISSUE: "Closed",
    ACTION_REJECT_ISSUE: "Re-opened",

    //print issue report dialog
    DIALOG_PRINT_ISSUE_REPORT_TITLE: "Select a report",
    SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TITLE:
        "PDF Notes report incl. photos",
    SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TEXT:
        "This report will contain the {{filteredIssueCount}} note{{(filteredIssueCount === 0 || filteredIssueCount > 1)  ? 's' : ''}} which are currently displayed in the list.",
    SECTION_PRINT_CSV_ISSUE_REPORT_TITLE: "CSV table",
    SECTION_PRINT_CSV_ISSUE_REPORT_TEXT:
        "This table will contain all {{totalIssueCount}} existing note{{(totalIssueCount === 0 || totalIssueCount > 1)  ? 's' : ''}} and can be opened in Microsoft Excel.",

    //user defined dates
    INFO_DELETING_ALL_USER_DEFINED_DATES_MESSAGE: "Deleting user-defined dates",
    INFO_USER_DEFINED_DATE: "User-defined date",
    USER_DEFINED_DATE_CONFLICT: "User-defined and calculated dates conflict",
    INFO_USER_DEFINED_DATE_EXPLAINED:
        "This user-defined date contradicts the defined workflow and dependencies. Therefore, you will see it here but it does not influence the calculated schedule.",
    //Automatically calculated durations
    INFO_EQUAL_DISTRIBUTED_DURATIONS:
        "Calculated duration – This activity duration has been calculated based on the deliverable duration. You may define activity durations using the template editor.",
    //Scheduling on demand - You project is in dirty mode
    INFO_SCHEDULING_ON_DEMAND_DIRTY_MODE_TEXT:
        "You applied changes to the schedule of this project (e.g. deleted deliverables or created new dependencies) which have not been incorporated into the programme, yet.",
    ACTION_RECALCULATE_SCHEDULE: "Recalculate the schedule",

    //////
    // Start of Commercial page
    //
    SECTION_COMMERCIAL_PAGE_TITLE: "Commercial Dashboard",
    SECTION_COMMERCIAL_PRO_PAGE_TITLE: "Commercial Pro",
    ACTION_NAV_TO_COMMERCIAL_PRO: "Commercial Pro",

    ACTION_NAV_TO_DASHBOARD_PRO: "Dashboards Pro",
    SECTION_DASHBOARD_PRO: "Dashboards Pro",

    ACTION_NAV_TO_COMMERCIAL: "Commercial Dashboard",
    SECTION_COMMERCIALS_PAGE_LOADING_DATA_MESSAGE: "Loading commercial data",
    SECTION_COMMERCIALS_PAGE_NO_DATA_TITLE: "No Cost Group available",
    SECTION_COMMERCIALS_PAGE_NO_DATA_MESSAGE:
        "Cost Groups will help you assess and evaluate the progress of your project. Currently there is no Cost Group available for you to review which could either mean none were created or you do not have sufficient permissions to review them, yet.",

    // subbar actions
    ACTION_SETUP_COST_GROUPS: "Create cost group",

    // create cost group dialog
    DIALOG_CREATE_COST_GROUP_TITLE: "Create new cost group",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_TYPE:
        "First, please select the workflow template which contains the activities that you want to assign to the new cost group:",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_ACTIVITIES:
        "Please select all activities which are part of the new cost group:",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_NAME:
        "What is the name of the cost group?",
    DIALOG_CREATE_COST_GROUP_NEW_NAME_PLACEHOLDER: "Name",

    // cost group
    ACTION_DELETE_COST_GROUP: "Delete cost group",
    COST_GROUP_SAVED: "Cost group saved",

    // cost group details - statistics
    INFO_COST_GROUP_STAGES_TITLE: "Contract sum breakdown",
    INFO_COST_GROUP_STAGE_WEIGHT: "% of contract sum",
    INFO_COST_GROUP_STAGE_BUDGET_ALLOCATED: "Contract sum allocated",
    INFO_COST_GROUP_STAGE_BUDGET_STARTED: "In progress",
    INFO_COST_GROUP_STAGE_BUDGET_WAITING_FOR_CONFIRMATION: "Awaiting sign-off",
    INFO_COST_GROUP_STAGE_BUDGET_COMPLETED: "Signed off",
    INFO_COST_GROUP_STAGE_PERCENTAGE_OF_COST_GROUP_SPENT: "Cost completed",

    // cost group details - configuration error
    ERROR_COST_GROUP_CONFIG_TOTAL_WEIGHT_TITLE:
        "Please allocate 100,00% of your budget.",
    ERROR_COST_GROUP_CONFIG_TOTAL_WEIGHT_MESSAGE: "Currently allocated:",
    ERROR_COST_GROUP_CONFIG_REMAINING_WEIGHT_MESSAGE: "Deviation:",

    // cost group header
    INPUT_COST_GROUP_ENTER_BUDGET_TITLE: "Enter contract sum",
    COST_GROUP_BUDGET_ALLOCATED: "Contract sum allocated",
    COST_GROUP_BUDGET_STARTED: "Overall in progress",
    COST_GROUP_BUDGET_WAITING_FOR_CONFIRMATION: "Overall awaiting sign-off",
    COST_GROUP_BUDGET_COMPLETED: "Overall signed off",
    COST_GROUP_PERCENT_COST_COMPLETED: "Overall cost completed",

    ERROR_COST_GROUP_WEIGHT_INPUT_PATTERN_MESSAGE: "Not a number",
    ERROR_COST_GROUP_WEIGHT_BUDGET_MIN_MESSAGE:
        "0 is the minimum budget supported",

    COST_GROUP_NUMBER_OF_COMMERCIAL_MANAGERS:
        "Commercial Managers: {{numberOf}}",
    COST_GROUP_NUMBER_OF_COMMERCIAL_REVIEWERS:
        "Commercial Reviewers: {{numberOf}}",

    COST_GROUP_ADD_MEMBER_DIALOG_HEADER: "Add or remove users",
    COST_GROUP_ADD_MEMBER_DIALOG_TEXT_MANAGER:
        "<b>Commercial Managers</b> can change and review commercial values for this team as well as manage access.",
    COST_GROUP_ADD_MEMBER_DIALOG_TEXT_REVIEWER:
        "<b>Commercial Reviewers</b> can review commercial values for this team but not edit anything.",
    COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_HEADER: "Are you sure?",
    COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_TEXT:
        "Once you removed yourself you will not be able to see or edit commercial values for this team any longer. Do you want to continue?",

    COMMERCIAL_PAGE_SECTION_COMBINED_STAGES:
        "Activities from multiple Workflow Templates",
    ACTION_COMBINE_COST_GROUP: "Combine similar Activities",
    DIALOG_COMBINE_COST_GROUP_TITLE: "Combine similar Activities",
    DIALOG_COMBINE_COST_GROUP_STAGE_SELECT_ACTIVITIES_DESCRIPTION:
        "Please select all Activities that you want to combine into a single row of the Cost Group:",
    DIALOG_COMBINE_COST_GROUP_STAGE_ENTER_NAME_DESCRIPTION:
        "Please specify the name of the combined row including the previously selected Activities:",
    DIALOG_CONFIRM_COMBINE: "Combine Activities",

    COST_GROUP_STAGE_SELECTOR_SEARCHBAR_PLACEHOLDER:
        "Search for Activities by Workflow Template and Name...",
    DIALOG_COMBINE_COST_GROUP_STAGE_ENTER_NAME_PLACEHOLDER: "Name",

    //
    // End of Commercial  page

    //////
    // Start of CSV progress export headers
    //
    DELIVERABLE_CODE: "Deliverable-Code",
    DELIVERABLE_NAME: "Deliverable-Name",
    DELIVERABLE_DESC: "Deliverable-Description",
    PROJECT_STRUCTURE: "Project Structure",
    PLANNED_DELIVERABLE_START_DATE: "User-defined start (Deliverable)",
    PLANNED_DELIVERABLE_END_DATE: "User-defined end (Deliverable)",
    EARLIEST_DELIVERABLE_START_DATE: "Earliest start (Deliverable)",
    EARLIEST_DELIVERABLE_END_DATE: "Earliest end (Deliverable)",
    LATEST_DELIVERABLE_START_DATE: "Latest start (Deliverable)",
    LATEST_DELIVERABLE_END_DATE: "Latest end (Deliverable)",
    DELIVERABLE_SCHEDULE: "Deliverable-Schedule",
    DELIVERABLE_PROGRESS: "Deliverable-Progress",

    ACTIVITY_ID: "Activity ID",
    ACTIVITY_NAME: "Activity-Name",
    ACTIVITY_DESCRIPTION: "Activity-Description",
    ACTIVITY_PLANNED_DURATION: "Planned Duration",
    ACTIVITY_ASSIGNED_TEAM_NAME: "Execution team",
    PLANNED_ACTIVITY_START_DATE: "User-defined start (Activity)",
    PLANNED_ACTIVITY_END_DATE: "User-defined end (Activity)",
    EARLIEST_ACTIVITY_START_DATE: "Earliest start (Activity)",
    EARLIEST_ACTIVITY_END_DATE: "Earliest end (Activity)",
    LATEST_ACTIVITY_START_DATE: "Latest start (Activity)",
    LATEST_ACTIVITY_END_DATE: "Latest end (Activity)",
    ACTIVITY_ACTUAL_START_DATE: "Activity-Start Date (actual)",
    ACTIVITY_ACTUAL_END_DATE: "Activity-Finish Date (actual)",
    ACTUAL_START_REPORTED_BY: "Start reported by",
    ACTUAL_END_REPORTED_BY: "Finish reported by",
    ACTIVITY_OPEN_CLAIM_QUANTITY: "Open Quality Issues",
    ACTIVITY_OPEN_OBSTRUCTION_QUANTITY: "Open Obstructions",
    ACTIVITY_CURRENT_PROGRESS: "Current status",
    ACTIVITY_SCHEDULE: "Activity-Schedule",
    ACTIVITY_REQUIRED_CHECKLIST: "QA checklist attached",
    ACTIVITY_LAST_PLANNED_START: "Look Ahead start (Activity)",
    ACTIVITY_LAST_PLANNED_END: "Look Ahead end (Activity)",
    ACTIVITY_LAST_PLANNED_DURATION: "Look Ahead duration (Work days)",
    ACTIVITY_CONFIRMATION_TIME: "Activity-Confirmation Date (actual)",
    ACTIVITY_CONFIRMATION_REPORTER: "Confirmed by",
    ACTIVITY_STATE: "Status",
    //
    // End of CSV progress export headers

    //////
    // Start of checklist template abstract page
    //
    CHECKLIST_TEMPLATE_PAGE_NAME: "QA Checklists",
    CHECKLIST_TEMPLATE_ADD_NEW: "Create new QA checklist",
    //
    // End of checklist template abstract page

    //////
    // Start of checklist template 'list' page
    //
    CHECKLIST_TEMPLATE_LIST_LOADING_DATA_MESSAGE: "Loading QA checklists",
    CHECKLIST_TEMPLATE_LIST_EMPTY_TITLE: "Create your first QA checklist",
    CHECKLIST_TEMPLATE_LIST_EMPTY_TEXT:
        "QA checklists help ensuring high quality work. After creating a checklist it can be assigned to Activities in the Workflow Template Editor. Afterwards it will be prompted once the status of Activities is changed.",
    CHECKLIST_TEMPLATE_LIST_EMPTY_BUTTON: "Create your first QA checklist",

    CHECKLIST_TEMPLATE_LIST_NUMBER_OF_ITEMS: "Number of items",
    CHECKLIST_TEMPLATE_LIST_CREATION: "Created",

    CREATED_BY: "Created {{timestamp }} by {{inspector}}",

    CREATE_NEW_CHECKLIST_TEMPLATE_TITLE: "Create new QA checklist",
    CREATE_NEW_CHECKLIST_TEMPLATE_DESCRIPTION:
        "First, please choose a name for your new checklist template:",
    CREATE_NEW_CHECKLIST_TEMPLATE_NAME_PLACEHOLDER: "QA checklist name",
    CREATE_NEW_CHECKLIST_TEMPLATE_CONFIRM: "Create QA checklist",
    //
    // End of checklist template 'list' page

    //////
    // Start of checklist template 'editor' page
    //
    CREATE_CHECKLIST_EDITOR_TITLE: "Checklist Editor",
    CREATE_CHECKLIST_EDITOR_ITEMS: "Items:",
    CREATE_CHECKLIST_EDITOR_ADD_NEW: "Add item",
    CREATE_CHECKLIST_EDITOR_ADD_NEW_PLACEHOLDER:
        "Add a new item to your QA checklist...",
    CREATE_CHECKLIST_EDITOR_SAVED: "QA checklist successfully saved.",

    CREATE_CHECKLIST_EDITOR_SETTINGS_LABEL: "Mandatory for",
    CREATE_CHECKLIST_EDITOR_SETTINGS_TOOLTIP:
        "Mandatory checklist items are highlighted in the mobile app and can not be answered 'not checked' by the Working Team.",

    CREATE_CHECKLIST_EDITOR_IS_MANDATORY: "All teams",
    CREATE_CHECKLIST_EDITOR_IS_NOT_MANDATORY: "No teams",
    CREATE_CHECKLIST_EDITOR_IS_CONFIRM_MANDATORY: "Only Confirmation team",
    CREATE_CHECKLIST_EDITOR_IS_WORK_MANDATORY: "Only Execution team",

    CREATE_CHECKLIST_EDITOR_REQUIRE_IMAGE_LABEL: "Photo required",

    //
    // End of checklist template 'editor' page

    SECTION_CHECKLISTS_TITLE: "QA Checklists",
    CHECKLISTS_TAB_NUMBER_UPLOADED: "QA checklists uploaded",
    CHECKLISTS_TAB_NUMBER_NON_STARTED: "QA checklists not started",
    CHECKLISTS_TAB_NUMBER_ATTACHED_TO_ACTIVITY: "Attached to",
    CHECKLISTS_TAB_DONE_BY: "Finished {{timestamp}} by {{inspector}}",
    CHECKLISTS_TAB_NO_CHECKLISTS_FOR_FILTER:
        "No activities with attached QA checklists assigned to team",
    CHECKLISTS_TAB_NO_CHECKLISTS_MESSAGE_FOR_FILTER:
        "It seems like the team was not assigned responsible for any activities with attached QA checklists of this sequence.",
    CHECKLISTS_TAB_NO_CHECKLISTS: "No QA Checklist attached",
    CHECKLISTS_TAB_NO_CHECKLISTS_MESSAGE:
        "QA checklists allow you to review a list of items connected to an activities. You can attach a QA checklist to an activity in the workflow template.",
    CHECKLISTS_TAB_NO_WORKFLOW_ASSIGNED: "No workflow template assigned",
    CHECKLISTS_TAB_NO_WORKFLOW_ASSIGNED_MESSAGE:
        "Assigning workflow templates allows you to capture a higher level of detail for your deliverables. and add checklists to the activities.",

    CHECKLISTS_DETAILS_RESPONSIBLE_TEAM: "Execution team",
    CHECKLISTS_DETAILS_CONFIRMING_TEAM: "Confirmation team",
    CHECKLISTS_DETAILS_REPORTED_BY: "Reported by:",
    CHECKLISTS_DETAILS_RESULT_Y: "Yes",
    CHECKLISTS_DETAILS_RESULT_N: "No",
    CHECKLISTS_DETAILS_RESULT_B: "Not checked",
    CHECKLISTS_DETAILS_RESULT_NA: "Not applicable",
    CHECKLISTS_DETAILS_RESULT_EMPTY: "",

    BACKGROUND_MENU_GLOBAL_SETTINGS: "Settings",
    BACKGROUND_MENU_RELEVENT_ONLY: "Show only my areas",

    TEAM_USERS:
        "Users responsible for Deliverables not assigned to a Responsible Manager",
    TEAM_CONSTRUCTION_MANAGERS:
        "Managers responsible for specific Deliverables",
    TEAM_ADD_REPLACEMENT: "Define replacement user",
    TEAM_REPLACEMENT_ACTIVE: "Activated",
    TEAM_REPLACEMENT_INACTIVE: "Deactivated",
    TEAM_REMOVE_REPLACEMENT: "Remove replacement user",
    TEAM_NO_EXISTING_REPLACEMENT: "No existing replacement user",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TITLE:
        "Confirm moving Responsible Manager",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TEXT:
        "Are you sure that you want to move this Responsible Manager into another team? If you continue all Deliverables assigned to him will be unassigned. This can only be undone using the Deliverables page.",

    WEEK_BOARD_OPTIONS: "Options",
    WEEK_BOARD_CHOOSE_SIZE: "Show large activity design",
    WEEK_BOARD_SHOW_ACTUALS: "Show Actual dates where available",
    WEEK_BOARD_SIZES_SMALL: "Small",
    WEEK_BOARD_SIZES_LARGE: "Large",
    WEEK_BOARD_PREVIOUS: "Previous page",
    WEEK_BOARD_NEXT: "Next page",

    DATE_ORIGIN_ACTUAL: "(Actual)",
    DATE_ORIGIN_PREDICTED: "@:DATE_ORIGIN_LOOK_AHEAD",
    DATE_ORIGIN_FORECASTED: "(Forecasted)",
    DATE_ORIGIN_LOOK_AHEAD: "(Look Ahead)",
    DATE_ORIGIN_BASELINE: "(Baseline)",

    ACTIVITY_DETAILS_BASELINE_DURATION: "Duration (Baseline)",
    ACTIVITY_DETAILS_STRUCTURE_DISPLAYED_DURATION: "Project Structure Duration",
    ACTIVITY_DETAILS_MEAN_DURATION_LOOKAHEAD: "Average Duration (Look Ahead)",
    ACTIVITY_DETAILS_BASELINE_START: "Start date (Baseline)",
    ACTIVITY_DETAILS_BASELINE_DUE: "End date (Baseline)",
    ACTIVITY_DETAILS_ACTUAL_START: "Actual start",
    ACTIVITY_DETAILS_ACTUAL_DONE: "Actual end",
    ACTIVITY_DETAILS_ACTUAL_CONFIRMED: "Actual confirmed",
    ACTIVITY_DETAILS_LATEST_DURATION: "Duration (Look Ahead)",
    ACTIVITY_DETAILS_LATEST_START: "Start date (Look Ahead)",
    ACTIVITY_DETAILS_NEW_DURATION: "New Duration",
    ACTIVITY_DETAILS_DISPLAYED_DURATION: "Current Duration",
    ACTIVITY_DETAILS_NEW_START: "New Start date",
    ACTIVITY_DETAILS_DISPLAYED_START: "Current Start date",
    ACTIVITY_DETAILS_NEW_DUE: "New End date",
    ACTIVITY_DETAILS_DISPLAYED_DUE: "Current End date",
    ACTIVITY_DETAILS_LATEST_DUE: "End date (Look Ahead)",
    ACTIVITY_DETAILS_NUMBER_OF_CHANGES: "Number of re-plannings",
    ACTIVITY_DETAILS_FORECAST_START: "Forecasted Start date",
    ACTIVITY_DETAILS_FORECAST_DUE: "Forecasted End date",
    ACTIVITY_DETAILS_ACCEPT_FORECAST: "Use as Look Ahead date",
    ACTIVITY_DETAILS_FORECAST_START_TOOLTIP:
        "Use the forecasted date as new Look Ahead Start date. Afterwards you will still need to save and publish your changes to the project.",
    ACTIVITY_DETAILS_FORECAST_DUE_TOOLTIP:
        "Use the forecasted date as new Look Ahead End date. Afterwards you will still need to save and publish your changes to the project.",
    ACTIVITY_DETAILS_MOVE_FOLLOWING_DATES:
        "Schedule all following activities according to your changes and defined dependencies.",
    ACTIVITY_DETAILS_MOVE_FOLLOWING_DATES_TOOLTIP:
        "When the checkbox is ticked, Sablono will automatically schedule all successor activities of this one according to the changes that you defined. Please note, that this will lead to Look Ahead dates for all following activities as well.",
    ACTIVITY_DETAILS_RESPONSIBLE_TEAM: "Execution team",
    ACTIVITY_DETAILS_PLANNED_LABOUR: "Planned labour",
    ACTIVITY_DETAILS_CONFIRMING_TEAM: "Confirmation team",
    ACTIVITY_DETAILS_DELIVERABLE_CODE: "Deliverable Code",
    ACTIVITY_DETAILS_DELIVERABLE_NAME: "Deliverable Name",
    ACTIVITY_DETAILS_PROJECT_STRUCTURE: "Project Structure",
    ACTIVITY_DETAILS_NUMBER_OF_DELIVERABLES: "Deliverables aggregated",
    ACTIVITY_DETAILS_NUMBER_OF_FORECASTED_ACTIVITIES: "currently forecasted",
    ACTIVITY_DETAILS_REQUIRED_PRODUCTIVITY: "Planned Productivity",
    ACTIVITY_DETAILS_REQUIRED_PRODUCTIVITY_UNIT: "Deliverables per day",
    ACTIVITY_DETAILS_LAST_CHECKED_BY: "Last updated by",
    ACTIVITY_DETAILS_LAST_CHECKED_ON: "Last updated on",
    ACTIVITY_DETAILS_CHANGE_DATE: "Select Look Ahead End date",
    ACTIVITY_DETAILS_NO_CONFIRMATION_REQUIRED: "No separate review requested",
    ACTIVITY_DETAILS_START_SESSION: "Start Session",
    ACTIVITY_DETAILS_CHANGE_DATES_OF_ACTIVITIES: "Change dates",
    ACTIVITY_DETAILS_HAS_CHANGED_THIS_SESSION:
        "This Look Ahead End date has been changed during the running session.",
    ACTIVITY_DETAILS_SAVED_IN_SESSION: "Planning session saved",
    ACTIVITY_DETAILS_BULK_LOOK_AHEAD_SAVED:
        "{{changed}} of {{all}} look ahead dates were changed.",

    FILTER_MENU: "Filters: {{activeFilterCount}} currently active",
    FILTER_MENU_SEARCH_PLACEHOLDER: "Search in {{items}} items...",

    ACTION_DOWNLOAD_WEEKPLAN: "Print",
    SESSION_MENU_BULK_CHANGES_CANCEL: "Done",
    SESSION_MENU_BULK_CHANGES_START: "Move multiple activities",
    SESSION_MENU_BULK_CHANGES_APPLY: "Change dates of selection",
    SESSION_MENU_BULK_CHANGES_ACTIVITIES:
        "{{activitiesNum}} activities selected.",
    SESSION_MENU_START: "Start Look Ahead planning session",
    SESSION_MENU_SAVE_AND_RELEASE: "Publish",
    SESSION_MENU_CANCEL: "Discard",
    SESSION_MENU_COMMIT_TO_ALL_FORECASTED_DATES:
        "Use all forecasted dates as new Look Ahead Schedule",
    ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_TITLE:
        "Planning Session on the Lean Planning page in progress...",
    ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_MESSAGE:
        "The following users have not released their Look Ahead changes yet: {{users}}. Please make sure that Look Ahead dates are saved or discarded by the user who created them before you continue to work on the project schedule.",
    COMMIT_TO_FORECAST_CONFIRM_DIALOG_TITLE:
        "Use all forecasted dates as new Look Ahead Schedule",
    COMMIT_TO_FORECAST_CONFIRM_DIALOG_CONTENT:
        "Are you sure that you want to use all forecasted dates as new Look Ahead Schedule dates? Please note, that this will also include Deliverables which you might not see on your screen currently (e.g. because they are not matching your filter criteria).",
    FORECAST_DATES_CONFIRM_DIALOG_TITLE:
        "Do you really want to forecast all deliverables?",
    FORECAST_DATES_CONFIRM_DIALOG_TITLE_CONTENT:
        "Forecast All will include all Deliverables matching your current filters. Please note, that they may also be displayed on previous/next pages. Do you want to continue?",
    FORECAST_DATES_ACCEPT_ALL_DIALOG_TITLE:
        "Do you really want to use all forecasted dates as new Look Ahead?",
    FORECAST_DATES_ACCEPT_ALL_DIALOG_CONTENT:
        "Accept All will include all forecasted Deliverables even if they do not match your current filters or are displayed on previous/next pages. Do you wish to proceed?",

    WORKFLOW_ACTIVITY_DETAIL_DELETE_CUSTOM_DATE: "Delete user-defined dates",
    WORKFLOW_ACTIVITY_DETAIL_QA_CHECKLIST: "Attached QA checklist",
    WORKFLOW_ACTIVITY_DETAIL_LOOK_AHEAD_DURATION: "Duration (Look Ahead)",
    WORKFLOW_ACTIVITY_BASELINE_DURATION: "Duration (Baseline)",
    WORKFLOW_GROUP_ACTIVITIES_COMPLETED: "Activities finished",
    ACTIVITY_DETAILS_REVIEW_TEAMS: "Inspection Team",

    WORKFLOW_BULK_LOOK_AHEAD_MULTI:
        "ALT + click to select a Project Structure level",
    WORKFLOW_BULK_LOOK_AHEAD_BUSY_TITLE: "Calculating your schedule...",
    WORKFLOW_BULK_LOOK_AHEAD_BUSY_PROGRESS:
        "We still need to re-schedule {{items}} activities.",
    WORKFLOW_BULK_LOOK_AHEAD_DIALOG_TITLE:
        "Change Look Ahead dates for selection",
    WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_TITLE:
        "Another activity already selected...",
    WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_MESSAGE:
        "Seems like you already selected another activity from the same deliverable. If you want to select this one please deselect the previously selected one.",
    WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_TITLE:
        "Activity finished already...",
    WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_MESSAGE:
        "For activities which have been finished already Look Ahead dates can not be changed any longer. You will see their Actual Start and End date when looking at the Lean Planning page.",

    // Daily logs page
    SITE_DIARY_TEAMS_CARD_TITLE_LEFT: "Teams",
    SITE_DIARY_TEAMS_CARD_TITLE_MANAGERS: "Managers",
    SITE_DIARY_TEAMS_CARD_TITLE_FOREMEN: "Foremen",
    SITE_DIARY_TEAMS_CARD_TITLE_WORKERS: "Workers",
    SITE_DIARY_TEAMS_CARD_TITLE_OTHER: "Other",
    SITE_DIARY_TEAMS_CARD_TITLE_TOTAL: "Total",
    SITE_DIARY_PROGRESS_CARD_TITLE: "Progress",
    SITE_DIARY_PROGRESS_CARD_INFORMATION:
        "Last updated {{createdAt}}. The information presented can be refreshed until the log is finalized.",
    SITE_DIARY_PROGRESS_CARD_TOOLTIP:
        "This activity was started on {{dateStarted}}",
    SITE_DIARY_MACHINES_CARD_TITLE: "Machines",
    SITE_DIARY_MACHINES_CARD_PLACEHOLDER:
        "Enter which machines and how many were used on the selected day.",
    SITE_DIARY_EVENTS_CARD_TITLE: "Special Events",
    SITE_DIARY_EVENTS_CARD_PLACEHOLDER:
        "Enter any special events or other notes that you want to document for the selected day.",
    ACTION_CHOOSE_A_DATE: "Select date",
    ACTION_FINALISE_ENTRY: "Finalise entry",
    SITE_DIARY_CALENDAR_TODAY: "Today",
    SITE_DIARY_CALENDAR_FINALISED: "Finalised",
    SITE_DIARY_CALENDAR_FINALISED_BY: "Finalised by {{finaliser}}",

    SITE_DIARY_INFO_CARD_LOW: "Lowest",
    SITE_DIARY_INFO_CARD_HIGH: "Highest",
    SITE_DIARY_INFO_CARD_CONDITIONS: "Weather conditions",
    SITE_DIARY_INFO_CARD_DEPARTMENT: "Building Owner",
    SITE_DIARY_INFO_CARD_TEMPERATURE_UNIT: "&deg;C",

    SITE_DIARY_CARD_INPUT_ERROR_MIN_MESSAGE:
        "Please select a number above {{value}}",
    SITE_DIARY_CARD_INPUT_ERROR_MAX_MESSAGE:
        "Please select a number below {{value}}",

    SITE_DIARY_PROGRESS_REPORTED_BY: "Updated by {{inspector}}",
    SITE_DIARY_PROGRESS_EMPTY:
        "It seems like no progress was reported and no notes have been opened or closed on the selected day...",
    SITE_DIARY_NOTE_CREATED_BY: "Updated by {{inspector}}",

    SITE_DIARY_CONFIRM_LEAVE_TITLE: "Unsaved changes",
    SITE_DIARY_CONFIRM_LEAVE_TEXT:
        "It seems like you have made some changes to this Daily Log which have not been saved, yet. Are you sure you want to leave this page without saving them first?",

    SITE_DIARY_SAVE_SUCCESS: "Daily log successfully updated",
    SITE_DIARY_PAGINATION_SELECTION: "Page {{currentPage}} of {{totalPages}}",

    SITE_DIARY_SHOW_DETAILS: "Show details",
    SITE_DIARY_HIDE_DETAILS: "Hide details",

    SITE_DIARY_CONFIRM_FINALISE_ENTRY_TITLE: "Are you sure?",
    SITE_DIARY_CONFIRM_FINALISE_ENTRY_TEXT:
        "After finalising this Daily Log you or your colleagues will not be able to change it any longer. Do you want to proceed?",

    COST_GROUP_ENTER_SEARCH_TERM: "Search for Cost Groups...",
    COST_GROUP_SEARCH_EMPTY: "There are no Cost Groups matching your criteria.",

    COST_GROUP_CONFIRM_DELETE_TITLE: "Are you sure?",
    COST_GROUP_CONFIRM_DELETE_BODY: "Are you sure? This can not be undone.",
    COST_GROUP_FORECASTED_VALUES_HINT:
        "You selected a date in the future. The numbers on the commercial dashboard are forecasted based on where the project should be on the date you have selected",

    COST_GROUP_CSV_COLUMN_WORK_PACKAGE: "Work package",
    COST_GROUP_CSV_COLUMN_BUDGET_SHARE: "% of contract sum",
    COST_GROUP_CSV_COLUMN_BUDGET_ALLOCATED: "Contract sum allocated",
    COST_GROUP_CSV_COLUMN_STARTED: "Contract sum in progress {{currency}}",
    COST_GROUP_CSV_COLUMN_PERCENT_STARTED: "% of Activities in progress",
    COST_GROUP_CSV_COLUMN_TOTAL_STARTED: "Number of Activities in progress",
    COST_GROUP_CSV_COLUMN_WAITING_FOR_CONFIRMATION:
        "Contract sum awaiting sign-off {{currency}}",
    COST_GROUP_CSV_COLUMN_PERCENT_WAITING_FOR_CONFIRMATION:
        "% of Activities awaiting sign-off",
    COST_GROUP_CSV_COLUMN_TOTAL_WAITING_FOR_CONFIRMATION:
        "Number of Activities awaiting sign-off",
    COST_GROUP_CSV_COLUMN_FINISHED_AND_CONFIRMED:
        "Contract sum signed off {{currency}}",
    COST_GROUP_CSV_COLUMN_PERCENT_FINISHED_AND_CONFIRMED:
        "% of Activities signed off",
    COST_GROUP_CSV_COLUMN_TOTAL_FINISHED_AND_CONFIRMED:
        "Number of Activities signed off",
    COST_GROUP_CSV_COLUMN_PERCENT_COST_GROUP_BUDGET_SPENT:
        "% of Cost completed",
    COST_GROUP_CSV_COLUMN_DATE_OF_GENERATION: "Report exported on",
    COST_GROUP_CSV_COLUMN_FIGURES_UP_TO_DATE: "Incl. updates up to",

    ACTIVITIES_LIST_SELECTOR_HEADER_ACTIVITY: "Activity",
    ACTIVITIES_LIST_SELECTOR_HEADER_TEAM: "Execution Team",
    ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_TEAM: "Standard Execution Team",
    ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_CONFIRMING_TEAM:
        "Standard Confirmation Team",
    ACTIVITIES_LIST_SELECTOR_HEADER_CONFIRMATION_TEAM:
        "@:CHECKLISTS_DETAILS_CONFIRMING_TEAM",
    ACTIVITIES_LIST_SELECTOR_SEARCHBAR_PLACEHOLDER:
        "Search for Activities by Workflow Template, Name and Team...",

    SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TITLE:
        "Invalid Verification Code",
    SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TEXT:
        "It seems like the Verification Code you entered is not correct. Please double-check and try again.",
    SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TITLE:
        "Expired Verification Code",
    SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TEXT:
        " Oops… It seems like your verification code has expired. Please request a new one and try again.",

    SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TITLE: "User cannot be confirmed",
    SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TEXT: "User is already confirmed",
    SECTION_VERIFICATION_CODE_RESENT:
        "A new verification code has been send to your registered email address. Please use the link of the new email to try again.",

    ERROR_EMAIL_FORMAT: "Please enter a valid email",

    LEGEND_STAGE_MODE_DEFAULTS: "Others",

    /**
     * Context: Information to display when options are disabled.
     *
     * Cases:
     *  - A checklist is required and this feature is currently not supported.
     */
    INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS___TITLE:
        "Inspection Teams required",
    INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS__MESSAGE:
        "This activity has to be checked by Inspection Teams, which is only supported by the Sablono mobile app. Please use it, to report progress.",
    MAKING_DECISION_LOCAL_DATA_HEADER: "Locally saved Workflow Template",
    MAKING_DECISION_LOCAL_DATA_MESSAGE:
        "You are currently opening a version of your Workflow Template which was only locally saved on this device {{timeAgo}}.\n\r Do you wish to continue working on it or do you want to download the newest Template from the Sablono server?",
    DISCARD_AND_DOWNLOAD_NEW_VERSION: "Discard & Download newest version",

    DELETE_PROJECT_DIALOG_WARN_USER:
        "Do you really want to delete this project? This will delete all related information from all Sablono systems.",
    DELETE_PROJECT_DIALOG_WARN_USER_INFO:
        "Warning: This action CAN NOT be undone.",
    DELETE_PROJECT_DIALOG_WARN_USER_NEXT: "Proceed",
    DELETE_PROJECT_DIALOG_WARN_USER_ALT: "Request data export",
    DELETE_PROJECT_DIALOG_PASSWORD:
        "Please verify your Sablono password to be able to irretrievably delete this project.",
    DELETE_PROJECT_DIALOG_WRONG_PASSWORD: "Your password is not valid.",
    DELETE_PROJECT_DIALOG_COPY_NAME_TO_DELETE:
        "Finally, please copy the NAME of your project <b>{{projectName}}</b> to confirm that you really want to permanently delete it from the Sablono server.",
    DELETE_PROJECT_DIALOG_REPEAT_PROJECT_NAME_PLACEHOLDER:
        "Enter the project NAME for confirmation",
    _ERASE: "Delete",

    ARCHIVE_PROJECT: "Archive Project",
    ARCHIVE_PROJECT_CONFIRMATION:
        "Do you really want to archive this project? Afterwards no user will have access to it any longer but it will still be saved on the Sablono server and could be reactivated in the future.",

    DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL_SUBJECT: "Request data export",
    DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL:
        "Hi Sablono support,%0D%0A%0D%0A" +
        "I would like to delete the project (ID: {{projectId}}) but receive an export of the data beforehand.%0D%0A Please contact me as soon as possible and let me know next steps.%0D%0A" +
        "Regards,%0D%0A" +
        "{{username}}",

    ACTIVITIES_LIST_SELECTOR_SEARCHBAR_PLACEHOLDER_WITHOUT_PROCESS_TEMPLATE:
        "Search for Activities by Name and Team...",

    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_TITLE:
        "Do you want to proceed?",
    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_MESSAGE:
        "If you want to replace the Workflow Template on Deliverables that were already started, please select the box below. This action can only be reverted by Sablono support.",
    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_CHECKBOX_MESSAGE:
        "Yes, I want to overwrite existing progress data",
    ERR_FR_MAX_BODY_LENGTH_EXCEEDED:
        "It seems like the amount of QA checklists that you are trying to export exceeds our server's limit. Please try again with the same Deliverables but a smaller number of Activities. If the problem persists please contact our support.",

    FILTER_BY_PROCESS_TEMPLATES_TITLE: "Filter by Workflow",
    FILTER_BY_PROCESS_TEMPLATES_ALL_TEMPLATES: "All Workflows",

    FILTER_BY_DELIVERABLE_TYPE_TITLE: "Filter by Deliverable Type",
    FILTER_BY_DELIVERABLE_TYPE_ALL_TEMPLATES: "All Deliverable Types",

    DASHBOARD_PROJECT_BAR_CHART_TITLE: "All Activities",
    SECTION_PROJECT_OVERVIEW_TITLE: "Project Overview",
    ACTION_NAV_TO_PROJECT_OVERVIEW_TITLE: "Project Overview",

    PROJECT_OVERVIEW_PROCESS_TEMPLATES_TITLE: "Workflows",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_TITLE: "Deliverable Types",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_TITLE:
        "Create Deliverable Types",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_MESSAGE:
        "Once you categorised your Deliverables by Types you will find a summary per Type here.",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_ACTION: "@:GO_TO_DELIVERABLES",

    DASHBOARD_BAR_CHART_TOTAL_LABEL: "Total:",
    DASHBOARD_BAR_CHART_TOTAL_TEXT: "@:_ACTIVITIES_N",
    _DELIVERABLE_TYPE: "Deliverable Type",

    REMOVE_DELIVERABLE_TYPE: "Remove Deliverable Type",
    DIALOG_UNASSIGNED_TYPE_ACTION: "Remove",

    //    TUTORIAL LINKS

    TUTORIAL_BAR_CHART:
        "http://support.sablono.com/en/articles/4828856-bar-chart-planned-vs-actual-completion-of-work",
    TUTORIAL_S_CURVE:
        "http://support.sablono.com/en/articles/4828855-s-curve-planned-vs-actual-completion-of-work",
    TUTORIAL_PRODUCTIVITY_CHART:
        "https://support.sablono.com/en/articles/8704401-productivity-chart-planned-vs-actual-by-week",
    TUTORIAL_DELIVERABLE_TYPES:
        "http://support.sablono.com/en/articles/4838516-how-to-categorize-your-deliverables-by-types",
    TUTORIAL_IMPORT_PROGRESS:
        "http://support.sablono.com/en/articles/5393783-how-to-import-progress-data-from-microsoft-excel",

    DELIVERABLE_LINK_TO_MOBILE_LABEL: "Open in mobile app",
    DELIVERABLE_LINK_TO_MOBILE: "Click here",
    ERROR_OPEN_DEEP_LINK_TO_NATIVE_APP_MESSAGE:
        "Please make sure the Sablono mobile app is installed and available to open it",
    DELIVERABLE_VIEW: "Deliverables",
    STRUCTURE_VIEW: "Project Structure",

    PAGINATION_FETCH_FROM_SOURCE_ERROR_MESSAGE:
        "Failed to load more data in the background. The information presented in the current view may not be complete.",

    UPLOAD_SVG_FILE:
        "Your file is being uploaded - This may take a few moments...",
    TIME_RANGE_SELECTOR_TITLE:
        "Please choose time range to be displayed on Lean Planning page",
    TIME_RANGE_SELECTOR_FROM: "Start Date",
    TIME_RANGE_SELECTOR_TO: "End Date",
    TIME_RANGE_SELECTOR_EXTRA: "Predefined time-ranges",
    TIME_RANGE_SELECTOR_CURRENTLY_SELECTED: "Currently Selected",
    TIME_RANGE_SELECTOR_START: "Start",
    TIME_RANGE_SELECTOR_END: "End",
    ERROR_MAXIMUM_DATE_RANGE_EXCEEDED:
        "Please select a time range which is shorter than one year.",

    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_TITLE: "Change teams",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SELECT:
        "Please select those activities of the previously selected deliverables for which you want to change the pre-defined teams of your Workflow Template:",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SUBMIT: "Change Teams",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_DROPDOWN_DESCRIPTION:
        "For which team would you like change the standard?",

    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_TITLE: "Remove user-defined teams",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SELECT:
        "Please select those activities of the previously selected deliverables for which you want to remove the user-defined teams and use the standard defined in your Workflow Template instead:",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SUBMIT: "Use standard Teams",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_DROPDOWN_DESCRIPTION:
        "For which team would you like to use the standard again?",

    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_RESPONSIBLE:
        "@:ACTIVITY_DETAILS_RESPONSIBLE_TEAM",
    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_CONFIRMATION:
        "@:ACTIVITY_DETAILS_CONFIRMING_TEAM",
    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_BOTH: "Both",

    DIALOG_ACTIVITY_SET_SUCCESS_TEXT:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Teams successfully changed",

    SECTION_EXCEL_IMPORT_PROGRESS_TEXT:
        "Please make sure that your Excel has the following format before importing:<br>" +
        "Deliverable Code - Activity Name - Status - Timestamp - Date Format - Project ID <br>" +
        "Please note that you will only be able to update activities that your team is responsible for.",

    SECTION_EXCEL_IMPORT_PROGRESS_TITLE: "@:SECTION_EXCEL_IMPORT_TITLE",
    SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TITLE:
        "Progress successfully imported",
    SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TEXT:
        "{{numberOfUpdates}} Status Updates have been imported into your project",
    SECTION_EXCEL_IMPORT_UPLOADED_WITH_FAILURES:
        "Unfortunately, some of your Status Updates could not be imported into your project:",

    CS_REVERT_CONFIRMATION_ACTION: "Request: Revert confirmation",
    CS_RESET_ACTIVITY_DIALOG_HEADER: "Request: Reset activity",
    CS_REVERT_CONFIRMATION_DIALOG_HEADER: "Request: Revert confirmation",
    CS_RESET_ACTIVITY_DIALOG_CONTENT:
        "If you want to reset this activity which includes removing all connected data, please copy the following text and paste it into a message to your Sablono support:",
    CS_REVERT_CONFIRMATION_DIALOG_CONTENT:
        "If you want to revert the confirmation step of this activity including all connected data, please copy the following text and paste it into a message to your Sablono support:",

    IMPORT_PROGRESS_SUCCESS: "Activity successfully updated",
    IMPORT_SUCCESS: "Activity successfully updated",
    IMPORT_PROGRESS_CHECKLIST_REQUIRED:
        "A QA checklist needs to be filled while updating the Activity status",
    IMPORT_PROGRESS_NOT_ALLOWED_TRANSITION:
        "The provided Status Update is not supported based on your workflow setup",
    IMPORT_PROGRESS_ALREADY_UPDATED:
        "The provided Status Update already exists",
    IMPORT_ACTIVITY_DUPLICATE_NAME:
        "The provided Activity Name exists twice in the relevant Deliverable",
    IMPORT_PROGRESS_NOT_RESPONSIBLE_TEAM:
        "Your team is not responsible for the Activity",
    IMPORT_PROGRESS_TIMESTAMP_IN_THE_FUTURE:
        "The provided Status Update Timestamp is in the future",
    IMPORT_ACTIVITY_NOT_FOUND:
        "The provided Activity Name does not exists in the relevant Deliverable",
    IMPORT_USER_DEFINED_DATES_ONLY_ONE_DATE_IS_ALLOWED:
        "Only one of start or end dates should be provided",
    IMPORT_USER_DEFINED_DATES_NO_DATE_AND_DURATION_GIVEN:
        "Either start or end date or duration must be provided",
    IMPORT_USER_DEFINED_DATES_DELIVERABLE_IS_LOCKED:
        "Deliverable is currently locked in planning session",
    IMPORT_USER_DEFINED_DATES_INVALID_UNIT_OF_WORK: "Wrong work unit",
    IMPORT_PROGRESS_WRONG_PROJECT:
        "The provided Project ID does not match the currently opened project",
    ERROR_PROGRESS_ENTRIES_LIMITATION:
        "You can only import up to 500 status updates during one import. If you want to import more than that please split the file into multiple ones and import them after each other.",
    ERROR_DATES_ENTRIES_LIMITATION:
        "You can only import up to 10000 date updates during one import. If you want to import more than that please split the file into multiple ones and import them after each other.",
    ERROR_PDF_CREATION_MESSAGE:
        "The requested PDF file became to large to be created. Please reduce the set of included data and try again.",
    ERROR_REQUEST_SIZE_EXCEEDED_MESSAGE:
        "The size of the requested data exceeded the limits. Please reduce the set of included data and try again.",
    ERROR_NOTE_REQUEST_SIZE_EXCEEDED_MESSAGE:
        "The report that you requested would unfortunately result in a PDF which is bigger than 1GB. Please filter your report to include a lower number of Notes and try again.",
    INTERNAL_SERVER_ERROR: "@:ERROR_UNKNOWN_MESSAGE",
    FORBIDDEN:
        "You do not have sufficient permissions to execute this action. Please contact your Project Admin to e.g. change your role.",

    EULA_PROMPT_TITLE:
        "We have updated our End User Licence Agreement (EULA) and Data and Privacy Policy",
    EULA_PROMPT_CONTENT:
        "We would like to inform you that we have adapted our EULA and Data and Privacy Policy.<br><br>Here you will find the current version of the <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a>.<br><br>The text of the Data and Privacy Policy you can find at <a href='https://www.sablono.com/privacy' target='_blank'>https://www.sablono.com/privacy</a>.<br><br>You do not need to do anything else. If you wish to lodge an objection, please do so within 30 days by email to <a href='mailto:support@sablono.com'>support@sablono.com</a>.<br><br>Your Sablono Team",
    EULA_CONFIRM_READ_TEXT: "I have read and understood the EULA",
    EULA_PROMPT_ACTION_ACCEPT: "Ok",
    EULA_PROMPT_ACTION_READ: "Open EULA as PDF",
    EULA_PROMPT_ACTION_SKIP: "Remind me later",
    EULA_LINK:
        "https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf",
    EULA_REVIEW_EULA: "Review the EULA",
    PRIVACY_POLICY: "Data and Privacy Policy",
    PRIVACY_POLICY_URL:
        "https://www.sablono.com/legal/data-and-privacy-policy?hsLang=en",

    SECTION_TOOLS_DIALOG_USAGE_NUMBER: "({{active}} deliverables live)",

    IMPORT_DELIVERABLE_EXCEL_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2967630-how-to-import-a-microsoft-excel-project",
    IMPORT_ASTA_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2960319-how-to-import-an-asta-power-project-schedule",
    CREATE_VISUAL_TRACKER_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2911811-how-to-create-visual-trackers-on-sablono",
    VISUAL_TRACKER_SVG_UPLOAD_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/5461453-how-to-create-visual-trackers-on-inkscape",
    GO_TO_TUTORIAL_ACTION: "Go to tutorial",
    COST_GROUP_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/4828779-how-can-i-set-up-a-cost-group",
    PROCESS_TEMPLATES_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2967824-how-to-create-a-process-template",
    QA_CHECKLIST_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2904883-what-are-qa-checklists-and-how-do-i-use-them-in-my-project",
    NOTE_TEMPLATES_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/2967699-how-to-create-a-note-template",
    NOTES_TUTORIAL_URL:
        "https://support.sablono.com/en/articles/5433250-how-to-add-notes-from-the-web-platform",
    NOTES_EMPTY_STATE_HEADER: "Report your first Notes",
    NOTES_EMPTY_STATE_CONTENT:
        "On Sablono, you can create Notes on your Deliverables, Activities and QA items. Read the tutorial below to learn how to report your first Notes.",
    LEGEND_LINE_DUE_TODAY: "Due today",
    LEGEND_LINE_DUE_THIS_WEEK: "Due by the end of this week",
    LEGEND_LINE_DUE_NEXT_WEEK: "Due by the end of next week",

    SECTION_TOOLS_IMPORT_STATES: "Progress (List) - Microsoft© Excel",
    SECTION_TOOLS_IMPORT_DATES: "Dates (List) - Microsoft© Excel",

    INPUT_OTHER_COMPANY_TYPE: "Other Company Type",
    INPUT_OTHER_JOB_TITLE: "Other Job Title",

    MAIN_CONTRACTOR: "Main Contractor",
    SUBCONTRACTOR: "Subcontractor",
    POD_SUPPLIER: "POD Supplier",
    HOUSE_BUILDER: "House Builder",
    ARCHITECT: "Architect",
    DEVELOPER: "Developer",
    HOUSING_ASSOCIATION: "Housing Association",
    MANUFACTURER: "Manufacturer",
    CONSULTANT: "Consultant",
    OTHER: "Other",
    CONSTRUCTION_MANAGER: "Construction Manager",
    CONSTRUCTION_DIRECTOR: "Construction Director",
    PROJECT_OR_PACKAGE_MANAGER: "Project / Package Manager",
    COMMERCIAL_DIRECTOR: "Commercial Director",
    COMMERCIAL_MANAGER: "Commercial Manager",
    PLANNER: "Planner",
    PROJECT_DIRECTOR: "Project Director",
    BOARD_DIRECTOR: "Board Director",
    CONSTRUCTION_OPERATIVE: "Construction Operative",
    SUB_CONTRACTOR_MANAGER: "Sub-Contractor Manager (Black Hat)",
    CENTRAL_BUSINESS_OR_OFFSITE_MANAGEMENT:
        "Central Business / Offsite Management",
    THIRD_PARTY_INSPECTOR: "Third party Inspector",
    SECTION_REGISTRATION_JOB_TITLE: "Job Title",
    SECTION_REGISTRATION_COMPANY_TYPE: "Company Type",

    IMAGE_OVERLAY_INFO_TITLE_LOCATION: "Location",
    IMAGE_OVERLAY_INFO_TITLE_ALTITUDE: "Altitude",
    IMAGE_OVERLAY_INFO_TITLE_LONGITUDE: "Longitude",
    IMAGE_OVERLAY_INFO_TITLE_LATITUDE: "Latitude",
    SIGNUP_NEW_TEXTS_WITH_MARKETING_CONSENT:
        "By signing up for Sablono you agree to our <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a> and our <a href='https://www.sablono.com/privacy' target='_blank'>Data and Privacy Policy</a> incl. the transfer to so-called third countries. Sablono will provide relevant information about our products to you. You may unsubscribe from these communications at any time.",
    SIGNUP_NEW_TEXTS_WITHOUT_MARKETING_CONSENT:
        "By signing up for Sablono you agree to our <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a> and our <a href='https://www.sablono.com/privacy' target='_blank'>Data and Privacy Policy</a> incl. the transfer to so-called third countries.",
    ACTION_ADD_COMMENT: "Add comments and photos",
    ADD_NOTE_COMMENT_DIALOG_TITLE: "Add comments and photos to Note",
    ADD_NOTE_COMMENT_REDIRECT_DIALOG_TITLE: "Report issue status",
    ADD_NOTE_COMMENT_REDIRECT_DIALOG_TEXT:
        "Do you want to add a comment or photo while changing the status of this Note?",
    ADD_NOTE_COMMENT_DESCRIPTION_TITLE:
        "Enter a text describing the note comment",

    RESET_ACTIVITY_TITLE: "Reset activity",
    RESET_ACTIVITY_CONFIRMATION: "To proceed, please enter the word RESET",
    RESET_ACTIVITY_ACTION: "Delete all progress and quality data",
    REQUEST_ACTIVITY_UPDATE: "Request teammate to update progress",
    REQUEST_ACTIVITY_UPDATE_EMAIL_SUBJECT:
        "{{projectName}} - Please update the status of the attached activity on Sablono",
    REQUEST_ACTIVITY_UPDATE_EMAIL_BODY:
        "Hi! \n\n Please update the status of the following activity on Sablono for me: \n * {{deliverableCode}} - {{activityName}} \n * https://app.sablono.com/{{deliverableUrl}} \n\n You can either click the link to open the activity on your mobile Sablono app or login on the web to update it there. \n\n Thanks \n {{userName}}",
    RESET_ACTIVITY_DESCRIPTION:
        "Do you really want to reset this activity <b>to “not started”</b>?\n\nIf you proceed progress reports, actual dates, connected Notes and QA Checklists will be removed. This can only be undone with Sablono support.",
    BULK_RESET_ACTIVITIES_TITLE: "Reset activities to not started",
    BULK_RESET_ACTIVITIES_DESCRIPTION:
        "Do you really want to reset the selected activities <b>to “not started”</b>?\n\nIf you proceed progress reports, actual dates, connected Notes and QA Checklists will be removed. This can only be undone with Sablono support.",
    BULK_RESET_ACTIVITIES_HEADER:
        "Please select the activities of the previously selected deliverables that you want to reset. This will include progress reports, actual dates, connected Notes and QA Checklists.",
    BULK_RESET_ACTIVITIES_SUBMIT: "Reset activities",
    BULK_RESET_ACTIVITIES_TOAST:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Activities reset",
    DIALOG_PRINT_ISSUE_REPORT_EMAIL_BEING_CREATED_TITLE:
        "Exporting your Notes into a PDF report",
    DIALOG_PRINT_ISSUE_REPORT_EMAIL_BEING_CREATED_TEXT:
        "Once we created your Note PDF report we will email it to {{email}}. Please keep in mind that this may take some time depending on the number of photos which are included in it.",
    SEND_REPORT_TO_ADDITIONAL_EMAILS:
        "Share QA Checklist PDFs with your colleagues incl. comment",

    INITIAL_ASSIGNMENT_OF_MULTIPLE_COMMERCIAL_MANAGERS_ERROR:
        "You are only allowed to add the first Commercial Manager to this team, afterwards they will manage access.",
};
module.exports = translation;
